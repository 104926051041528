import React, { useContext, useEffect, useState } from "react";
import { SettingsContext } from '../../components/authorization/GetAccessRights';
import { getUserId, getLoggedInUser } from "../../components/authorization/Utilities"

import { useParams, useNavigate } from "react-router-dom"
import { useQuery, useMutation } from '@apollo/client'

import toast from 'react-hot-toast';

import { setErrorFieldState } from '../../components/utilities/Validation'

import { Box, Button, MenuItem, Stack, TextField, Typography } from "@mui/material";
import LayoutContainer from "../../components/container/Container";

import FileDropzone from '../../components/files/FileDropzone'
import { uploadDocuments } from "../../components/files/utilities/UploadDownload"

import { hasData, validateFormDataNotEmpty } from '../../components/utilities/Validation'
import { handleFormDataChange } from '../../components/utilities/Events'
import { parseFileNameType } from '../../components/utilities/Parsers'

import { DOCUMENT_TYPES } from '../../Graphql/ListsCategories/QueryListsCategories'
import { CREATE_COURSE_DOCUMENT } from '../../Graphql/Documents/MutateDocuments'
import AcademicYearClosed from "../../components/authorization/AcademicYearClosed";

var initFormData = {
  userId: "",
  created_by_id: "",
  academic_year: "",
  submission_type: "",
  submitted_name: "",
  term: "",
  professor_name: "",
  file_display_name: "",
  file_path: "",
  // file_name: "",
  file_type: "",
  file_size: ""
}

const initErrors: any = {
  academic_year: false, 
  submission_type: false, 
  submitted_name: false, 
  term: false, 
  professor_name: false
}

const initSubmissionTypes = [
  { value: '', label: '', },
];

const NewDocument = () => {
  const settings: any = useContext(SettingsContext)
  const loggedInUserId = getLoggedInUser(settings).id || null 
  // const loggedInUserId = localStorageLoggedInUser().id || null
  const academicYearSettings = settings.academicYearSettings

  const params = useParams()
  // const userId: string = params.id ? params.id : ''
  const userId: string = getUserId(settings.accessRights, params.id)

  

  const { error: errorDocTypes, data: dataDocTypes } = useQuery(DOCUMENT_TYPES)
  const [ createCourseDocument, { loading: loadingCreateDocument, error: errorCreateDocument, data: dataCreateDocument } ] = useMutation(CREATE_COURSE_DOCUMENT)
  
  const [ formData, setFormData ] = useState(initFormData)
  const [ errorFields, setErrorFields ] = useState(initErrors)

  const [ uploadResponse, setUploadResponse ] = useState<any>({})
  const [ postData, setPostData ] = useState(false)
  
  const [ selectedFile, setSelectedFile ] = useState<any>([])

  const [ submissionTypes, setSubmissionTypes ] = useState(initSubmissionTypes)

  const [ fileId, setFileId ] = useState('')

  const handleSubmit = async (e:any) => {  
    //Validate Fields Filled Out
    //Current validation is on client side not server side. 
    const validationResponse = validateFormDataNotEmpty(formData, ["userId", "created_by_id", "file_display_name", "file_path", "file_type", "file_size"])
    const timestamp = new Date().getTime();

    if (validationResponse.length > 0) {
      console.debug('validationResponse')
      console.debug(validationResponse)
      setErrorFields(setErrorFieldState(validationResponse.join(',')))

      toast.error('Please fill out the form')
      return false
    }
    if (selectedFile.length === 0) {
      toast.error('Please select a file to upload')
      return false
    }
    console.debug('passed form validation. upload')
    //upload doc to AWS first, submit to db if upload is successful
    uploadDocuments(formData.academic_year, selectedFile, timestamp.toString())
      .then((data) => {
        console.log(data); // access the data resolved by the Promise here
        setFileId(timestamp.toString())
        setUploadResponse(data)
      })
      .catch((error) => {
        console.error(error); // handle any errors that may have occurred
      });
  }
  // UPLOAD FILE TO AWS IS SUCCESSFUL
  useEffect(() => {
    setPostData(false)
    
    if (hasData(uploadResponse)) {
      console.log("uploadResponse", uploadResponse)
      if (uploadResponse.uploadErrors.length > 0 ) {
        toast.error('There was an issue uploading your document. This submission did not go through.')
      }
      if (uploadResponse.uploadResponses.length > 0) {
        console.debug('postData', postData)
        setPostData(true)
        console.debug('Upload Successful!!!')
        console.debug('uploadResponse: ', uploadResponse)
        
      }
    }
  }, [uploadResponse, selectedFile, setPostData, postData ])
  
  // POST TO DB IF UPLOAD TO AWS IS SUCCESSFUL
  useEffect(() => {
    if (postData) {
      console.debug('formData: ', formData)
      console.debug('selectedFile: ', selectedFile)
      const filePath = selectedFile[0] ? selectedFile[0].path : ""
      const fileParts = parseFileNameType(filePath)
      // const fileName = fileParts.fileName
      const fileType = fileParts.fileType
      const fileSize = selectedFile[0] ? selectedFile[0].size.toString() : ""

      const variables: any = {
        userId: userId,
        created_by_id: loggedInUserId,
        academic_year: formData.academic_year,
        submission_type: formData.submission_type,
        submitted_name: formData.submitted_name,
        term: formData.term,
        professor_name: formData.professor_name,
        file_id_aws: fileId,
        file_display_name: formData.submitted_name,
        file_path: filePath,
        file_type: fileType,
        file_size: fileSize,
      }
      const validationResponse = validateFormDataNotEmpty(variables)
      console.debug('validationResponse', validationResponse)
      if (validationResponse.length > 0) {
        toast.error("Error: " + validationResponse)
      }
      console.debug("post to db, values to  post: ", variables)
      createCourseDocument({variables: variables})
    }
  }, [userId, loggedInUserId, postData, formData, selectedFile, fileId, createCourseDocument])

  useEffect(() => {
    if (errorDocTypes) {
      console.debug(errorDocTypes)
    }
    if (dataDocTypes) {
      console.debug(dataDocTypes)
      setSubmissionTypes(dataDocTypes.getDocumentTypes.document_types)
    }

  }, [errorDocTypes, dataDocTypes])

  useEffect(() => {    
    let toastMsg = ""
    if (loadingCreateDocument) {
      console.debug("loadingCreateDocument: ", loadingCreateDocument)
    }
    if (errorCreateDocument) {
      toastMsg = "Error Submiting Course Document: " + errorCreateDocument
      toast.error(toastMsg)
      console.debug('errorCreateDocument: ', errorCreateDocument)

    } else if (dataCreateDocument) {
      console.debug(dataCreateDocument)
      if (dataCreateDocument.createCourseDocument.successful) {        
        toastMsg = "Successfully Submitted Course Document "
        console.log(toastMsg)
        
        toast.success(toastMsg)
        
        window.location.href = `/bank/${userId}`
        
      } else {
        toastMsg = dataCreateDocument.createCourseDocument.message
        toast.error(toastMsg)
        console.log(`${toastMsg}: `)
        console.log(dataCreateDocument.createCourseDocument)
      }
      
    }
  }, [userId, loadingCreateDocument, errorCreateDocument, dataCreateDocument, selectedFile, formData.academic_year])

  if (academicYearSettings.status === null) {
    return <AcademicYearClosed section="Notes/Test Bank"/>
  }
  
	return (
		<>
      <LayoutContainer>
        <Stack spacing={3}>
          <Typography variant="h2">Submit a New Document</Typography>
          <Typography variant="h3">
            Please combine multiple sets of notes into one single file before uploading!
          </Typography>
          <NewDocumentForm errorFields={errorFields} submissionTypes={submissionTypes} formData={formData} setFormData={setFormData} handleSubmit={handleSubmit} setSelectedFile={setSelectedFile} selectedFile={selectedFile} currentAcademicYear={academicYearSettings.academic_year}/>
        </Stack>
      </LayoutContainer>
    </>
	);
};

function NewDocumentForm ({errorFields, submissionTypes, formData, setFormData, handleSubmit, setSelectedFile, selectedFile, currentAcademicYear}: any) {
  
  const navigate = useNavigate();
	const goBack = () => {
		navigate(-1);
	}

  return (
    <Stack spacing={3} sx={{ maxWidth: 340 }}>
      <TextField
        required
        error={errorFields.term || false}
        id="course-name"
        label="Course Name"
        value={formData.submitted_name}
        onChange={(event) => { handleFormDataChange(setFormData, formData, "submitted_name", event.target.value) }}
      />
      <Stack direction="row" spacing={3}>
        <TextField
          required
          error={errorFields.submitted_name || false}
          id="course-term"
          fullWidth
          select
          label="Course Term"
          value={formData.term}
          onChange={(event) => { handleFormDataChange(setFormData, formData, "term", event.target.value) }}
        >
          {courseTerms.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          required
          error={errorFields.academic_year || false}
          id="course-year"
          fullWidth
          select
          label="Course Year"
          value={formData.academic_year}
          onChange={(event) => { handleFormDataChange(setFormData, formData, "academic_year", event.target.value) }}
        >
          {courseYears(currentAcademicYear).map((option) => (
            <MenuItem 
              key={option.value} 
              value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
      <TextField
        required
        error={errorFields.professor_name || false}
        id="course-professor"
        label="Course Professor's Name"
        value={formData.professor_name}
        onChange={(event) => { handleFormDataChange(setFormData, formData, "professor_name", event.target.value) }}
      />
      <TextField
          required
          error={errorFields.submission_type || false}
          id="submission-type"
          fullWidth
          select
          label="Submission Type"
          value={formData.submission_type}
          onChange={(event) => { handleFormDataChange(setFormData, formData, "submission_type", event.target.value) }}
        >
          {submissionTypes.map((option: any) => (
            <MenuItem key={option.label} value={option.label}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <Box>
          <FileDropzone
            key={'dropzone-1'}
            setSelectedFile={setSelectedFile}
            selectedFile={selectedFile}
          />
        </Box>
        <Stack direction="row" spacing={2}>
          <Button onClick={goBack} variant="outlined">Cancel</Button>
          <Button variant="contained" onClick={handleSubmit}>Submit Attachment</Button>
        </Stack>
    </Stack>
  )
}

const courseTerms = [
  { value: 'fall', label: 'Fall', },
  { value: 'spring', label: 'Spring', },
  { value: 'summer', label: 'Summer', },
  { value: 'winter', label: 'Winter', },
];

const courseYears = (currentYear: string) => {
  const yearList = [];
  for (let i = parseInt(currentYear) - 2; i <= parseInt(currentYear); i++) {
    const year = String(i) 
    yearList.push({
      value: year,
      label: year
    });
  }
  return yearList;
}
// export default withPageState(NewDocument, {})
export default NewDocument