import React, { useState, useEffect } from 'react'

import { useQuery } from '@apollo/client';

import { Link } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';

import { formatGQLDate } from '../../../../components/utilities/Formatters';

import { GET_UCLA_FACULTY } from '../../../../Graphql/UclaFaculty/QueryUclaFaculty';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', minWidth: 10},
  { field: 'ucla_faculty_id', headerName: 'UID', width: 200 },
  { field: 'full_name', headerName: 'Full Name', width: 250 },
  { field: 'first_name', headerName: 'First', width: 150 },
  { field: 'last_name', headerName: 'Last', width: 150 },
  
];

export function ListOfFaculty() {
    const { loading: loadingFaculty, error: errorFaculty, data: dataFaculty } = useQuery(GET_UCLA_FACULTY, {fetchPolicy: "network-only"})

    const [ tableData, setTableData ] = useState([]);
    const [ pageSize, setPageSize ] = useState<number>(10);

    useEffect(() => {
        if (errorFaculty) {
          console.debug('Error Faculty:', errorFaculty)
        }
        if (dataFaculty) {
            console.debug('Update DataGrid - List of Faculty: ', dataFaculty.getFacultyList);
            setTableData(dataFaculty.getFacultyList);
        }

    }, [loadingFaculty, errorFaculty, dataFaculty]);

    return (
        <>
          <div className="container data-grid-users">
              <h4>Faculty</h4>
              <DataGrid 
                // checkboxSelection={checkboxSelection}
                autoHeight={true}
                rows={tableData}
                columns={columns} 
                pageSize={pageSize}
                onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
                rowsPerPageOptions={[2, 5, 10, 25, 100]}                
                density="compact"
                components={{
                    Toolbar: GridToolbar,
                }}
                componentsProps={{
                  toolbar: {
                    csvOptions:{
                      fileName: `ASC-Faculty List-${formatGQLDate({gQLDate: new Date().getTime(), format: "YYYYMMDD"})}`,
                    }
                  },
                }}
                initialState={{
                    // sorting: {
                    //     sortModel: [{ field: 'first_name', sort: 'asc' }],
                    // },
                    columns: {
                      columnVisibilityModel: {
                        id: false,
                        ucla_faculty_id: false,
                        first_name: true,
                        last_name: true,
                        created_on: false
                      }
                    }
                }}
                // onRowClick={handleOnCellClick}
              />
          </div>
        </>
    )
}

export default ListOfFaculty