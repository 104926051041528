import { gql } from "@apollo/client";

export const EMAIL_INVITE = gql`
	mutation emailInvite(
		$email: String!
		$firstName: String!
		$lastName: String!
		$scholarshipType: String!
		$uid: String!
	) {
		emailInvite(
			email: $email
			firstName: $firstName
			lastName: $lastName
			scholarshipType: $scholarshipType
			uid: $uid
		) {
			message
			successful
		}
	}
`;
