import React, { useContext, useEffect, useState }from 'react'
import { SettingsContext } from '../../components/authorization/GetAccessRights';
import { checkAdminAccess } from '../../components/authorization/PageState';

import styled from 'styled-components';
import { theme } from "../../themes/default";
import { Link, NavLink } from "react-router-dom";
import {
  AppBar,
  Avatar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Link as ExtLink
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../images/asc-logo.png'

import { hasData } from '../utilities/Validation'
import { doLogin, initAccessRights } from '../authorization/Utilities'
import { getReturnPage } from '../authorization/LoginLogout';

const settings = [
  {name: 'Edit Profile', path: '/profile'},
  {name: 'Logout', path: '/logout'},
];

// Desktop Nav Links
const StyledNavLink: any = styled(NavLink)`
  color: ${theme.palette.primary.main};
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  padding: 5px 0px;
  margin: 0 20px;
  &[aria-current="page"] {
    border-bottom: 2px solid ${theme.palette.secondary.main};
  }
`;

const StyledExtLink: any = styled(ExtLink)`
  color: ${theme.palette.primary.main};
  font-size: 16px;
  font-weight: 700;
  padding: 5px 0px;
  margin: 0 20px;
  border-bottom: 2px solid ${theme.palette.secondary.main};
`;

// Dropdown Menu Links
const StyledMenuLink = styled(NavLink)`
  color: ${theme.palette.grey[800]};
  text-decoration: none;
`;

var initPages = [
  {name: 'Program Participation', path: '/participation'},
  {name: 'Notes/Test Bank', path: '/bank'},
  {name: 'Update Personal Info', path: '/profile'},
  // {name: 'Admin', path: '/admin'},
];

function GlobalNav() {
  const settingsContext: any = useContext(SettingsContext)
  const accessRightsData = settingsContext.accessRights ?? initAccessRights

  const [pages, setPages] = useState(settingsContext.profileFilled  === true ? initPages : [])

  const [adminAccess, setAdminAccess] = useState(false);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [loggedInUser] = useState(localStorage.loggedInUser ? JSON.parse(localStorage.loggedInUser) : null);

  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    if (settingsContext.isLoading === false && settingsContext.profileFilled === true) {
      setPages(initPages)
    }
    if (settingsContext.isLoading === false && accessRightsData && hasData(accessRightsData)) {
      if (accessRightsData) {
        
        // const hasAdminAccess = () => {
        //   for (const key in accessRightsData) {
        //     if (Object.hasOwnProperty.call(accessRightsData, key)) {
        //       if (key.includes('admin') && accessRightsData[key] === true) {
        //         return true;
        //       }
        //     }
        //   }
        //   return false
        // }
        // setAdminAccess(hasAdminAccess() && settingsContext.profileFilled)
        setAdminAccess(checkAdminAccess(settingsContext) && settingsContext.profileFilled)
      }
      // console.debug('Global Nav Access Rights: ', accessRightsData)
    }
  }, [accessRightsData, pages, settingsContext])

  if (settingsContext.isLoading) {
    return <></>
  } 

  return (
    <AppBar position="relative" color="primary" sx={{ backgroundColor: 'white', zIndex: 100 }}>
      <Container>
        <Toolbar disableGutters>
          
          {/* Desktop Logo */}
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <LogoLink />
          </Box>

          {/* Mobile Menu & Logo */}
          {loggedInUser && (
          <>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                sx={{ color: theme.palette.primary.main }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {adminAccess && (
                  <MenuItem key="admin" onClick={handleCloseNavMenu}>
                    <StyledMenuLink to="/admin">Admin</StyledMenuLink>
                  </MenuItem>
                )}
                {pages.map((page) => (
                  <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                    <StyledMenuLink to={page.path}>{page.name}</StyledMenuLink>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </>
          )}

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <LogoLink />
          </Box>

          {/* Desktop Menu */}
          <Box 
            sx={{ 
              flexGrow: 1, 
              display: { xs: 'none', md: 'flex' }, 
              justifyContent: 'end',
              borderRight: '1px solid grey',
              mx: 3,
              my: 1,
              px: 1,
              py: 1
            }}
          >
            {adminAccess && (
              <Box
                key="admin"
                onClick={handleCloseNavMenu}
              >
                <StyledNavLink to="/admin" activeclassname="current">Admin</StyledNavLink>
              </Box>
            )}
            {pages.map((page) => (
              <Box
                key={page.name}
                onClick={handleCloseNavMenu}
              >
                <StyledNavLink to={page.path} activeclassname="current">{page.name}</StyledNavLink>
              </Box>
            ))}


          </Box>
          <Box sx={{ flexGrow: 0 }}>
            {loggedInUser ?
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <UserAvatar />
              </IconButton>
            </Tooltip>
            :
            <>
              <StyledExtLink href={doLogin('signin', getReturnPage("/participation"))} underline="none">Sign In</StyledExtLink>
            </>
            }
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting.name} onClick={handleCloseUserMenu}>
                  <StyledMenuLink to={setting.path}>{setting.name}</StyledMenuLink>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default GlobalNav;

function LogoLink() {
  return (
    <Link to="/">
      <img src={logo} alt="Alumni Scholars Club Logo" width={126}/>
    </Link>
  )
}

function UserAvatar() {
  const [loggedInUser] = useState(localStorage.loggedInUser ? JSON.parse(localStorage.loggedInUser) : null);
  const initials = loggedInUser ? loggedInUser.first_name.substring(0,1) + loggedInUser.last_name.substring(0,1) : '-'
  return (
    <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
      {initials} 
    </Avatar>
  )
}