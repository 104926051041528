import moment from "moment";

export function formatGQLDate(props: any) {
	// formatDirection: r=read, w=write

	const formatDirection = props.formatDirection ?? "r";

	if (!sameAsFormat(props.gQLDate, props.format)) {
		//convert if unix time is passed in

		const f = props.format ? props.format : "MM/DD/YYYY @ h:mm A";
		const d = props.gQLDate ? new Date(parseInt(props.gQLDate)) : null;

		const midnightDate = d ? new Date(d.setUTCHours(0, 0, 0, 0)) : null;

		const useDate = formatDirection === "w" ? midnightDate : d;

		// console.debug(
		// 	"formatGQLDate:",
		// 	formatDirection,
		// 	d,
		// 	midnightDate,
		// 	moment(useDate).utc().format(f)
		// );

		if (useDate) {
			return moment(useDate).utc().format(f);
		}
	}

	return props.gQLDate;
}

export function formatAcademicYearRange(academicYear: string) {
	return `${parseInt(academicYear) - 1}-${academicYear}`;
}

function sameAsFormat(input: any, format = "YYYY-MM-DD") {
	// Check to see if the input === the format
	try {
		const date = new Date(input);
		const dateString = date.toISOString().substr(0, 10);

		if (dateString === input) {
			return true;
		} else {
			return false;
		}
	} catch {
		return false;
	}
}

export function removeElement(array: Array<any>, element: any) {
	const index = array.indexOf(element); // get the index of the element in the array
	if (index > -1) {
		// check if the element is in the array
		array.splice(index, 1); // remove the element from the array using splice
	}
}

export function addUniqueElement(array: Array<any>, element: any) {
	if (!array.includes(element)) {
		// check if the element is not already in the array
		array.push(element); // add the element to the array
	}
}

export function sortObjectsByKey(objects: any, key: string, direction = "asc") {
	const mutableObjects = objects.slice(); // Make a copy of the input array
	const compareFunction = (a: any, b: any) => {
		const valueA = a[key];
		const valueB = b[key];
		let comparison = 0;
		if (valueA > valueB) {
			comparison = 1;
		} else if (valueA < valueB) {
			comparison = -1;
		}
		return direction.toLowerCase() === "desc"
			? comparison * -1
			: comparison;
	};
	return mutableObjects.sort(compareFunction);
}

export function formatFileSize(fileSize: number) {
	const kb = fileSize / 1000;
	const mb = kb / 1000;
	const size = mb < 1 ? `${kb} kb` : `${Math.round(mb)} MB`;
	return size;
}

export function titleCase(str: string) {
	if (!str) {
		return "";
	}
	const skipWords = ["a", "an", "and", "the", "for", "of"];
	return str
		.toLowerCase()
		.split(" ")
		.map((word: string, index: number) => {
			if (skipWords.includes(word) && index !== 0) {
				// skip this word if it's in the skipWords list and not the first word
				return word;
			} else {
				// capitalize the first letter of the word
				return word.charAt(0).toUpperCase() + word.slice(1);
			}
		})
		.join(" ");
}

export function chunkArray(array: any, chunkSize: number) {
	const chunks = [];
	for (let i = 0; i < array.length; i += chunkSize) {
		chunks.push(array.slice(i, i + chunkSize));
	}
	return chunks;
}
