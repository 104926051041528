import styled from 'styled-components';

export const DataGridStyle = styled.div`
  .data-cell-red {
    color: red;
    font-weight: bold;
  }
  .data-cell-green {
    color: green;
    font-weight: bold;
  }
`;

export default DataGridStyle