import { useState, useEffect }from "react"

import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { Link } from "@mui/material";

import LoadingSpinner from "../../../../components/progress/Spinner";
import { formatGQLDate } from '../../../../components/utilities/Formatters';

import { UpdateParticipationStatus } from '../helpers/Events'

export function UserParticipationLog(props: any) {
  // const { userId, data, dgLoading } = props
  const userId = props.id
  const data = props.data
  const dgLoading = props.dgLoading
  const refetchQuery = props.refetchQuery //apollo refetch

  const [ tableData, setTableData ] = useState([]);
  const [ pageSize, setPageSize ] = useState<number>(25);

  const updateParticipationStatus = UpdateParticipationStatus({userId: userId, refetchData: 'user', refetchQuery: refetchQuery})

  function handleActionClick (id: string, status: string) {
    updateParticipationStatus(id, status)

  }

  //set datagrid data
  useEffect(() => {
    // console.log('props')
    // console.log(props)

    const participations = data.participations
    if (participations) {
      console.debug(`UserParticipationLog Component`)
      console.debug(participations)
      setTableData(participations);
    }
  }, [props, data])

  const columns: GridColDef[] = [
    { 
      field: 'actions', 
      headerName: 'Actions', 
      width: 125,
      renderCell: (params) =>
        <>
          <Link 
            onClick={(event) => handleActionClick(params.row.id, "approved")} 
            underline="none"
            sx={{ cursor: 'pointer' }}
          >
            Approve
          </Link>
          &nbsp;|&nbsp;
          <Link 
            onClick={(event) => handleActionClick(params.row.id, "denied")} 
            underline="none" 
            color="error"
            sx={{ cursor: 'pointer' }}
          >
            Deny
          </Link>
        </>,
    },
    { field: 'academic_year', headerName: 'Academic Year', width: 125, type: 'number', align:'center',
      renderCell: (params: any) =>
        <>
          {params.row.academic_year ? `${params.row.academic_year}` : "-"}
        </> 
    },
    { field: 'participation_status', headerName: 'Status', width: 90 },
    { field: 'participation_status_changed_on', headerName: 'Approved / Denied Date', width: 175, type: 'date', valueGetter: (params: any) => params.row.participation_status !== 'pending' ? formatGQLDate({gQLDate: params.row.participation_status_changed_on, format: "MM/DD/YYYY"}) : "-" },
    { field: 'participation_category', headerName: 'Type', width: 75 },
    { 
      field: 'name', 
      headerName: 'Participation Title',  
      width: 300,
      renderCell: (params) =>
        <>
          <Link href={`/admin/user/participation/details/${params.row.id}`} underline="none"> {params.row.name}</Link>
        </>,
    },
    { field: 'participation_hours', headerName: 'Hours', width: 75, type: 'number', renderCell: (params) =>
      <>
        {params.row.participation_category === 'event' ? "-" : params.row.participation_hours }
      </>
    },
    { field: 'participation_date', headerName: 'Participation Date', width: 150, 
      renderCell: (params: any) =>
        <>
          {params.row.participation_date ? formatGQLDate({gQLDate: params.row.participation_date, format: "MM/DD/YYYY"}) : "-"}
        </> 
    },
    { field: 'created_on', headerName: 'Created On', width: 175, type: 'date', valueGetter: (params: any) => params.row.created_on ? formatGQLDate({gQLDate: params.row.created_on, format: "MM/DD/YYYY"}) : "-" },
  ];

  return (
    <>
      { dgLoading && 
        <LoadingSpinner />
      }
      <DataGrid 
        // checkboxSelection={checkboxSelection}
        autoHeight={true}
        rows={tableData ?? []}
        columns={columns} 
        pageSize={pageSize}
        onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
        rowsPerPageOptions={[2, 5, 10, 25, 100]}                
        density="compact"
        components={{
            Toolbar: GridToolbar,
        }}
        componentsProps={{
          toolbar: {
            csvOptions:{
              fileName: `ASC-User Participation Log-${formatGQLDate({gQLDate: new Date().getTime(), format: "YYYYMMDD"})}`,
            }
          },
        }}
        initialState={{
          sorting: {
              sortModel: [{ field: 'created_on', sort: 'asc' }],
          },
          columns: {
            columnVisibilityModel: {
              // participation_date: false,
              updated_on: false,
              // created_on: false
            }
          }
        }}
        // onRowClick={handleOnCellClick}
    />
    </>
  )
}

export default UserParticipationLog