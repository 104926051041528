import { Toaster } from "react-hot-toast"
import { theme } from "../../themes/default"

export const Toast = () => {
  return (
    <Toaster 
      toastOptions={{
        position: 'bottom-right',
        style: {
          borderRadius: theme.shape.borderRadius,
          fontSize: 15,
        },
        success: {
          style: {
            color: theme.palette.success.contrastText,
            backgroundColor: theme.palette.success.main,
          },
          iconTheme: {
            primary: theme.palette.success.contrastText,
            secondary: theme.palette.success.main,
          },
        },
        error: {
          style: {
            color: theme.palette.error.contrastText,
            backgroundColor: theme.palette.error.main,
          },
        },
      }}
    />
  )
}