// CHECK OUT: https://github.com/UgnisSoftware/react-spreadsheet-import - swap dropzone out for this? Currently not supporting react 18
import React, { useState, useEffect } from 'react'
// import { SettingsContext } from '../../../components/authorization/GetAccessRights';
// import { withPageState } from '../../../components/authorization/PageState'

import { useLazyQuery, useMutation } from '@apollo/client';

import toast from 'react-hot-toast';

import { BULK_IMPORT_USERS } from '../../../Graphql/Users/MutateUser'
import { GET_ALL_USERS } from '../../../Graphql/Users/QueryUser'

import { useDropzone } from 'react-dropzone'
import * as XLSX from 'xlsx'

import { DataGrid, GridRowParams, GridToolbar } from '@mui/x-data-grid';
import DataGridStyle from '../../../components/container/DataGridStyle';
import styled from 'styled-components';

import LinkButton from "../../../components/button/Button"
import { Box, Divider, Stack, Typography, Button } from "@mui/material";
import { chunkArray, formatGQLDate } from '../../../components/utilities/Formatters';
import LoadingSpinner from '../../../components/progress/Spinner';

const getColor = (props: any) => {
  if (props.isDragAccept) {
      return '#00e676';
  }
  if (props.isDragReject) {
      return '#ff1744';
  }
  if (props.isFocused) {
      return '#2196f3';
  }
  return '#eeeeee';
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;

const NewUserBulk = (props: any) => {
  // const adminPage = "admin_users"
  // const settings: any = useContext(SettingsContext)
  // const loggedInUserId = getLoggedInUser(settings)
  // const academicYearSettings = settings.academicYearSettings
  // const allowAccess = settings.accessRights[adminPage]

  const [ importUsers, { loading: loadingImportUsers, error: errorImportUsers, data: dataImportUsers } ] = useMutation(BULK_IMPORT_USERS)
  const [ getRecordsInASC, { loading: loadingUsers, data: dataRecordsInASC }] = useLazyQuery(GET_ALL_USERS, {fetchPolicy: "network-only"});

  const [ mappedData, setMappedData ] = useState(Array<any>)

  const [ fileName, setFileName ] = useState('')
  const [ rowsOrigFile, setRowsOrigFile ] = useState(Array<any>)

  const [ rowsValid, setRowsValid ] = useState(Array<any>)
  const [ rowsInvalid, setRowsInvalid ] = useState(Array<any>)

  const [ columnsAfterValidation, setColumnsAfterValidation ] = useState(Array<any>)
  const [ rowsAfterValidation, setRowsAfterValidation ] = useState(Array<any>)
  const [ pageSizeAfterValidation, setPageSizeAfterValidation ] = useState<number>(25); //Set default Page Size here

  const [ columnsFinal, setColumnsFinal ] = useState(Array<any>)
  const [ rowsFinal, setRowsFinal ] = useState(Array<any>)
  const [ pageSizeFinal, setPageSizeFinal ] = useState<number>(25); //Set default Page Size here

  const [ columnsEmailResponse, setColumnsEmailResponse ] = useState(Array<any>)
  const [ rowsEmailResponse, setRowsEmailResponse ] = useState(Array<any>)
  const [ pageSizeEmailResponse, setPageSizeEmailResponse ] = useState<number>(25); //Set default Page Size here

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    maxFiles: 1,
    onDropAccepted,
    multiple: false,
    accept: {'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [], 'application/vnd.ms-excel': []}});

  
  // File Drop
  async function onDropAccepted (acceptedFiles: any) {
    // Init Data grids. Make sure there's no data
    setRowsOrigFile([])
    setRowsAfterValidation([]) 
    setRowsFinal([]) 
    setRowsEmailResponse([]) 

    console.debug('File Accepted: ', acceptedFiles)

    const file = acceptedFiles[0]
    
    
    setFileName(file.name)
    let reader = new FileReader();

    reader.onload = function(e: any) {

      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, {type: 'array'});
      const worksheet = workbook.Sheets[workbook.SheetNames[0]]; //Get first sheet

      // convert to json format
      const jsonData: Array<any> = XLSX.utils.sheet_to_json(worksheet);

      setRowsOrigFile(jsonData)

      // console.log(jsonData)
      const validInValidStudents = cleanAndValidateStudentData(jsonData)
      setRowsValid(validInValidStudents.valid)
      setRowsInvalid(validInValidStudents.invalid)

      console.debug("jsonData AFTER clean and validate: ", jsonData)

      setColumnsAfterValidation(createColumnsFromJson(jsonData))
      setRowsAfterValidation(jsonData)

      setMappedData(mapExcelToUserModel(validInValidStudents.valid))

    };

    reader.readAsArrayBuffer(file);
    
  }

  // Validate CRM_ID and Student ID (UCLA ID) lengths
  function cleanAndValidateStudentData(data: any) {
    
    const requiredCRMIDLength = 10
    const requiredIDLength = 9
    const validStudents: Array<any> = []
    const inValidStudents: Array<any> = []

    console.debug("student data before clean and validate: ", data)
  
    data.forEach((student: any, index: number) => {
      // skip index = 0. Should be header\
      const studentCRMID = student["stu_crm_id"]
      const studentCRMIDLength = studentCRMID ? studentCRMID.toString().trim().length : 0
      const isValidCRMID = studentCRMIDLength === requiredCRMIDLength

      const studentID = student["stu_id"]
      const studentIDLength = studentID ? studentID.toString().trim().length : 0

      const isValidID = studentIDLength === requiredIDLength
      
      const parsedFullName = parseStudentFirstLastName(student["stu_full_name"])
      student.asc_first_name = parsedFullName.first_name
      student.asc_last_name = parsedFullName.last_name

      let validationMessage = []
      // Validatation
      if (!isValidCRMID) {
        const msgInValidCRMID = `CRM ID is ${studentCRMIDLength} digits in length. Needs to be ${requiredCRMIDLength}.`
        validationMessage.push(msgInValidCRMID)
      }

      if (!isValidID) {
        const msgInValidID = `Student ID is ${studentIDLength} digits in length. Needs to be ${requiredIDLength}.`
        validationMessage.push(msgInValidID)
      }

      if (!isValidCRMID || !isValidID) {
        student.bulk_upload_is_valid_record = false
        student.bulk_upload_validation_message = validationMessage.join(" ")
        inValidStudents.push(student)
      } else {
        student.bulk_upload_is_valid_record = true
        student.bulk_upload_validation_message = ""
        validStudents.push(student)
      }
    })
    
    // console.log("student data AFTER clean and validate")
    // console.log(data)
    // console.log("valid students")
    // console.log(validStudents)
    // console.log("Not Valid Student Data")
    // console.log(inValidStudents)
    return { valid: validStudents, invalid: inValidStudents }
  }

  function parseStudentFirstLastName(fullName: string) {
    const parsedStudentFullName = fullName.split(", ")
    const studentFirstName = parsedStudentFullName[1]
    const studentLastName = parsedStudentFullName[0]
    return {
      first_name: studentFirstName,
      last_name: studentLastName
    }
  }
  
  function createColumnsFromJson(data: Array<any>, excludeFields: Array<any> = []) {
    let columns: Array<any> = []
    let keys = Object.keys(data[0])

    keys.forEach((columnName) => {
      let width: number = 100

      // Bulk Import Related fields
      if (
          columnName === 'aid_id_desc' || 
          columnName === 'major' || 
          columnName === 'first_name' || 
          columnName === 'last_name' ||
          columnName === 'scholarship_name' ||
          columnName === 'major_degree'
        ) {
        width = 200
      }
      if (
        columnName === 'apl_email_addr' || 
        columnName === 'stu_full_name' || 
        columnName === 'email' 
      ) {
        width = 250
      }
      if (columnName === 'bulk_upload_validation_message') {
        width = 500
      }

      // Email Response Related Fields
      if (columnName === 'msg') {
        width = 500
      }
      if (columnName === 'scholarship_type') {
        width = 350
      }

      if (!excludeFields.includes(columnName)) {
        let dgAttributes: any = { field: columnName, headerName: columnName, width: width }
        // add other attributes if needed
        if (columnName === 'success') {
          dgAttributes.type = 'boolean'
        }

        columns.push(dgAttributes)
      }
    })
    return columns
  }

  function mapExcelToUserModel(data: any) {
    // Return object mapped to args required for the Create User mutation
    // Fields not defined in the template except for stu_crm_id will be skipped and not imported.

    var mappedRecords: Array<any> = []
    // column name from excel file: column in ASC DB
    // If there are any changes to the mapping, make sure other functions or references to fields are modified. 
    // eg) createColumnsFromJson(), cleanAndValidateStudentData()
    
    const mapping = {
      "stu_crm_id": "crm_id", //Needs to be added to financial aid Excel
      "awd_per": "", //Award Period?
      "stu_id": "ucla_id",
      "stu_full_name": "", //Financial Aid File full name to be split
      "aid_id": "scholarship_id",
      "aid_id_desc": "scholarship_name",
      "awd_per_offer_amt": "",
      "app_lvl_entry_cd": "financial_aid_app_lvl_entry", //eg Undergraduate Level
      "adm_trm": "financial_aid_app_adm_trm", //eg 22F = Fall 2022
      "major": "major_degree",
      "apl_email_addr": "email",
      "home_phone": "",
      "cell_phone": "phone",
      "city": "city",
      "state": "state",
      "home_loc_name": "",
      "ctzn_country_ttl ": "country",
      "fin_aid_alumni_region": "",
      "fin_aid_ethnicity": "",
      "asc_first_name": "first_name",
      "asc_last_name": "last_name",
      "graduation_year": "graduation_year"
    }

    data.forEach((student: any) => {
      var tempObj: any = {}
      for (const [key, value] of Object.entries(mapping)) {
        if (value) {
          //TEMP Make up CRM_ID, CRM_ID needs to be added to the new sheet -- comment out
          // if (key === 'stu_crm_id') {
          //   student[key] = "C" + student["stu id"]
          // }
          if (key === 'graduation_year') {
            const admissionYear = getNumberFromString(student["adm_trm"]) + 2000
            const graduationYear = student["app_lvl_entry_cd"] === 'FR' ? admissionYear + 4 : student["app_lvl_entry_cd"] === 'AS' ? admissionYear + 2 : ""
            
            // console.log('admissionYear, graduationYear', admissionYear, graduationYear)
            student[key] = graduationYear
          }
          // console.debug("mapExcelToUserModel()", value, key, student[key], typeof student[key])
          if (student[key]) {
            tempObj[value] = typeof student[key] === 'string' ? student[key] : student[key].toString()
          } 
        }
      }
      mappedRecords.push(tempObj)
      // console.log('tempObj for Student: ' + student["stu id"])
      // console.log(tempObj)
    })

    console.debug('bulk student mappedRecords: ', mappedRecords)
    return mappedRecords
  }

  function getNumberFromString(s: string) {
    const matches = s.match(/\d+/g) ?? [];
    const lastMatch = matches[matches.length - 1];
    // console.log('getNumberFromString', lastMatch)
    return parseInt(lastMatch);
  }

  // Click Event
  function handleSubmit() { 
    console.debug('Submit Bulk Records')
    const importChunks:Array<any> = chunkArray(rowsFinal, 300)
    let msg = `Number of Records to Import: ${rowsFinal.length}, Chunks based on 300 records at a time: ${importChunks.length}`
    console.debug(msg)
    toast.success(msg)
    
    let count = 0
    for (const chunk of importChunks) {
      count++
      msg = `Importing chunk ${count}`
      console.debug(msg, chunk)

      toast.success(msg)
      
      importUsers({
        variables: { userList: chunk }
      })
    }
    
    
  }

  function handleReturnClick() {
    window.location.href ="/admin/users"
  }
  // UseEffect to Check if Valid Students in Excel Sheet are already in ASC DB
  useEffect(() => {
    // console.log('Check if Valid Students in Excel Sheet are already in ASC DB')
    // console.log(mappedData)
    var userInputs: Array<any> = []
  
    mappedData.forEach((record: any) => {
      userInputs.push({
        ucla_id: record.ucla_id,
        // email: record.email
      })
    })

    if (userInputs.length > 0) {
      getRecordsInASC({
        variables: {
          userInputs: userInputs,
        }}
      )
    }

  }, [mappedData, getRecordsInASC])

  //Clean List to Import. Remove any that already exist in the ASC DB based on the UCLA_ID
  useEffect(() => {
    
    if (dataRecordsInASC) {
      console.debug('dataRecordsInASC: ', dataRecordsInASC.getAllUsers)
      const recordsToImport: Array<any> =  removeArrayObjDuplicates( dataRecordsInASC.getAllUsers, mappedData, "ucla_id")

      console.debug('recordsToImport: ', recordsToImport)
      
      if (recordsToImport.length > 0) {
        setColumnsFinal(createColumnsFromJson(recordsToImport))
        setRowsFinal(recordsToImport)

      } else {
        console.debug('No Records To Import')
      }
    } 

    function removeArrayObjDuplicates(arr1: Array<any>, arr2: Array<any>, key: string) {
      //Return objects from arr2 that are not in arr1
      let uniqueArr:Array<any> = arr2.filter(obj => !arr1.some(o => o[key] === obj[key]));
      return uniqueArr;
    }

  }, [dataRecordsInASC, mappedData])

  // Import Response
  useEffect(() => {
    let toastMsg: string = ''

    if (errorImportUsers) {
      console.error('errorImportUsers: ', errorImportUsers)

      toastMsg = "Error Importing List";
      toast.error(toastMsg)
        
    }  
    if (dataImportUsers) {
      console.debug('dataImportUsers response: ', dataImportUsers.importUsers)
      if (dataImportUsers.importUsers.successful) {
        console.debug('dataImportUsers email responses: ', dataImportUsers.importUsers.email_responses) //Sometimes Console doesn't allow expanding the array.
        const emailResponses = dataImportUsers.importUsers.email_responses
        toast.success("Successfully Bulk Imported users.")

        setColumnsEmailResponse(createColumnsFromJson(emailResponses, ["__typename"]))

        setRowsEmailResponse((prevResponses) => [...prevResponses, ...emailResponses]);
        // setTimeout(() => {
        // console.debug("Redirect to /admin/users")
        // window.location.href = `/admin/users`
        // }, 3000);
          

      } else {
          toastMsg = dataImportUsers.importUsers.message
          toast.error(toastMsg)
      }
    } 
    if (toastMsg) {
        console.debug(`Toast Message: ${toastMsg}`)
    }

  }, [ loadingImportUsers, errorImportUsers, dataImportUsers])


	return (
		<>
      { loadingImportUsers && 
        <LoadingSpinner/>
      }
      <LinkButton to="/admin/users" variant="outlined" type="back" reload>
        Return to User Management
      </LinkButton>
      <Stack spacing={4} sx={{ mt: 4, mb:3 }}>
        <Box>
          <Typography variant="h2">Bulk Add New Users</Typography>
          <Typography>To add users in bulk, please upload a file that matches the expected structure.</Typography>
        </Box>
      </Stack>
      <Container {...getRootProps({isFocused, isDragAccept, isDragReject})}>
        <input {...getInputProps()} />
        <p>Drag and drop file here, or click to select file</p>
        <em>(Only *.xlsx and *.xls files will be accepted)</em>
        <p>{fileName ? 'Filename Selected: ' + fileName : ''}</p>
      </Container>
      <Stack spacing={3} sx={{ maxWidth: 250, mt: 3 }}></Stack>

      {/* -----------------------------
        Excel/CSV Records with Validation DataGrid
        -------------------------------*/}
      {rowsOrigFile.length > 0 &&
        <DataGridStyle>
          <p># of Students in "{fileName}": <strong>{rowsOrigFile.length}</strong></p>
          <p># of Valid Record(s): <strong>{rowsValid.length}</strong></p>
          {rowsInvalid.length > 0 &&
            <p># of Rejected Record(s): <strong>{rowsInvalid.length}</strong></p>
          }
          <DataGrid
            // checkboxSelection={checksboxSelection}
            autoHeight={true}
            getRowId={(row) => row["stu_id"]}
            rows={rowsAfterValidation}
            columns={columnsAfterValidation} 
            pageSize={pageSizeAfterValidation}
            onPageSizeChange={(newPageSize: number) => setPageSizeAfterValidation(newPageSize)}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}                
            density="compact"
            components={{
              Toolbar: GridToolbar,
            }}
            componentsProps={{
              toolbar: {
                csvOptions:{
                  fileName: `ASC-Bulk User Import-Validations-${formatGQLDate({gQLDate: new Date().getTime(), format: "YYYYMMDD"})}`,
                }
              },
            }}
            initialState={{
              sorting: {
                  sortModel: [{ field: 'stu full name', sort: 'asc' }],
              },
            }}
            getRowClassName={(params: GridRowParams<any>) => {
              // console.log(params)
              if (params.row["bulk_upload_is_valid_record"] === false) {
                return 'data-cell-red';
              }
              return ''
            }}
            // onRowClick={handleOnCellClick}
          />
        </DataGridStyle>
      }
      {rowsFinal.length === 0 && fileName && (
        <>
          <p>
            <strong>
              {rowsFinal.length} Records to Import.
              {rowsOrigFile.length === rowsValid.length && " All records already exist in the ASC DB."}
              {rowsOrigFile.length === rowsInvalid.length && " Errors were detected in the Import File."}
            </strong>
          </p>
        </>
      )}

      {/* -----------------------------
        Records to Import DataGrid
        -------------------------------*/}
      {rowsFinal.length > 0 &&
        <>
          <p># of Records to import: <strong>{rowsFinal.length}</strong> out of {rowsAfterValidation.length}</p>
          <DataGrid 
            // checkboxSelection={checkboxSelection}
            autoHeight={true}
            getRowId={(row) => row["ucla_id"]}
            rows={rowsFinal}
            columns={columnsFinal} 
            pageSize={pageSizeFinal}
            onPageSizeChange={(newPageSize: number) => setPageSizeFinal(newPageSize)}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}                
            density="compact"
            components={{
              Toolbar: GridToolbar,
            }}
            componentsProps={{
              toolbar: {
                csvOptions:{
                  fileName: `ASC-Bulk User Import-Imported Students-${formatGQLDate({gQLDate: new Date().getTime(), format: "YYYYMMDD"})}`,
                }
              },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'last_name', sort: 'asc' }],
              },
            }}
          />
        </>
      }
      {/* -----------------------------
        Email Response DataGrid
        -------------------------------*/}
      {rowsEmailResponse.length > 0 &&
        <>
          <p>Email Response</p>
          <DataGrid 
            autoHeight={true}
            getRowId={(row) => row["uid"]}
            rows={rowsEmailResponse}
            columns={columnsEmailResponse} 
            pageSize={pageSizeEmailResponse}
            onPageSizeChange={(newPageSize: number) => setPageSizeEmailResponse(newPageSize)}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}                
            density="compact"
            components={{
              Toolbar: GridToolbar,
            }}
            componentsProps={{
              toolbar: {
                csvOptions:{
                  fileName: `ASC-Bulk User Import-Email Response After Import-${formatGQLDate({gQLDate: new Date().getTime(), format: "YYYYMMDD"})}`,
                }
              },
            }}
            initialState={{
              // sorting: {
              //   sortModel: [{ field: 'last_name', sort: 'asc' }],
              // },
            }}
          />
        </>
      }
    
    {(rowsFinal.length === 0 || rowsEmailResponse.length > 0) && fileName &&
      <Stack spacing={3} sx={{ maxWidth: 350, mt: 3 }}>
        <LinkButton to="/admin/users" variant="outlined" type="back" reload="true">
          Return to User Management
        </LinkButton>
      </Stack>
    }

    {rowsFinal.length > 0 && fileName && rowsEmailResponse.length === 0 && 
      <Stack spacing={3} sx={{ maxWidth: 250, mt: 3 }}>
        <Button 
          variant="contained"
          onClick={() => { 
            handleSubmit()
          }}>Submit</Button>
      </Stack>
    }
		</>
	)
}

// export default withPageState(NewUserBulk, { pageSection: "admin", adminAccess: "admin_users" })
export default NewUserBulk
