import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
// import React, { useContext, useState, useEffect } from 'react'
// import { SettingsContext } from '../../../components/authorization/GetAccessRights';
// import { withPageState } from '../../../components/authorization/PageState'

import { useParams } from "react-router-dom"

// import { getLoggedInUser } from "../../../components/authorization/Utilities"

import { Box, Button, Link, Paper, Stack, Typography } from "@mui/material";
import LinkButton from "../../../components/button/Button";

import { formatGQLDate, titleCase } from '../../../components/utilities/Formatters';

import { GetUsersByParticipation } from '../participation/helpers/LoadData'
import { UpdateParticipationStatus } from '../participation/helpers/Events'
import { DeleteParticipation } from '../participation/helpers/Events'


const SubmissionDetails = () => {
  // const adminPage = "admin_participation"
  // const settings: any = useContext(SettingsContext)
  // const loggedInUserId = getLoggedInUser(settings)
  // const academicYearSettings = settings.academicYearSettings
  // const allowAccess = settings.accessRights[adminPage]

  const params = useParams()
  const id: string = params.id ? params.id : ''

  const [ user, setUser ] = useState<any>({})
  const [ participation, setParticipation ] = useState<any>({})

  const updateParticipationStatus = UpdateParticipationStatus({goBackHistory: -1})
  const deleteParticipation = DeleteParticipation({redirectUrl: `/admin/user/participation/${user.id}`})
  
  const response: any = GetUsersByParticipation({participation_id: id})
  const userData: any = response.userData
  
  useEffect(() => {
    if (userData.getUsersByParticipation && userData.getUsersByParticipation.length > 0) {
      console.debug("userData.getUsersByParticipation: ", userData.getUsersByParticipation)
      setUser(userData.getUsersByParticipation[0]);
      setParticipation(userData.getUsersByParticipation[0].participations[0])
    }
  }, [userData, setUser, setParticipation])
  

  return (
		<>
      <Stack spacing={5}>
        <LinkButton variant="outlined" type="back">Back</LinkButton>
        <Typography variant="h2">Program Participation Submission Details</Typography>
        <Paper elevation={3}>
          <Box p={3}>
            <Stack spacing={2}>
              <Typography>
                <strong>Member Name:</strong> {user.last_name}, {user.first_name} &nbsp;
                <small>
                  <Link href={`/admin/user/${user.id}`} underline="none">edit profile</Link>
                  &nbsp;|&nbsp;
                  <Link href={`/admin/user/participation/${user.id}`} underline="none">view participation</Link>
                </small>
              </Typography>
              <Typography><strong>Participation Category:</strong> {titleCase(participation.participation_category)}</Typography>
              <Typography><strong>Title of {titleCase(participation.participation_category)}:</strong> {participation.name}</Typography>
              <Typography><strong>Date of {titleCase(participation.participation_category)}:</strong> {participation.participation_date ? formatGQLDate({gQLDate: participation.participation_date, format: "MM/DD/YYYY"}) : "-"}</Typography>
              <Typography><strong>Description of {titleCase(participation.participation_category)}:</strong> {participation.description}</Typography>
              <Typography><strong>Number of Hours Served:</strong> {participation.participation_category === 'event' ? "N/A" : participation.participation_hours}</Typography>
              <Typography><strong>Service Organization:</strong> {participation.participation_category === 'event' ? "N/A" : participation.sponsor_organization}</Typography>
              <Typography><strong>Service Supervisor:</strong> {participation.participation_category === 'event' ? "N/A" : participation.sponsor_supervisor}</Typography>
              <Typography><strong>Service Supervisor Contact:</strong> {participation.participation_category === 'event' ? "N/A" : participation.sponsor_contact_info}</Typography>
            </Stack>
          </Box>
        </Paper>
        <Stack spacing={2} direction="row">
          <Button variant="outlined" color="error"
            onClick={(event) => updateParticipationStatus(id, "denied")}
          >Deny Submission</Button>
          <Button variant="contained"
            onClick={(event) => updateParticipationStatus(id, "approved")}>Approve Submission</Button>
        
          <Button variant="outlined"
              onClick={(event) => updateParticipationStatus(id, "pending")}>Change Back to Pending</Button>
          {/* <Button variant="outlined"
              onClick={(event) => deleteParticipation(id)}>DELETE Submission</Button> */}
        </Stack>
      </Stack>
    </>
	);
};

// export default withPageState(SubmissionDetails, { pageSection: "admin", adminAccess: "admin_participation" })
export default SubmissionDetails