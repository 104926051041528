import React, { useContext, useState, useEffect } from 'react'
// import { SettingsContext } from '../../../components/authorization/GetAccessRights';
// import { withPageState } from '../../../components/authorization/PageState'

import { useParams } from "react-router-dom"
import { useQuery } from '@apollo/client'

// import { getLoggedInUser } from "../../../components/authorization/Utilities"

import { Stack, Typography } from "@mui/material";

import { GET_ONE_USER } from '../../../Graphql/Users/QueryUser'

import { ParticipationForm } from "../../../components/forms/ParticipationForm";

const NewParticipation = () => {
  // const adminPage = "admin_participation"
  // const settings: any = useContext(SettingsContext)
  // const loggedInUserId = getLoggedInUser(settings).id || null
  // const academicYearSettings = settings.academicYearSettings

  const params = useParams()
  const id: string = params.id ? params.id : ''

  const { error: errorUser, data: dataUser } = useQuery(GET_ONE_USER, { variables: { id: id }, fetchPolicy: "network-only"})

  const [ user, setUser ] = useState<any>({})

  useEffect(() => {           
    if (!errorUser && dataUser) {
      console.log(dataUser)
      setUser(dataUser.getOneUser);
    }
  }, [id, errorUser, dataUser, setUser ])

  return (
		<>
      <Stack spacing={5}>
        <Typography variant="h2">Submit Program Participation (Admin)</Typography>
        <Typography variant="h4">For User: {user.last_name}, {user.first_name}</Typography>
        <ParticipationForm userId={id} data={dataUser} />
      </Stack>
    </>
	);
};

// export default withPageState(NewParticipation, { pageSection: "admin", adminAccess: "admin_participation" })
export default NewParticipation