import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { doLogout, doLogin, deleteCookie, getCookie } from './util'

const returnPage = window.location.protocol+'//'+window.location.hostname+(window.location.port ? ':'+window.location.port: '') + '/login_demo';

// this page will be for users who can login on the CIDM side and have a CRM ID, but they aren't in the ASC database yet (or there's a mistake)
const UserNotFoundInAscDatabase = () => {
  return (
		<>
			<Typography variant="h1">ASC Login/Logout Demonstration Page</Typography>
			<br/>&nbsp;<br/>
			&nbsp; We cannot find you in the ASC user database. Please use the email on which you were sent your invitation. If you need help contact
			ASC support at ZZZZZZZZ@support.ucla.edu.
			<br/>&nbsp;<br/>
			&nbsp;<button onClick={() => doLogin('signin', returnPage)}>Login Again</button>
			<br/>&nbsp;<br/>
			<div></div>
		</>
	);
};

export default UserNotFoundInAscDatabase;

