import { gql } from "@apollo/client";

export const GET_MLL = gql`
	query getMLL($academic_year: String, $faculty_name: String) {
		getMLL(academic_year: $academic_year, faculty_name: $faculty_name) {
			id
			academic_year
			faculty_name
			student_email
			student_first_name
			student_last_name
			created_on
		}
	}
`;

export const GET_MLL_STATUS = gql`
	query getMLLStatus {
		getMLLStatus {
			active
		}
		getSettingsCurrentAcademicYear {
			academic_year
		}
	}
`;

export const GET_MLL_VOTES = gql`
	query getMLLVotes {
		# ) # 	$academic_year: String # 	$offset: Int #   $limit: Int # (
		getMLLVotes {
			# ) #   academic_year: $academic_year #   offset: $offset #   limit: $limit # (
			id
			academic_year
			faculty_name
			votes
		}
	}
`;

export default GET_MLL_STATUS;
