import { Box, Stack, styled, Typography } from "@mui/material";
import LayoutContainer from "../../../components/container/Container";
import { theme } from "../../../themes/default";
import { LinearProgressWithLabel } from "../../../components/progress/ProgressBar";
import { formatAcademicYearRange } from "../../../components/utilities/Formatters";
import AcademicYearClosed from "../../../components/authorization/AcademicYearClosed";

const BoxStyled = styled(Box)({
  color: theme.palette.primary.contrastText
})

const ProgressBoxStyled = styled(Box)({
  paddingTop: 16,
  maxWidth: 460,
  'h3': {
    marginBottom: 10
  }
})

const ParticipationProgress = (props: any) => {
  const {participationStats, academicYearSettings } = props;
  const total_events_approved = participationStats.participation_total_events_approved ?? 0
  const total_hours_approved = participationStats.participation_total_hours_approved ?? 0
  const event_goal: number = academicYearSettings.event_participation_goal ?? 0
  const service_goal: number = academicYearSettings.service_hours_goal ?? 0
  const academic_year = `${formatAcademicYearRange(academicYearSettings.academic_year)}`

	return (
    <LayoutContainer background="dark">
      <BoxStyled>
        <Stack spacing={3}>
          <Typography variant="h2">{academic_year} Program Participation Progress </Typography>
          <ProgressBoxStyled>
            <Typography variant="h3">Event Participation Completed:</Typography>
            <LinearProgressWithLabel value={total_events_approved} goal={event_goal} />
          </ProgressBoxStyled>
          <ProgressBoxStyled>
            <Typography variant="h3">Service Hours Completed:</Typography>
            <LinearProgressWithLabel color="secondary" value={total_hours_approved} goal={service_goal} />
          </ProgressBoxStyled>
        </Stack>
      </BoxStyled>
    </LayoutContainer>
	);
};

export default ParticipationProgress;
