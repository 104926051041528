import { Link, Typography } from "@mui/material";
import LayoutContainer from '../container/Container';

const Footer = () => {
	return (
    <>
      <LayoutContainer>
        <Typography align="center">
          © 2022 Alumni Scholars Club | <Link href="">Terms and Conditions</Link>
        </Typography>
        <Typography align="center">
          James West Alumni Center, Los Angeles, CA 90024
        </Typography>
        <Typography align="center">
          Comments, Questions, or Concerns? Contact Our Webmaster <Link href="">Here!</Link>
        </Typography>
      </LayoutContainer>
    </>
	);
};

export default Footer;
