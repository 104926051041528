import React, { useContext, useState, useEffect } from 'react'
// import { SettingsContext } from '../../../components/authorization/GetAccessRights';
// import { withPageState } from '../../../components/authorization/PageState'

import { useQuery, useMutation } from '@apollo/client';

// import { getLoggedInUser } from "../../../components/authorization/Utilities"

import toast from 'react-hot-toast';

import LinkButton from "../../../components/button/Button"

import { Box, Button, Stack, Typography, TextField } from "@mui/material";

import Dropdown, { DropDownTypes } from "../../../components/autocomplete/Autocomplete"

import { handleFormDataChange } from '../../../components/utilities/Events'

import { ALL_LISTS_CATEGORIES } from '../../../Graphql/ListsCategories/QueryListsCategories'
import { CREATE_USER } from '../../../Graphql/Users/MutateUser'

const NewUser = (props: any) => {
  // const adminPage = "admin_users"
  // const settings: any = useContext(SettingsContext)
  // const loggedInUserId = getLoggedInUser(settings).id || null
  // const academicYearSettings = settings.academicYearSettings

  var initFormData = {
    crm_id: "", 
    ucla_id: "", 
    first_name: "", 
    last_name: "", 
    email: "", 
    scholarship_type: "",
  }

  const { error: errorListsCategories, data: dataListsCategories } = useQuery(ALL_LISTS_CATEGORIES)
  const [ createUser, { error: errorCreateUser, data: dataCreateUser } ] = useMutation(CREATE_USER)
  const [ scholarshipTypes, setScholarshipTypes ] = useState(Array<any>)
  const [ formData, setFormData ] = useState(initFormData)

  const handleAddUserClick = () => { 
    console.debug('CustomButton props')
    console.log('formData')
    console.log(formData)

    createUser({
      variables: {
        crm_id: formData.crm_id,
        ucla_id: formData.ucla_id,
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        scholarship_type: formData.scholarship_type
      }})
  }

  useEffect(() => {
    // Fill In Scholarship Type Drop Down
    if (errorListsCategories) {
      console.log(errorListsCategories)

    } else if (dataListsCategories) {
      console.log(dataListsCategories)
      const scholarship_types = dataListsCategories["getScholarshipTypes"] ? dataListsCategories.getScholarshipTypes.scholarship_types : [];

      // console.log(scholarship_types)
      if (scholarship_types.length > 0) {
        setScholarshipTypes(scholarship_types)
      }  
    }

  }, [errorListsCategories, dataListsCategories, setScholarshipTypes])

  useEffect(() => {

    let toastMsg: string = ''
    if (errorCreateUser) {
      console.debug('errorCreateUser')
      console.debug(errorCreateUser)
      toastMsg = "Error Attempting to Create User";
      toast.error(toastMsg)
        
    } else if (dataCreateUser) {
        console.log('data: ')
        console.log(dataCreateUser)
        if (dataCreateUser.createUser.successful) {
            window.location.href = `/admin/user/${dataCreateUser.createUser.inserted_id}`
            // toastMsg = "User Created Successfully!";
            // toast.success(toastMsg);

        } else {
            toastMsg = dataCreateUser.createUser.message
            toast.error(toastMsg)

        }
    } 
    if (toastMsg) {
        console.log(`Toast Message: ${toastMsg}`)
    }
}, [errorCreateUser, dataCreateUser])

	return (
		<>
			<Box mb={3}>
        <LinkButton to="/admin/users" variant="outlined" type="back">
          Return to User Management
        </LinkButton>
      </Box>
			<Typography variant="h1">Manually Add New User</Typography>
			<Typography variant="body1">Please fill out the form below to manually add a user. Once submitted, the user will automatically be emailed an activation code.</Typography>
			<Stack spacing={3} sx={{ maxWidth: 300, mt: 3 }}>
				<TextField
          required
          id="first-name"
          label="First Name"
          defaultValue=""
          onChange={(event) => { handleFormDataChange(setFormData, formData, "first_name", event.target.value) }}
        />
				<TextField
          required
          id="last-name"
          label="Last Name"
          defaultValue=""
          onChange={(event) => { handleFormDataChange(setFormData, formData, "last_name", event.target.value) }}
        />
				<TextField
          required
          id="ucla-id"
          label="UCLA ID"
          defaultValue=""
          // Add Validation Length = 9
          onChange={(event) => { handleFormDataChange(setFormData, formData, "ucla_id", event.target.value) }}
        />
				<TextField
          required
          id="crm-id"
          label="CRM ID"
          defaultValue=""
          // Add Validation Length = 10
          onChange={(event) => { handleFormDataChange(setFormData, formData, "crm_id", event.target.value) }}
        />
				<TextField
          required
          id="email"
          label="Login Email"
          defaultValue=""
					// type="email"
          onChange={(event) => { handleFormDataChange(setFormData, formData, "email", event.target.value) }}
        />
        <Dropdown type={DropDownTypes.Scholarship} options={scholarshipTypes} initValue="" setFormData={setFormData} formData={formData} />

			</Stack>
			<Stack spacing={3} sx={{ maxWidth: 150, mt: 3 }}>
				<Button 
        variant="contained"
        onClick={() => { handleAddUserClick() }}>Add User</Button>
        <LinkButton to="/admin/users" variant="outlined">
          Cancel
        </LinkButton>
			</Stack>
		</>
	);
};

// export default withPageState(NewUser, { pageSection: "admin", adminAccess: "admin_users" })
export default NewUser