import { Box } from "@mui/material";
import { Container } from "@mui/material";
import { theme } from "../../themes/default";

const LayoutContainer = ({children, background}: any) => {
	if (background === 'light') {
    background = theme.palette.info.light
  } else if (background === 'dark') {
    background = theme.palette.primary.dark
  } else {
    background = ''
  }
  return (
		<Box sx={{ py: 6, backgroundColor: {background} }}>
      <Container>
        {children}
      </Container>
    </Box>
	);
};

export default LayoutContainer;
