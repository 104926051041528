import { gql } from "@apollo/client";

export const BULK_IMPORT_FACULTY = gql`
	mutation importFaculty($facultyList: [BulkImportFaculty]!) {
		importFaculty(facultyList: $facultyList) {
			successful
			message
			error_fields
			inserted_id
		}
	}
`;

export const DELETE_FACULTY = gql`
	mutation deleteFaculty($facultyList: [FacultyInputs]!) {
		deleteFaculty(facultyList: $facultyList) {
			successful
			message
			error_fields
		}
	}
`;
