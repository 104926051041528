import React, { useEffect, useState } from "react";

import { DataGrid, GridColDef, GridToolbar, GridToolbarExport } from '@mui/x-data-grid';

import { Link } from 'react-router-dom'
import { styled } from '@mui/material';

import { formatGQLDate } from '../../../../components/utilities/Formatters';
import { hasData } from '../../../../components/utilities/Validation'

const LinkStyled = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  '&:hover': {
    color: theme.palette.primary.dark,
  },
}));

const columns: GridColDef[] = [
  { 
    field: 'actions', 
    headerName: 'Actions', 
    width: 130,
    renderCell: (params: any) =>
    <LinkStyled to={`review/${params.row.id}`}>
      {`${params.row.submission_status === 'pending' ? 'Review' : 'Edit'}`}
    </LinkStyled>
  },
  { field: 'submission_type', headerName: 'Type', width: 130 },
  { field: 'submission_status', headerName: 'Status', width: 130 },
  { field: 'submitted_name', headerName: 'Submitted Name', width: 230 },
  { field: 'file_display_name', headerName: 'File Display Name', width: 230 },
  { field: 'ucla_classes_class_name', headerName: 'Assigned Class', width: 230 },
  { field: 'user_last_name', headerName: 'Student Name', width: 230, renderCell: (params: any) => `${params.row.user_last_name}, ${params.row.user_first_name}`},
  { field: 'created_on', headerName: 'Submitted', width: 120, type: 'date', valueGetter: (params: any) => params.row.created_on ? formatGQLDate({gQLDate: params.row.created_on, format: "MM/DD/YYYY"}) : "-" },
]

export default function BankManagementTable(props: any) {
  const { data } = props

  const [ tableData, setTableData ] = useState(data);
  const [ pageSize, setPageSize ] = useState<number>(25);

  useEffect(() => {
    if (hasData(data)) {
      setTableData(data) 
    }
  }, [data])

  return (
      <DataGrid
        autoHeight={true}
        rows={tableData}
        columns={columns} 
        pageSize={pageSize}
        onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
        rowsPerPageOptions={[2, 5, 10, 25, 100]}                
        density="compact"
        components={{
            Toolbar: GridToolbar,
        }}
        componentsProps={{
          toolbar: {
            csvOptions:{
              fileName: `ASC-Notes Test Bank Management-${formatGQLDate({gQLDate: new Date().getTime(), format: "YYYYMMDD"})}`,
            }
          },
        }}
        initialState={{
          sorting: {
              sortModel: [{ field: 'submission_status', sort: 'desc' }],
          },
          columns: {
            columnVisibilityModel: {
              // submission_status: false,
            }
          }
        }}
      />
  )
}