import React, { useContext, useState, useEffect } from 'react'
// import { SettingsContext } from '../../../components/authorization/GetAccessRights';
// import { withPageState } from '../../../components/authorization/PageState'

import { useQuery } from '@apollo/client';

// import { getLoggedInUser } from "../../../components/authorization/Utilities"

import toast from 'react-hot-toast';

import { Box, Divider, Grid, Paper, Stack, styled, Typography } from "@mui/material";
import LoadingSpinner from "../../../components/progress/Spinner";

import BankManagementTable from "./partials/BankManagementTable";

import { GET_ADMIN_COURSE_DOCUMENTS } from '../../../Graphql/Documents/QueryDocuments';

const BankManagement = () => {
  // const adminPage = "admin_bank"
  // const settings: any = useContext(SettingsContext)
  // const loggedInUserId = getLoggedInUser(settings).id || null
  // const academicYearSettings = settings.academicYearSettings
  // const allowAccess = settings.accessRights[adminPage]

  const initStats = {
    approvedCount: 0,
    classesWithApprovedDocsCount: 0,
    pendingCount: 0,
    uniqueUsersCount: 0
  }

  const initDocumentsData = [{
    id: 0,
    submitted_name: "",
    submission_type: "",
    submission_status: "",
  }]

  const { loading, error, data } = useQuery(GET_ADMIN_COURSE_DOCUMENTS)
  const [stats, setStats] = useState<any>(initStats)
  const [documentsData, setDocumentsData] = useState<any>(initDocumentsData)

  useEffect(() => {
    
    let toastMsg: string = ''

    if (error) {
      toastMsg = 'Error: ' + error;
      toast.error(toastMsg)
    }

    if (data) {
      console.debug(data)
      setStats(data.getCourseDocumentsStats[0])
      setDocumentsData(data.getCourseDocuments)
    }
  }, [loading, error, data])

	return (
		<>
      { loading && 
        <LoadingSpinner/>
      }
      <Stack spacing={3}>
        <Typography variant="h2">Notes/Test Bank Management</Typography>
        <Divider />
      </Stack>
      <Grid container spacing={3} mt={1} sx={{ maxWidth: 800 }}>
        <SummaryCard title="Documents Pending:" count={stats.pendingCount} />
        <SummaryCard title="Documents Available:" count={stats.approvedCount} />
        <SummaryCard title="Classes (with at least 1 document):" count={stats.classesWithApprovedDocsCount} />
        <SummaryCard title="Notes/Test Bank Users:" count={stats.uniqueUsersCount} />
      </Grid>
      <Box mt={5}>
        <BankManagementTable data={documentsData}/>
      </Box>
      
    </>
	);
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.primary.contrastText,
  padding: theme.spacing(3),
  'h3': {
    color: theme.palette.primary.main,
  },
  'p': {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  }
}));

function SummaryCard ({ title, count }: any) {
  return (
    <Grid item xs={12} md={6}>
        <Item elevation={3}>
          <Typography paragraph>{title}</Typography>
          <Typography variant="h3">{count}</Typography>
        </Item>
    </Grid>
  )
}

// export default withPageState(BankManagement, { pageSection: "admin", adminAccess: "admin_bank" })
export default BankManagement