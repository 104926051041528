import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQuery, useLazyQuery } from '@apollo/client'

import { POC_USER_GET_PROFILE } from '../../Graphql/Users/QueryUser'

const loginEnv = (process.env.REACT_APP_AWS_ENVIRONMENT === "local") ? "dev" : process.env.REACT_APP_AWS_ENVIRONMENT;

// redirects user to UCLA site to sign on, with return url in query parameter (redirect_url)
export const doLogin = (page:any, returnPage:any) => {
  window.location.href = 'https://' + (loginEnv === 'prod' ? '' : loginEnv + '.') + 'account.alumnischolarsclub.alumni.ucla.edu/' + page + '?redirect_url=' + encodeURIComponent(returnPage);
};

// you'll probably also want to create some kind of timer that auto-logs the user out 1 hour after logging in
// the token is only good for an hour, and we don't have any refresh token capability on this site (very complicated)
// for now I think we should just
export const doLogout = (logoutLandingPage:any) => {
  deleteCookie('.UCLAEAIDMID', '/', 'ucla.edu');
  localStorage.removeItem('loggedInUser')
  localStorage.removeItem('sessionEnds')
  localStorage.removeItem('uclaeaidmtoken')
  window.location.href = logoutLandingPage;
};

export const  deleteCookie = (name:any, path:any, domain:any) => {
  if ( getCookie(name) ) {
    document.cookie = name + "=" +
    ((path) ? ";path="+path:"")+
    ((domain)?";domain="+domain:"") +
    ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}

export const  getCookie = (name:any) => {
  const value = "; " + document.cookie;
  const parts = value.split("; " + name + "=") || [];
  if (parts.length === 2) return (parts.pop() || '').split(";").shift();
}