import React, { useContext, useState, useEffect } from 'react'
import { SettingsContext } from '../../../components/authorization/GetAccessRights';
// import { withPageState } from '../../../components/authorization/PageState'

import { getLoggedInUser } from "../../../components/authorization/Utilities"

import { Box, Divider, Stack, Typography } from "@mui/material"

import LinkButton from "../../../components/button/Button"
import { ListOfUsers } from "./partials/ListOfUsers"

const Users = (props: any) => {
  // const adminPage = "admin_users"
  // const settings: any = useContext(SettingsContext)
  // const loggedInUserId = getLoggedInUser(settings)
  // const academicYearSettings = settings.academicYearSettings

  const [ activeStudentsCount, setActiveStudentsCount ] = useState(0)
  const [ inActiveStudentsCount, setInActiveStudentsCount ] = useState(0)

  const updateActiveStudentsCount = (active: number, inactive: number) => {
    setActiveStudentsCount(active)
    setInActiveStudentsCount(inactive)
  }

	return (
		<>
      <Typography variant="h2">User Management Center</Typography>
      <Typography>View and modify user account and demographic information</Typography>
			<Divider sx={{ my: 3  }} />
      <Typography variant="h2">Master Student Roster [{activeStudentsCount} Active, {inActiveStudentsCount} InActive]</Typography>
      <Box mt={3}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <LinkButton to="/admin/user/new" variant="outlined">
            Add New User
          </LinkButton>
          <LinkButton to="/admin/users/new/bulk" variant="contained">
            Bulk Add
          </LinkButton>
        </Stack>
      </Box>
      <ListOfUsers updateActiveUserCount={updateActiveStudentsCount}></ListOfUsers>
		</>
	)
}

// export default withPageState(Users, { pageSection: "admin", adminAccess: "admin_users" })
export default Users