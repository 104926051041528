import React, { useEffect, useState } from "react";
import { useLazyQuery } from '@apollo/client'

import { POC_USER_GET_PROFILE } from '../../Graphql/Users/QueryUser'

import { localStorageLoggedInUser, doLogout, doLogin, getCookie } from './Utilities';

const sessionTimeout = 24*60*60*1000; // access and id tokens have been set to 1 day in Cognito

export const GoToLogin = ({returnRoute = "/participation"}) => {

  const lsLoggedInUser = localStorageLoggedInUser();

  console.debug(getReturnPage(returnRoute))
  console.debug('GoToLogin lsLoggedInUser: ', lsLoggedInUser)

  const goToUrl = !lsLoggedInUser ? doLogin('signin', getReturnPage(returnRoute)) : returnRoute

  window.location.href = goToUrl

  return (
    <>
      {/* <p>Go To Login Page: <a href={goToUrl}>{goToUrl}</a></p> */}
    </>
  )
}; 
export const GoToLogout = ({returnRoute = "/"}) => {
  // console.debug(getReturnPage(returnRoute))
  // console.debug('lsLoggedInUser: ', lsLoggedInUser)
  const goToUrl = doLogout(getReturnPage(returnRoute))

  window.location.href = goToUrl
  
  return <></>
};

export const useLoginStatus = () => {
  const [getUserProfile, { loading, error, data }] = useLazyQuery(POC_USER_GET_PROFILE);
  const [loggedInUser, setLoggedInUser] = useState(localStorage.loggedInUser ? JSON.parse(localStorage.loggedInUser) : null);
  const [loginStatus, setloginStatus] = useState(localStorage.loggedInUser ? "success" : "waiting");

	// scrapes JWT token from cookie for user returning from logging in on the CIDM Portal
	// (the token is also appended to the URL in dev mode, to make local development easier)
	// you'll probably want to show some kind of loading screen while the app retrieves the newly logged-in user's profile
	// this can be local to a special page that the user lands on after logging in
	// if we need login intervening (user clicks page, logs in, continues on that page) we can use local storage and a redirect page param
	useEffect(() => {
		if (!loggedInUser) {
      console.debug('ASC - Get UCLA Cookie ')
	    let cognitoIdToken:any = getCookie('.UCLAEAIDMID');

	    // check url param for local dev mode only
	    if (!cognitoIdToken && process.env.REACT_APP_AWS_ENVIRONMENT === "local" && window.location.href.indexOf('uclaeaidmtoken') > -1) {
	      const urlParams = new URLSearchParams(window.location.search);
	      cognitoIdToken = urlParams.get('uclaeaidmtoken');
	    }

	    if (cognitoIdToken) {
        console.debug('ASC - SET Local Storage uclaeaidmtoken: ', cognitoIdToken)
			 	localStorage.setItem('uclaeaidmtoken', cognitoIdToken)
	      getUserProfile(); // call to get the logged in user's user profile
	    }
	    else {
        console.debug('ASC - Failed to get cognitoIdToken')

        localStorage.removeItem("uclaeaidmtoken");
        localStorage.removeItem("loggedOut");
        localStorage.removeItem("loginSessionTimedOut");
        localStorage.removeItem("loginFailed");
	 			setloginStatus("failed")
	    }
	  }
	}, [loggedInUser, getUserProfile])

	// is activated when the POC_USER_GET_PROFILE query populates the user data (post user login)
	useEffect(() => {
    if (loading) {
      console.debug('ASC - useLoginStatus() data loading...')
    }
    if (error) {
      console.debug('ASC - useLoginStatus() data error: ', error)
    }

	 	if (!loggedInUser && data && data.getLogInProfile.id) {

		 	localStorage.setItem('loggedInUser', JSON.stringify(data.getLogInProfile))
	 		setLoggedInUser(data.getLogInProfile)
      
      // console.debug("ASC - useLoginStatus() 1 setLoginStatus = success", data.getLogInProfile.id)
			// auto-logs the user out 1 hour after logging in (if they haven't already)
			// Cognito tokens are only good for an hour, and we can't implement refresh token functionality on this site
			// we can increase the token timeout, but we still need to flush stale tokens/logins out of localStorage somehow
      localStorage.removeItem("loggedOut");
      localStorage.removeItem("loginSessionTimedOut");
      localStorage.removeItem("loginFailed");
      localStorage.setItem('sessionEnds', ((new Date()).getTime() + 1*sessionTimeout).toString());
	 		setloginStatus("success")
		 	// strip off local dev url param if it exists
		 	// if (process.env.REACT_APP_AWS_ENVIRONMENT === "local" && window.location.href.indexOf('uclaeaidmtoken') > -1) {
		  //   window.location.href = getReturnPage();
		  // }
		}
	 	// user isn't found in system
	 	else if (data && !data.getLogInProfile.id) {

      // console.debug("ASC - useLoginStatus() 2 - FAILED", data)
		 	// localStorage.removeItem('uclaeaidmtoken')// Leave this in, to distinguish between crm user that is not in asc and 
	 		// I'm sure there's a better way to do internal routing but I couldn't find any examples
      localStorage.removeItem("loggedOut");
      localStorage.removeItem("loginSessionTimedOut");
      localStorage.setItem('loginFailed', "1") //track what's caused user to logout in order to redirect to the correct page
	 		
      setloginStatus("failed")
	 		// document.location.href = getReturnPage('/');
		}
	}, [loading, error, data])

	// this auto logout probably needs to go somewhere global and may need to be tweaked, just an example for now
	useEffect(() => {
	  const sessionEnds:any = localStorage.getItem('sessionEnds');
	  if (localStorage.loggedInUser && sessionEnds) {
	  	const now = (new Date()).getTime();
	  	if (1*sessionEnds < now) {
      	doLogout(getReturnPage('/'));
        localStorage.removeItem("loggedOut");
        localStorage.removeItem("loginFailed");

        localStorage.setItem('loginSessionTimedOut', "1") //track what's caused user to logout in order to redirect to the correct page
        window.location.href = "/"
    	}
	  	else {
      	setTimeout(() => doLogout(getReturnPage('/')), sessionEnds - now);
    	}
    }
	}, [])

  // console.debug("ASC - useLoginStatus() 3", loginStatus, data)
  return { loginStatus: loginStatus, userProfile:  loggedInUser}
}

export function getReturnPage(returnRoute="/participation") {
  return window.location.protocol+'//'+window.location.hostname+(window.location.port ? ':'+window.location.port: '') + returnRoute;
}
export default GoToLogin;