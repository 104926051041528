// CHECK OUT: https://github.com/UgnisSoftware/react-spreadsheet-import - swap dropzone out for this? Currently not supporting react 18
import React, { useState, useEffect } from 'react'
// import { SettingsContext } from '../../../components/authorization/GetAccessRights';

import { useLazyQuery, useMutation } from '@apollo/client';

import toast from 'react-hot-toast';

import { BULK_IMPORT_FACULTY, DELETE_FACULTY } from '../../../Graphql/UclaFaculty/MutateUclaFaculty'
import { GET_UCLA_FACULTY } from '../../../Graphql/UclaFaculty/QueryUclaFaculty'

import { useDropzone } from 'react-dropzone'
import * as XLSX from 'xlsx'

import { DataGrid, GridRowParams, GridToolbar } from '@mui/x-data-grid';
import DataGridStyle from '../../../components/container/DataGridStyle';

import styled from 'styled-components';

import LinkButton from "../../../components/button/Button"
import { chunkArray } from "../../../components/utilities/Formatters"

import { Button, Stack } from "@mui/material"
import LoadingSpinner from '../../../components/progress/Spinner';

const getColor = (props: any) => {
  if (props.isDragAccept) {
      return '#00e676';
  }
  if (props.isDragReject) {
      return '#ff1744';
  }
  if (props.isFocused) {
      return '#2196f3';
  }
  return '#eeeeee';
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;

const NewFacultyBulk = (props: any) => {
  // const adminPage = "admin_users"
  // const settings: any = useContext(SettingsContext)
  // const loggedInUserId = settings.loggedInUserId
  // const academicYearSettings = settings.academicYearSettings
  // const allowAccess = settings.accessRights[adminPage]

  const [ importFaculty, { loading: loadingImportFaculty, error: errorImportFaculty, data: dataImportFaculty } ] = useMutation(BULK_IMPORT_FACULTY)
  const [ deleteFaculty, { loading: loadingDeleteFaculty, error: errorDeleteFaculty, data: dataDeleteFaculty } ] = useMutation(DELETE_FACULTY)
  const [ getExistingFaculty, { loading: loadingExistingFaculty, error: errorExistingFaculty, data: dataExistingFaculty }] = useLazyQuery(GET_UCLA_FACULTY);

  const [ processing, setProcessing ] = useState(Boolean) //Used to show Loading Spinner, theoretically shouldn't need it for mutation and query loading, referencing in JSX to conditioinally show spinner not working as expected here.
  const [ mappedData, setMappedData ] = useState(Array<any>)

  const [fileName, setFileName] = useState('')
  const [ rowsOrigFile, setRowsOrigFile ] = useState(Array<any>)

  const [ recordsToDelete, setRecordsToDelete ] = useState(Array<any>)
  const [ columnsToDelete, setColumnsToDelete ] = useState(Array<any>)
  const [ pageSizeDelete, setPageSizeDelete ] = useState<number>(5);

  const [ pageSizeAfterValidation, setPageSizeAfterValidation ] = useState<number>(25);
  const [ columnsAfterValidation, setColumnsAfterValidation ] = useState(Array<any>)
  const [ rowsAfterValidation, setRowsAfterValidation ] = useState(Array<any>)

  const [ columnsFinal, setColumnsFinal ] = useState(Array<any>)
  const [ rowsFinalImport, setRowsFinalImport ] = useState(Array<any>)
  const [ pageSizeFinal, setPageSizeFinal ] = useState<number>(25);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    maxFiles: 1,
    onDropAccepted,
    multiple: false,
    accept: {'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [], 'application/vnd.ms-excel': []}});

  async function onDropAccepted (acceptedFiles: any) {
    //init DGs
    setRowsAfterValidation([])
    setRowsFinalImport([])

    setProcessing(true)
    console.debug('File Accepted: ', acceptedFiles)

    const file = acceptedFiles[0]
    setFileName(file.name)
    let reader = new FileReader();

    reader.onload = function(e: any) {
      
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, {type: 'array'});
      const worksheet = workbook.Sheets[workbook.SheetNames[0]]; //Get first sheet

      // convert to json format
      const jsonData: Array<any> = XLSX.utils.sheet_to_json(worksheet);

      setRowsOrigFile(jsonData)
      
      console.debug("jsonData AFTER clean and validate")
      console.debug(jsonData)
      setColumnsAfterValidation(createColumnsFromJson(jsonData))
      setRowsAfterValidation(jsonData)

      setMappedData(mapExcelToFacultyModel(jsonData))
      getExistingFaculty()
      
    };
    reader.readAsArrayBuffer(file);
    setProcessing(false)
  }
  
  function createColumnsFromJson(data: Array<any>) {
    let columns: Array<any> = []
    let keys = Object.keys(data[0])
    keys.forEach((columnName) => {
      let width: number = 150
      if (
          columnName === 'first_name' || 
          columnName === 'last_name'
        ) {
        width = 200
      }
      columns.push({ field: columnName, headerName: columnName, width:width })
    })
    //Full name does not exist in the incoming spreadsheet. Create it based on First and Last Name
    // columns.push({ field: "full_name", headerName: "full_name", width: 350 })
    return columns
  }

  function mapExcelToFacultyModel(data: any) {
    // Return object mapped to args required for the Create User mutation
    var mappedRecords: Array<any> = []
    const mapping = {
      "faculty_uid": "ucla_faculty_id",
      "first_name": "first_name", 
      "last_name": "last_name", 
      "full_name": "full_name", 
    }
    
    data.forEach((faculty_member: any) => {
      var tempObj: any = {}
      for (const [key, value] of Object.entries(mapping)) {
        if (value && key !== "full_name") {
          tempObj[value] = typeof faculty_member[key] === 'string' ? faculty_member[key] : faculty_member[key].toString() 
        }
        if (key === "full_name") {
          tempObj[value] = `${faculty_member["first_name"]} ${faculty_member["last_name"]}`
        }
      }
      mappedRecords.push(tempObj)

      // console.debug('tempObj for Student: ' + student["stu id"])
      // console.debug(tempObj)
    })

    console.debug('bulk Faculty mappedRecords: ', mappedRecords)
    return mappedRecords
  }

  function handleSubmit() { 
    console.debug('Submit Bulk Records')

    deleteFaculty({
      variables: { facultyList: recordsToDelete }
    })

    const importChunks:Array<any> = chunkArray(rowsFinalImport, 15000)
    let msg = `Number of Records to Import: ${rowsFinalImport.length}, Chunks based on 750 records at a time: ${importChunks.length}`
    console.debug(msg)
    toast.success(msg)
    
    let count = 0
    for (const chunk of importChunks) {
      count++
      msg = `Importing chunk ${count}`
      console.debug(msg, chunk)

      toast.success(msg)
      
      
      
      importFaculty({
        variables: { facultyList: chunk }
      })
    }
  }

  useEffect(() => {
    //Clean List to Import. Remove any that already exist in the ASC DB based on the UCLA_ID
    if (loadingDeleteFaculty) {
      console.debug("loadingDeleteFaculty...")
    }

    if (errorDeleteFaculty) {
      console.error('errorDeleteFaculty:', errorDeleteFaculty)
      toast.error(errorDeleteFaculty.message)
    }
    if (dataDeleteFaculty) {
      console.debug('dataDeleteFaculty: ', dataDeleteFaculty)

    } 
  }, [ loadingDeleteFaculty, errorDeleteFaculty, dataDeleteFaculty ])

  useEffect(() => {
    //Clean List to Import. Remove any that already exist in the ASC DB based on the UCLA_ID
    if (loadingExistingFaculty) {
      console.debug("loadingExistingFaculty...")
    }
    if (errorExistingFaculty) {
      console.error('errorExistingFaculty:', errorExistingFaculty)
      toast.error(errorExistingFaculty.message)
    }
    if (dataExistingFaculty) {
      console.debug('dataExistingFaculty:', dataExistingFaculty.getFacultyList)

      const recordsToRemove = facultyToRemove(mappedData, dataExistingFaculty.getFacultyList)
      const recordsToImport: Array<any> = removeArrayObjDuplicates( dataExistingFaculty.getFacultyList, mappedData, "ucla_faculty_id" )
      // console.debug('recordsToImport: ', recordsToImport)
      
      if (recordsToRemove.length > 0) {
        setColumnsToDelete(createColumnsFromJson(recordsToRemove))
        setRecordsToDelete(recordsToRemove)

      } else {
        console.debug('No Records To Delete')
      }

      if (recordsToImport.length > 0) {
        setColumnsFinal(createColumnsFromJson(recordsToImport))
        setRowsFinalImport(recordsToImport)

      } else {
        console.debug('No Records To Import')
      }
    } 

    function facultyToRemove(importData: any, existingData: any) {
      const importSet = new Set(importData.map(({ first_name, last_name  }: any) => [first_name, last_name].toString()));
      const removeFaculty = existingData.filter(({ first_name, last_name }: any) => !importSet.has([first_name, last_name].toString()))
                                        .map(({ id, ucla_faculty_id, first_name, last_name }: any) => ({ id, ucla_faculty_id, first_name, last_name }));;

      console.debug("facultyToRemove: ", importData, existingData, removeFaculty)
      return removeFaculty
    }

    function removeArrayObjDuplicates(arr1: Array<any>, arr2: Array<any>, key: string) {
      //Return objects from arr2 that are not in arr1
      console.debug(arr1, arr2, key)
      let uniqueArr:Array<any> = arr2.filter(obj => !arr1.some(o => o[key] === obj[key]));
      return uniqueArr;
    }

  }, [loadingExistingFaculty, errorExistingFaculty, dataExistingFaculty, mappedData])

  useEffect(() => {
    // let dummyFormData = formData //Needed for AutoComplete onChange event or else will be one behind
    let toastMsg: string = ''

    if (loadingImportFaculty) {
      console.debug("loadingImportFaculty...")
    }
    if (errorImportFaculty) {
      console.error('errorImportFaculty: ', errorImportFaculty)
      let errMsg = ""

      if (errorImportFaculty.message.includes("code 413")) {
        errMsg = " - Payload Too Large"
      }

      toastMsg = "Error Importing List" + errMsg;
      toast.error(toastMsg)
        
    } else if (dataImportFaculty) {
        console.debug('dataImportFaculty: ', dataImportFaculty)
        setProcessing(true)
        if (dataImportFaculty.importFaculty.successful) {
          window.location.href = `/admin/mll`

        } else {
          toastMsg = dataImportFaculty.importFaculty.message
          toast.error(toastMsg)
          setProcessing(false)
        }
    } 
    if (toastMsg) {
        console.debug(`Toast Message: ${toastMsg}`)
        setProcessing(false)
    }
  }, [ loadingImportFaculty, errorImportFaculty, dataImportFaculty])


	return (
		<>
      { (processing === true || loadingDeleteFaculty || loadingExistingFaculty || loadingImportFaculty) && 
        <LoadingSpinner />
      }
      <LinkButton to="/admin/mll" variant="outlined" type="back">
        Return to MLL Management
      </LinkButton>
      <Stack spacing={3} sx={{ maxWidth: 250, mt: 3 }}></Stack>
      <Container {...getRootProps({isFocused, isDragAccept, isDragReject})}>
        <input {...getInputProps()} />
        <p>Drag and drop file here, or click to select file</p>
        <em>(Only *.xlsx and *.xls files will be accepted)</em>
        <p>{fileName ? 'Filename Selected: ' + fileName : ''}</p>
      </Container>
      <Stack spacing={3} sx={{ maxWidth: 250, mt: 3 }}></Stack>

      {rowsOrigFile.length > 0 &&
        <DataGridStyle>
          <p># of Faculty Members In "{fileName}": <strong>{rowsOrigFile.length}</strong></p>
          <DataGrid
            // checkboxSelection={checksboxSelection}
            autoHeight={true}
            getRowId={(row) => row["faculty_uid"]}
            rows={rowsAfterValidation}
            columns={columnsAfterValidation} 
            pageSize={pageSizeAfterValidation}
            onPageSizeChange={(newPageSize: number) => setPageSizeAfterValidation(newPageSize)}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}                
            density="compact"
            components={{
                Toolbar: GridToolbar,
            }}
            initialState={{
                sorting: {
                    sortModel: [{ field: 'first_name', sort: 'asc' }],
                },
            }}
            getRowClassName={(params: GridRowParams<any>) => {
              // console.log(params)
              if (params.row["bulk_upload_is_valid_record"] === false) {
                return 'data-cell-red';
              }
              return ''
            }}
            // onRowClick={handleOnCellClick}
          />
        </DataGridStyle>
      }
      {recordsToDelete.length > 0 &&
        <>
          <p># of Records to REMOVE from DB: <strong>{recordsToDelete.length}</strong></p>
          <DataGrid 
              // checkboxSelection={checkboxSelection}
              autoHeight={true}
              getRowId={(row) => row["id"]}
              rows={recordsToDelete}
              columns={columnsToDelete} 
              pageSize={pageSizeDelete}
              onPageSizeChange={(newPageSize: number) => setPageSizeFinal(newPageSize)}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}                
              density="compact"
              components={{
                  Toolbar: GridToolbar,
              }}
              initialState={{
                  sorting: {
                      sortModel: [{ field: 'first_name', sort: 'asc' }],
                  },
              }}
              
          />
        </>
      }
      {rowsFinalImport.length === 0 && fileName &&
        <>
        <p><strong>{rowsFinalImport.length} Records to IMPORT. Looks like all the records exist in the ASC DB.</strong></p>
        </>
      }
      {rowsFinalImport.length > 0 &&
        <>
          <p># of Records to IMPORT: <strong>{rowsFinalImport.length}</strong> out of {rowsAfterValidation.length}</p>
          <DataGrid 
            // checkboxSelection={checkboxSelection}
            autoHeight={true}
            getRowId={(row) => row["ucla_faculty_id"]}
            rows={rowsFinalImport}
            columns={columnsFinal} 
            pageSize={pageSizeFinal}
            onPageSizeChange={(newPageSize: number) => setPageSizeFinal(newPageSize)}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}                
            density="compact"
            components={{
                Toolbar: GridToolbar,
            }}
            initialState={{
                sorting: {
                    sortModel: [{ field: 'first_name', sort: 'asc' }],
                },
            }}
              
          />
        </>
      }
      {rowsFinalImport.length === 0 && fileName &&
        <LinkButton to="/admin/mll" variant="outlined" type="back">
          Return to MLL Management
        </LinkButton>
      }
      {fileName && (rowsFinalImport.length > 0 || recordsToDelete.length > 0) && 
        <Stack spacing={3} sx={{ maxWidth: 250, mt: 3 }}>
          <Button 
          variant="contained"
          onClick={() => { 
            handleSubmit()
          }}>Submit</Button>
        </Stack>
      }
		</>
	)
}

// export default withPageState(NewUserBulk, { pageSection: "admin", adminAccess: "admin_users" })
export default NewFacultyBulk
