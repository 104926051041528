import React, { useContext, useState, useEffect } from 'react'
import { useSearchParams } from "react-router-dom"

import { useQuery, useMutation, useLazyQuery } from '@apollo/client';

import toast from 'react-hot-toast';

import { apolloClientOpen } from '../../components/authorization/GetAccessRights'

import { NoAccessPage } from '../../pages/error/NoAccess'

import { Autocomplete, Box, Button, Divider, Grid, Paper, Stack, styled, TextField, Typography } from "@mui/material";
import LayoutContainer from '../../components/container/Container';
import ExternalNav from '../../components/navigation/ExternalNav';
import Footer from '../../components/navigation/Footer';
import GlobalNav from '../../components/navigation/GlobalNav';

import { hasData, validateFormDataNotEmpty } from '../../components/utilities/Validation'
import { initializeForm, handleFormDataChange } from '../../components/utilities/Events'
import { setErrorFieldState } from '../../components/utilities/Validation'

import { GET_UCLA_FACULTY } from '../../Graphql/UclaFaculty/QueryUclaFaculty';
import { GET_MLL_STATUS } from '../../Graphql/MyLastLecture/QueryMLL';
import { CREATE_MLL_VOTE } from '../../Graphql/MyLastLecture/MutateMLL';

const TextFieldNarrow = styled(TextField)(({ theme }) => ({
  maxWidth: 340
}));

const initErrors: any = {
  student_first_name: false, 
  student_last_name: false, 
  student_email: false, 
  faculty_name_cbo: false,
  faculty_name_input: false,
}

export const MLLVoting = () => {
  const [params] = useSearchParams()
  const first_name = params.get("fn")
  const last_name = params.get("ln")
  const email = params.get("e")

  // const cboOtherLabel = "-- OTHER --"

  const initFormData: any = {
    student_first_name: first_name || "",
    student_last_name: last_name || "",
    student_email: email || "",
    faculty_name_cbo: "",
    // faculty_name_input: "",
  }
  
  // console.debug('params: ', first_name, last_name, email)
  const [ formData, setFormData ] = useState(initFormData)
  const [ errorFields, setErrorFields ] = useState(initErrors)
  const [ professorInput, setProfessorInput ] = useState('');

  const [ getFacultyList, { loading: loadingFaculty, error: errorFaculty, data: dataFaculty }] = useLazyQuery(GET_UCLA_FACULTY, {client: apolloClientOpen})
  // const { loading: loadingFaculty, error: errorFaculty, data: dataFaculty } = useQuery(GET_UCLA_FACULTY, {variables: { faculty_name: professorInput }, client: apolloClientOpen})
  const { loading: loadingMLLStatus, error: errorMLLStatus, data: dataMLLStatus } = useQuery(GET_MLL_STATUS, {client: apolloClientOpen})
  const [ createMLLVote, { error: errorCreateMLLVote, data: dataCreateMLLVote } ] = useMutation(CREATE_MLL_VOTE, {client: apolloClientOpen})

  const [ mllStatus, setMLLStatus] = useState()
  const [ academicYear, setAcademicYear] = useState("")
  // const [ professors, setProfessors] = useState([{ label: `${cboOtherLabel}`, uid: `other`}])
  const [ professors, setProfessors] = useState([])
  const [ voted, setVoted] = useState(false)

  function handleSubmit() {
    // const faculty_name =  isFacultyFromList() ?  formData.faculty_name_cbo : formData.faculty_name_input 
    const faculty_name =  professorInput || formData.faculty_name_cbo 
    const variables = {
      student_first_name: formData.student_first_name,
      student_last_name: formData.student_last_name,
      student_email: formData.student_email,
      faculty_name: faculty_name,
      academic_year: academicYear
    }

    console.debug('handleSubmit()', academicYear, mllStatus, formData, variables)
    const valid = validateFields()
    if (!valid) {
      toast.error('Please fill out the form')
      return false
    }

    createMLLVote({variables: variables})
  }

  function validateFields() {
    let exceptions: any = []

    if (formData.faculty_name_cbo !== "" || professorInput.trim() !== "") {
      exceptions.push('faculty_name_cbo')
      exceptions.push('faculty_name_input')
    }

    console.debug('validateFields:', exceptions, formData.faculty_name_cbo, professorInput)
    const validationResponse = validateFormDataNotEmpty(formData, exceptions)

    if (validationResponse.length > 0) {
      console.debug('validationResponse: ', validationResponse)
      setErrorFields(setErrorFieldState(validationResponse.join(',')))
      return false
    }
    return true
  }

  // function isFacultyFromList() {
  //   return formData.faculty_name_cbo !== cboOtherLabel
  // }
  
  //CREATE Vote Record
  useEffect(() => {
    if (errorCreateMLLVote) {
      toast.error('Sorry, there was an error trying to submit your vote.')
    }
    if (dataCreateMLLVote && dataCreateMLLVote.createMLLVote.successful === true) {
      // console.debug('dataCreateMLLVote:', dataCreateMLLVote)
      setVoted(true)
      toast.success('Your Vote Was Successfully Cast')
    }
  }, [errorCreateMLLVote, dataCreateMLLVote, voted])

  // GET MLL Status
  useEffect(() => {
    if (dataMLLStatus) {
      // console.debug('dataMLLStatus:', dataMLLStatus)
      let status = dataMLLStatus.getMLLStatus.active
      let academicYear = dataMLLStatus.getSettingsCurrentAcademicYear[0].academic_year
      setMLLStatus(status)
      setAcademicYear(academicYear)
      
    }
  }, [errorMLLStatus, dataMLLStatus])

  useEffect(() => {

    if (professorInput.length > 2) {
      // console.debug("useEffect getFacultyList()")
      getFacultyList({variables: { faculty_name: professorInput }})
    }

  }, [professorInput, getFacultyList])

  // BUILD Professor List
  useEffect(() => {
    if (errorFaculty) {
      console.error('Error Faculty:', errorFaculty)
    }
    if (dataFaculty) {
        console.debug('List of Faculty: ', dataFaculty.getFacultyList);
        const facultyData = dataFaculty.getFacultyList
        var facultyList: any = []
        
        // facultyList.push(
        //   { key: 'other', label: `${cboOtherLabel}`, uid: `other`},
        // )

        for (let i=0; i<facultyData.length; i++) {
          facultyList.push(
            { key: `${facultyData[i].id}`, label: `${facultyData[i].first_name} ${facultyData[i].last_name}`.trim(), uid: `${facultyData[i].ucla_faculty_id}`},
          )
        }
        
        setProfessors(facultyList)
    }

}, [loadingFaculty, errorFaculty, dataFaculty]);

  if (mllStatus === false) {
    return (
      <>
      <ExternalNav />
      {/* <NoAccessPage message="MLL Voting is Closed" /> */}
      <LayoutContainer>
        <Stack spacing={3} sx={{ maxWidth: 700 }}>
          <Typography variant="h2">My Last Lecture Voting <span style={{color: '#FF8080'}}>is closed. </span> </Typography>
          <Box sx={{ maxWidth: 700 }}>
            <p>My Last Lecture voting is currently closed.  When voting opens, please return to cast your ballot for this year’s faculty member to deliver their My Last Lecture.</p>
            <p>For more information on My Last Lecture voting and results, please contact asc@alumni.ucla.edu and a member of the Alumni Scholars Club will reach out to you soon.</p>
            <p>Alumni Scholars Club</p>
            <p>Executive Committee</p>
          </Box>
          </Stack>
        </LayoutContainer>
        <Divider />
      <Footer />
      </>
    )
  }

  if (!mllStatus) {
    return <></>
  }

	return (
    <>
      <ExternalNav />
      {/* <GlobalNav /> */}
      
      <LayoutContainer>
        <Stack spacing={3} sx={{ maxWidth: 700 }}>
        { voted === false &&
        <>
          <Typography variant="h2">Welcome to the My Last Lecture Voting!</Typography>
          <Box sx={{ maxWidth: 700 }}>
            <ol>
              <li><p>Please provide your name and email address</p></li>
              <li><p>Choose the faculty member you’d like to vote for.  If you don’t see the faculty member you’d like to vote for, you may type in the name of the faculty member.</p></li>              
              <li><p>Click the “Submit Vote” to cast your ballot</p></li>
            </ol>
            <Typography paragraph>If you are having issues with voting, please contact asc@alumni.ucla.edu and a member of the Alumni Scholars Club will reach out to you soon.</Typography>
            <Typography paragraph>Good Luck!</Typography>
            <Typography paragraph>Alumni Scholars Club</Typography>
            <Typography paragraph>Executive Committee</Typography>
          </Box>
          </>
        }
        { voted &&
          <>
          <Typography variant="h2">Thank you for voting!</Typography>
          <Box sx={{ maxWidth: 700 }}>
            Once all of the votes have been gathered and tallied, you will be notified of this year’s winning faculty member to deliver the My Last Lecture along with instructions on the event date and location.
            <p>See you there!</p>
            <p>Alumni Scholars Club</p>
            <p>Executive Committee</p>
          </Box>
          </>
        }  
        { voted === false &&  
          <>
          <TextFieldNarrow
            required
            error={errorFields.student_first_name || false}
            value={formData.student_first_name || ''}
            id="first_name"
            label="Your First Name"
            onChange={(event) => { handleFormDataChange(setFormData, formData, "student_first_name", event.target.value) }}
          />
          <TextFieldNarrow
            required
            error={errorFields.student_last_name || false}
            value={formData.student_last_name || ''}
            id="last_name"
            label="Your Last Name"
            onChange={(event) => { handleFormDataChange(setFormData, formData, "student_last_name", event.target.value) }}
          />
          <TextFieldNarrow
            required
            error={errorFields.student_email || false}
            type='email'
            value={formData.student_email || ''}
            id="email"
            label="Email Address"
            onChange={(event) => { handleFormDataChange(setFormData, formData, "student_email", event.target.value) }}
          />
          <Divider />
          <ComboBox formData={formData} setFormData={setFormData} setProfessors={setProfessors} setProfessorInput={setProfessorInput} errorFields={errorFields} professors={professors} validateFields={validateFields}/>
          {/* { isFacultyFromList() === false &&
          <>
            <TextField
              error={errorFields.faculty_name_input || false}
              value={formData.faculty_name_input || ''}
              id="professor_name"
              label="Professor's Name "
              onChange={(event) => { handleFormDataChange(setFormData, formData, "faculty_name_input", event.target.value) }}
              // helperText=""
              sx={{ maxWidth: 450 }}
            />
          </>
          } */}
          <Box>
            <Button variant='contained' onClick={handleSubmit}>Submit Vote</Button>
          </Box>
          </>
        }
        </Stack>
      </LayoutContainer>
      <Divider />
      <Footer />
    </>
	);

};

function ComboBox({formData, setFormData, setProfessors, setProfessorInput, errorFields, professors, validateFields}: any) {
  return (
    <Autocomplete
      freeSolo
      disablePortal
      id="combo-box-professors"
      // onFocus={() => {
      //   console.debug("auto complete has focus")
      //   setProfessors([{ key: 'other', label: `-- OTHER --`, uid: `other`}])
      // }}
      onChange={(event, value: any) => {
        // console.debug("CBO Onchange: ", value)
        setProfessorInput("");
        handleFormDataChange(setFormData, formData, "faculty_name_cbo", value ? value.label : '')
      }}
      onInputChange={(event, newInputValue) => {
        // console.debug("CBO onInputChange:", newInputValue)
        setProfessorInput(newInputValue);
      }}
      options={professors}
      sx={{ maxWidth: 450 }}
      renderOption={(props, options) => {
        // console.debug("renderOption: ", props, options)
        // options = [{ key: 'other', label: `-- OTHER --`, uid: `other`}, ...options]
        return (
          <>
          <li {...props} key={options.key}>
            {options.label}
          </li>
          </>
        );
      }}
      renderInput={(params) => 
        <TextField
          error={errorFields.faculty_name_cbo || false} 
          {...params}
                    InputLabelProps={{
            shrink: true,
          }} 
          label="Your Professor's name"
          placeholder="Start typing your professor's name..." 
          />
      }
    />
  );
}