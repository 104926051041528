import React from "react";
import styled from 'styled-components';
import { theme } from "../../themes/default";
import {
  Link,
  Stack,
  Typography
} from "@mui/material";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

const BackLink = styled(Link)`
  font-weight: 700;
  color: ${theme.palette.primary.contrastText} !important;
  display: flex;
  alignItems: center;
  padding: 6px 24px;
  p {
    line-height: 1.5;
  }
`;

const SupportLink = styled(Link)`
  background-color: ${theme.palette.secondary.main};
  font-weight: 700;
  color: ${theme.palette.common.black} !important;
  padding: 10px 24px;
  &:hover {
    background-color: ${theme.palette.secondary.dark};
  }
`;

function AlertContainer() {
  return (
    <Stack 
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      sx={{ 
        backgroundColor: theme.layout.light, 
        color: theme.palette.primary.contrastText,
      }}
    >
      <BackLink 
        href="https://alumni.ucla.edu/" 
        underline="hover"
      >
        <ArrowCircleLeftIcon sx={{ mr: 1 }} />
        <Typography variant="body1" sx={{ lineHeight: 0, fontWeight: '700' }}>Return to UCLA Alumni</Typography>
      </BackLink>
      <SupportLink 
        href="https://giving.ucla.edu/Campaign/Donate.aspx?SiteNum=1&fund=63227O&code=M-6561&AutoFN=Y" 
        underline="none"
      >
        SUPPORT UCLA
      </SupportLink>
    </Stack>
  )
}

export default AlertContainer;