import { styled } from '@mui/material/styles';
import { Link, useNavigate } from "react-router-dom";
import { Button, Link as MuiLink } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';
import FolderIcon from '@mui/icons-material/Folder';

const LinkButton = ({ to, type, variant, reload=false, children }: any) => {
  
  let startIcon = <></>

	if (type === 'back') {
    startIcon = <ArrowBackIcon />
  } else if (type === 'info') {
    startIcon = <InfoIcon />
  } else if (type === 'add') {
    startIcon = <AddIcon />
  } else if (type === 'file') {
    startIcon = <FolderIcon />
  }

  function goBack(to: string, type: string) {
    console.debug("Button Link - goBack()", to, type)

    if (!to && type === 'back') {
      // if to is not defined, use history back
      console.debug("Button Link - history back")
      window.history.back()
    }
  }

  if (reload) {
    // for some reason going back from Bulk Import Users doesn't reload using the react-dom link. using Mui Link provided as a workaround.
    // console.debug("Mui Button Link: ", reload, to)
    return (
      <MuiLink href={to} >
        <Button variant={variant} startIcon={startIcon} >
          {children}
        </Button>
      </MuiLink>
    );
  }

  // console.debug("React Dom Button Link: ", reload, to)
  return (
    <Link to={to} style={{ textDecoration: 'none' }}>
      <Button variant={variant} startIcon={startIcon} onClick={(event) => goBack(to, type)}>
        {children}
      </Button>
    </Link>
  );
}

export default LinkButton;

LinkButton.defaultProps = {
  variant: 'contained',
  type: ''
};
