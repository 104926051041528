import { gql } from "@apollo/client";

export const CREATE_MLL_VOTE = gql`
	mutation createMLLVote(
		$academic_year: String!
		$faculty_name: String!
		$student_email: String!
		$student_first_name: String!
		$student_last_name: String!
	) {
		createMLLVote(
			academic_year: $academic_year
			faculty_name: $faculty_name
			student_email: $student_email
			student_first_name: $student_first_name
			student_last_name: $student_last_name
		) {
			error_fields
			inserted_id
			message
			successful
		}
	}
`;

export const UPDATE_SETTINGS_MLL_STATUS = gql`
	mutation updateSettingsMLLStatus($active: Boolean!) {
		updateSettingsMLLStatus(active: $active) {
			error_fields
			inserted_id
			message
			successful
		}
	}
`;
