// import React, { useEffect, useState } from "react"
import { Autocomplete, TextField } from "@mui/material"
import { handleFormDataChange } from "../../../components/utilities/Events"


export const PronounDropdown = ({ options, setFormData, formData, other_pronoun_option }: any) => {
  // const key = 'pronoun'
  // console.log('options', options)
  const label = 'Preferred Pronouns'
  let option_value = formData.pronoun

  let show_other_pronoun_text_field: boolean

  // console.debug('typeof option_value', typeof option_value, formData.pronoun)

  if (options.length > 0) {
    show_other_pronoun_text_field = formData.pronoun === other_pronoun_option

    if (show_other_pronoun_text_field) {
      option_value = other_pronoun_option
    }
  }

  if (Object.keys(options).length === 0) {
    return <></>
  }

  // console.debug('typeof option_value', typeof option_value, other_pronoun_option)
	return (
    <>
      <Autocomplete
        disablePortal
        value={{label: typeof option_value === 'string' ? option_value : ""}}
        id="pronoun"
        options={options}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label={label} />}
        onChange={(event, value: any) => { handleFormDataChange(setFormData, formData, "pronoun", value.label) }}
        />
      {formData.pronoun === "Different or multiple sets of pronouns" && 
        <AdditionalPronounForm setFormData={setFormData} formData={formData} /> }
    </>
  )
}

const AdditionalPronounForm = ({setFormData, formData}: any) => {
  return (
    <>
      <TextField
        value={formData.pronoun_other || ""}
        id="other_pronoun"
        label="Enter Your Different or Multiple Pronouns"
        onChange={(event) => { handleFormDataChange(setFormData, formData, "pronoun_other", event.target.value) }}
      />
    </>
  )
}

export default PronounDropdown