import React from "react";
import { Box, Stack, Typography } from "@mui/material"
import LinkButton from "../../../components/button/Button";

import { withPageState } from '../../../components/authorization/PageState'

import { AcademicYearForm } from "../../admin/settings/partials/SettingsForm"

const NewAcademicYear = () => {
  const initFormData: any = {
    academic_year_start: "",
    academic_year_end: "",
    event_participation_goal: 15,
    service_hours_goal: 15
  }
  return (
    <Stack spacing={3}>
      <Box>
        <Typography variant="h2">Add Academic Year</Typography>
        <Typography>
          Use the form below to define an academic year for the Alumni Scholars Club.
          <br />
          Set the start and end dates below to define the timeframe students are allowed to submit program participation. Set the goals below that align with this year’s scholarship renewal requirements.
        </Typography>
      </Box>
      <Box>
        <LinkButton to="/admin/settings" type="back" variant="outlined" reload>
          Return to Settings
        </LinkButton>
      </Box>
      <AcademicYearForm initFormData={initFormData}/>
    </Stack>
  )
}

// export default withPageState(NewAcademicYear, { pageSection: "admin", adminAccess: "admin_settings" })
export default NewAcademicYear