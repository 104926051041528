import React, { useState, useEffect } from 'react'

import { useQuery, useLazyQuery } from '@apollo/client';

import { Link } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';

import { formatGQLDate } from '../../../../components/utilities/Formatters';
import LoadingSpinner from "../../../../components/progress/Spinner";

import { GET_MLL, GET_MLL_VOTES } from '../../../../Graphql/MyLastLecture/QueryMLL';

const columnsMLLVotes: GridColDef[] = [
  // { field: 'id', headerName: 'ID', minWidth: 10},
  { field: 'academic_year', headerName: 'Academic Year', width: 125, type: 'number', align:'center' },
  { field: 'faculty_name', headerName: 'Professor', width: 150 },
  { field: 'votes', headerName: 'Votes', width: 100, type: 'number' }  // , renderCell: (params: any) => parseInt(params.row.votes)
];

const columnsMLL: GridColDef[] = [
  // { field: 'id', headerName: 'ID', minWidth: 10},
  { field: 'academic_year', headerName: 'Academic Year', width: 125, type: 'number', align:'center' },
  { field: 'faculty_name', headerName: 'Professor', width: 150 },
  { field: 'student_first_name', headerName: 'First Name', width: 150 },
  { field: 'student_last_name', headerName: 'Last Name', width: 150 },
  { field: 'student_email', headerName: 'email', width: 150 },
  { field: 'created_on', headerName: 'Submitted On', width: 175, type: 'date', valueGetter: (params: any) => params.row.created_on ? formatGQLDate({gQLDate: params.row.created_on, format: "MM/DD/YYYY"}) : "-" },
  
];

export function ListOfMLLVotes(props:any) {
  const { loading: loadingMLLVotes, error: errorMLLVotes, data: dataMLLVotes } = useQuery(GET_MLL_VOTES, {fetchPolicy: "network-only"})
  const [ getMLL, { loading: loadingMLL, error: errorMLL, data: dataMLL }] = useLazyQuery(GET_MLL, {fetchPolicy: "network-only"})

  const [ tableDataMLLVotes, setTableDataMLLVotes ] = useState([]);
  const [ pageSizeMLLVotes, setPageSizeMLLVotes ] = useState<number>(25);

  const [ tableDataMLL, setTableDataMLL ] = useState([]);
  const [ pageSizeMLL, setPageSizeMLL ] = useState<number>(25);

  function handleRowClick(params: any) {
    const academic_year = params.row.academic_year
    const faculty_name = params.row.faculty_name
    getMLL({
      variables: {
        academic_year: academic_year,
        faculty_name: faculty_name
      }
    })
    console.debug(`handlRowClick:`, academic_year, faculty_name)
  }

  useEffect(() => {
    if (errorMLL) {
      console.debug('Error MLL:', errorMLL)
    }
    if (dataMLL) {
        console.debug('Update DataGrid - List of MLL');
        console.debug(dataMLL.getMLL);
        setTableDataMLL(dataMLL.getMLL);
    }

  }, [loadingMLL, errorMLL, dataMLL, props]);

  useEffect(() => {
      if (errorMLLVotes) {
        console.debug('Error MLL Votes:', errorMLLVotes)
      }
      if (dataMLLVotes) {
          console.debug('Update DataGrid - List of MLL Votes');
          console.debug(dataMLLVotes.getFacultyList);
          // Call back to update the Active User Count
          // Add method to just get active users
          
          setTableDataMLLVotes(dataMLLVotes.getMLLVotes);
      }

  }, [loadingMLLVotes, errorMLLVotes, dataMLLVotes]);

return (
    <>
      { (loadingMLLVotes || loadingMLL) && 
        <LoadingSpinner/>
      }
      <div className="container data-grid-users">
        <h4>Votes</h4>
        <DataGrid 
          // getRowId={(row) => row.id}
          autoHeight={true}
          rows={tableDataMLLVotes}
          columns={columnsMLLVotes} 
          pageSize={pageSizeMLLVotes}
          onPageSizeChange={(newPageSize: number) => setPageSizeMLLVotes(newPageSize)}
          rowsPerPageOptions={[2, 5, 10, 25, 100]}
          onRowClick={handleRowClick}            
          density="compact"
          components={{
              Toolbar: GridToolbar,
          }}
          componentsProps={{
            toolbar: {
              csvOptions:{
                fileName: `ASC-MLL Votes-${formatGQLDate({gQLDate: new Date().getTime(), format: "YYYYMMDD"})}`,
              }
            },
          }}
          initialState={{
            // sorting: {
            //     sortModel: [{ field: 'first_name', sort: 'asc' }],
            // },
            columns: {
              columnVisibilityModel: {
                id: false,
              }
            }
          }}
        />
      </div>

      <div className="container data-grid-users">
        <h4>Vote Audit</h4>
        <p>Select a professor above to see the votes for that professor.</p>
        <DataGrid 
          // getRowId={(row) => row.id}
          autoHeight={true}
          rows={tableDataMLL}
          columns={columnsMLL} 
          pageSize={pageSizeMLL}
          onPageSizeChange={(newPageSize: number) => setPageSizeMLL(newPageSize)}
          rowsPerPageOptions={[2, 5, 10, 25, 100]}
          onRowClick={handleRowClick}            
          density="compact"
          components={{
              Toolbar: GridToolbar,
          }}
          componentsProps={{
            toolbar: {
              csvOptions:{
                fileName: `ASC-MLL Vote Audit-${formatGQLDate({gQLDate: new Date().getTime(), format: "YYYYMMDD"})}`,
              }
            },
          }}
          initialState={{
            // sorting: {
            //     sortModel: [{ field: 'first_name', sort: 'asc' }],
            // },
            columns: {
              columnVisibilityModel: {
                id: false,
              }
            }
          }}
        />
      </div>
    </>
  )
}

export default ListOfMLLVotes