import React, { useContext, useEffect, useState } from "react"
import { SettingsContext } from '../../components/authorization/GetAccessRights';

import { useParams } from "react-router-dom"
import { useQuery } from '@apollo/client'

import { withPageState } from '../../components/authorization/PageState'

import { Box, Link, Paper, Stack, styled, Typography } from "@mui/material"

import LinkButton from "../../components/button/Button"
import LayoutContainer from "../../components/container/Container"
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

import { hasData } from '../../components/utilities/Validation'
import { formatFileSize } from '../../components/utilities/Formatters'

import { getDocument } from "../../components/files/utilities/UploadDownload"

import { GET_ONE_COURSE_DOCUMENTS } from '../../Graphql/Documents/QueryDocuments'
import AcademicYearClosed from "../../components/authorization/AcademicYearClosed"

const ClassDetail = () => {
  const params = useParams()
  const id: string = params.id ? params.id : '' //class
  const settings: any = useContext(SettingsContext)
  const academicYearSettings = settings.academicYearSettings

  const { error: errorCourseDocuments, data: dataCourseDocuments } = useQuery(GET_ONE_COURSE_DOCUMENTS,  { variables: { uclaClassesId: id }})
  const [ className, setClassName ] = useState("")
  const [ notes, setNotes ] = useState([])
  const [ syllabi, setSyllabi ] = useState([])
  const [ tests, setTests ] = useState([])

  useEffect(() => {
    if (errorCourseDocuments) {
      console.debug(errorCourseDocuments)
    }
    if (dataCourseDocuments) {
      console.debug(dataCourseDocuments)
      const courseData = dataCourseDocuments.getOneCourseDocuments
      
      if (hasData(courseData)) {
        setClassName(courseData.ucla_classes_class_name)
        setNotes(courseData.documents.notes)
        setSyllabi(courseData.documents.syllabi)
        setTests(courseData.documents.tests)
      }
    }
  }, [errorCourseDocuments, dataCourseDocuments])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // if (academicYearSettings.status === 'closed') {
  //   return <AcademicYearClosed section="Notes/Test Bank"/>
  // }
  return (
		<>
      <LayoutContainer>
        <Stack spacing={7}>
          <LinkButton to="../bank" variant="outlined" type="back">Back to Class List</LinkButton>
          <Typography variant="h2">{className}</Typography>
          <Box>
            <Typography variant="h4">NOTES</Typography>
            <Stack spacing={3} mt={3}>
              { notes.map((doc: any, index: number) => (
                <DocCard key={`n${index}`} title={doc.file_display_name} term={doc.term} year={doc.academic_year} author={`${doc.user_first_name} ${doc.user_last_name}`} file_path={doc.file_path} file_type={doc.file_type} file_size={doc.file_size} file_id_aws={doc.file_id_aws} status={doc.submission_status}/>
              ))}
              { notes.length === 0 && (
                <DocCard title="" term="" year="" author="" file_path="" />
              )}
            </Stack>
          </Box>
          <Box>
            <Typography variant="h4">SYLLABI</Typography>
            <Stack spacing={3} mt={3}>
             { syllabi.map((doc: any, index: number) => (
                <DocCard key={`n${index}`} title={doc.file_display_name} term={doc.term} year={doc.academic_year} author={`${doc.user_first_name} ${doc.user_last_name}`} file_path={doc.file_path} file_type={doc.file_type} file_size={doc.file_size} file_id_aws={doc.file_id_aws} status={doc.submission_status}/>
              ))}
              { syllabi.length === 0 && (
                <DocCard title="" term="" year="" author="" file_path="" />
              )}
            </Stack>
          </Box>
          <Box>
            <Typography variant="h4">TESTS</Typography>
            <Stack spacing={3} mt={3}>
              { tests.map((doc: any, index: number) => (
                <DocCard key={`n${index}`} title={doc.file_display_name} term={doc.term} year={doc.academic_year} author={`${doc.user_first_name} ${doc.user_last_name}`} file_path={doc.file_path} file_type={doc.file_type} file_size={doc.file_size} file_id_aws={doc.file_id_aws} status={doc.submission_status}/>
              ))}
              { tests.length === 0 && (
                <DocCard title="" term="" year="" author="" file_path="" status="" />
              )}
            </Stack>
          </Box>
          <LinkButton to="../bank" variant="outlined" type="back">Back to Class List</LinkButton>
        </Stack>
      </LayoutContainer>
    </>
	);
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(3),
  maxWidth: 360,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  }
}));

function DocCard ({ title, term, year, author, file_path, file_type, file_size, file_id_aws, status }: any) {
  const titleText = title && term && year ?`${title} - ${term}, ${year}` : "No submissions"

  function handleClick(schoolYear: string, filePath: string, fileId: string, status: string) {
    console.log('handleClick:', schoolYear, filePath, fileId, status)
    if (filePath) {
      console.log('getDocument() ', schoolYear, filePath, fileId, status)
      getDocument({schoolYear: schoolYear, fileName: filePath, fileId: fileId, status: status})
    }
  }

  return (
    <Link style={{ textDecoration: 'none' }}
      onClick={() => handleClick(year, file_path, file_id_aws, status)}
    >
      <Item elevation={3}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Box>
            {file_path && (
              <ArrowDownwardIcon />
            )}    
          </Box>
          <Box>
            <Typography><strong>{titleText}</strong></Typography>
            <Typography>{file_path ? `File Type: ${file_type}` : ""}</Typography>
            <Typography>{file_size ? `File Size: ${formatFileSize(parseInt(file_size))}` : ""}</Typography>
            <Typography>{author ? author : ""}</Typography>
          </Box>
        </Stack>
      </Item>
    </Link>
  )
}

// export default withPageState(ClassDetail, {})
export default ClassDetail