export function hasData(obj: any) {
	//Just checks to see if the object has any data
	if (obj) {
		return Object.keys(obj).length > 0;
	}
	return false;
}

export function validateFormDataNotEmpty(formData: any, ignoreKeys: any = []) {
	const emptyFields: any = [];

	Object.entries(formData).forEach(([key, value]) => {
		if (value === "" && !ignoreKeys.includes(key)) {
			emptyFields.push(key);
		}
	});

	return emptyFields;
}

export function setErrorFieldState(errorFields: string) {
	// error fields = comma delimited list of fields with an error from the graphql mutation
	let tempObj: any = {};

	errorFields.split(",").forEach((key: string) => {
		console.debug("error_fields: ", key);
		tempObj[key] = true;
	});
	return tempObj;
}
