import React, { useState, useEffect, useContext, useRef } from "react";
import { SettingsContext } from '../../components/authorization/GetAccessRights';

import {useNavigate} from 'react-router-dom';

import { useMutation } from '@apollo/client'
import toast from 'react-hot-toast';

import { Box, Button, MenuItem, Stack, styled, TextField, Typography } from "@mui/material";

import { handleFormDataChange } from '../utilities/Events'
import { formatGQLDate, titleCase } from '../utilities/Formatters'
import { hasData } from '../utilities/Validation'
import { getLoggedInUser } from '../authorization/Utilities'

import { CREATE_PARTICIPATION, UPDATE_PARTICIPATION } from '../../Graphql/Participations/MutateParticipations'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";

const TextFieldStyled = styled(TextField) ({
  width: "100%",
  maxWidth: 356
})

const DatePickerStyled = styled(MobileDatePicker) ({
  width: "100%",
  maxWidth: 356
})  

export const ParticipationForm = (props: any) => {
  const settings: any = useContext(SettingsContext)
  const loggedInUserId = getLoggedInUser(settings).id || null 
  const academicYearSettings = settings.academicYearSettings

  const isSectionAdmin = window.location.href.includes('admin')
  const action = props.action ? props.action : "new"
  const userId = props.userId ? props.userId : "" //User ID coming from Admin and Student participation/new
  const data = props.data //User Data + lists

  const participationId = props.participationId ? props.participationId : ""
  const participationData = props.participationData ? props.participationData : {} //Participation Data
  
  const [ academicYears, setAcademicYears ] = useState([])
  const [ currentAcademicYear, setCurrentAcademicYear ] = useState("") //Use SettingsContext
  const [ createParticipation, { error: errorCreateParticipation, data: dataCreateParticipation } ] = useMutation(CREATE_PARTICIPATION)
  const [ updateParticipation, { error: errorUpdateParticipation, data: dataUpdateParticipation } ] = useMutation(UPDATE_PARTICIPATION)
  
  // Refactor Initing form. Pass in initial state from other form
  var initFormData: any = {
    userId: userId, //Need to replace this with the logged in user id not the id of the associated to the student
    academic_year: action === "edit" && participationData["academic_year"] ? participationData["academic_year"] : "",
    participation_category: action === "edit" && participationData["participation_category"] ? participationData["participation_category"] : "event", 
    name: action === "edit" && participationData["name"] ? participationData["name"] : "", 
    description: action === "edit" && participationData["description"] ? participationData["description"] : "", 
    participation_date: action === "edit" && participationData["participation_date"] ? formatGQLDate({gQLDate: participationData["participation_date"], format: "YYYY-MM-DD"}) : "", 
    participation_hours: action === "edit" && participationData["participation_hours"] ? participationData["participation_hours"] : 0.0, 
    
    sponsor_organization: action === "edit" && participationData["sponsor_organization"] ? participationData["sponsor_organization"] : "",
    sponsor_supervisor: action === "edit" && participationData["sponsor_supervisor"] ? participationData["sponsor_supervisor"] : "",
    sponsor_supervisor_contact_info: action === "edit" && participationData["sponsor_supervisor_contact_info"] ? participationData["sponsor_supervisor_contact_info"] : "",
    created_by_id: loggedInUserId
  }

  const [ formData, setFormData ] = useState(initFormData)
  
  const navigate = useNavigate();
	const goBack = () => {
		navigate(-1);
	}

  const handleSubmit = () => {
    console.log('handleSubmit action: ' + action)
    console.log(formData)
    const category = formData.participation_category;

    let variables: any = {
      // userId: formData.userId
      // created_by_id: formData.created_by_id,
      participation_category: category,
      name: formData.name,
      description: formData.description,
      participation_date: formatGQLDate({gQLDate: formData.participation_date, format: "YYYY-MM-DD", formatDirection: "w"}),
      academic_year: formData.academic_year
    }

    variables["participation_hours"] = category === 'service' && formData.participation_hours ? parseFloat(formData.participation_hours) : 0.0
    variables["sponsor_organization"] = category === 'service' ? formData.sponsor_organization : ""
    variables["sponsor_supervisor"] = category === 'service' ? formData.sponsor_supervisor : ""
    variables["sponsor_supervisor_contact_info"] = category === 'service' ? formData.sponsor_supervisor_contact_info : ""

    
    if (action === "new") {
      variables["userId"] = userId
      variables["created_by_id"] = loggedInUserId

      console.debug("New variables")
      console.debug(variables)

      createParticipation({
        variables: variables
      })

    } else if (action === "edit") {
      variables["id"] = participationId
      console.debug("edit variables")
      console.debug(variables)
      updateParticipation({
        variables: variables
      })

    }
    
  }

  
  useEffect(() => {
    if (hasData(participationData)) {
      // ignore the initFormData useEffect dependency. will keep running this useEffect if added
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setFormData(initFormData) 
    }
  }, [participationData])

  useEffect(() => {
    let currentAcademicYr = ""
    if (data && hasData(data)) {
      console.debug('action: ' + action)
      console.debug("ParticipationForm.tsx props.Data")
      console.debug(data)
      // console.debug("participationData")
      // console.debug(participationData)
      // console.log(data.getAcademicYears.academic_years)
      currentAcademicYr = academicYearSettings.academic_year ?? ""
      console.debug("currentAcademicYr", currentAcademicYr)
      setCurrentAcademicYear(currentAcademicYr)
      initFormData.academic_year = currentAcademicYr

      // if (action === "new") {
      //   // currentAcademicYr = data.getOneUser.current_academic_year ? data.getOneUser.current_academic_year : ""
      //   console.debug("currentAcademicYr", currentAcademicYr)
      //   setCurrentAcademicYear(currentAcademicYr)
      //   initFormData.academic_year = currentAcademicYr

      // } else if (action === "edit") {
      //   currentAcademicYr = data.getUsersByParticipation.length > 0 ? data.getUsersByParticipation[0].current_academic_year : ""
      //   setCurrentAcademicYear(currentAcademicYr)
      //   initFormData.academic_year = currentAcademicYr
      // }
      
      // ignore the initFormData useEffect dependency. will keep running this useEffect if added
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setFormData(initFormData) 

      if (data.getAcademicYears){
        setAcademicYears(data.getAcademicYears.academic_years)
      }
    }
  }, [action, data, academicYearSettings]) //do not add initForm to dependencies

  useEffect(() => {    
    // console.debug('ParticipationForm')
    // console.debug('id')
    // console.debug(props.id)
    let toastMsg = ""
    if (errorCreateParticipation) {
      toastMsg = "Error Submiting Participation: " + errorCreateParticipation
      toast.error(toastMsg)
      console.log(`${toastMsg}: `)
      console.log(errorCreateParticipation)
    } else if (dataCreateParticipation) {
      if (dataCreateParticipation.createParticipation.successful) {
        toastMsg = "Successfully Submitted Participation "
        toast.success(toastMsg)
        console.log(toastMsg)
        console.log(dataCreateParticipation)
        if (isSectionAdmin) {
          window.location.href = `/admin/user/participation/${userId}`
        } else {
          window.location.href = `/participation/${userId}`
        }
        
      } else {
        toastMsg = dataCreateParticipation.createParticipation.message
        toast.error(toastMsg)
        console.log(`${toastMsg}: `)
        console.log(dataCreateParticipation.createParticipation)
      }
      
    }
  }, [userId, errorCreateParticipation, dataCreateParticipation, isSectionAdmin])
  
  useEffect(() => {    

    let toastMsg = ""
    if (errorUpdateParticipation) {
      toastMsg = "Error Editing Participation: " + errorUpdateParticipation
      toast.error(toastMsg)
      console.log(`${toastMsg}: `)
      console.log(errorUpdateParticipation)

    } else if (dataUpdateParticipation) {
      if (dataUpdateParticipation.updateParticipation.successful) {
        toastMsg = "Successfully Editted Participation "
        toast.success(toastMsg)
        console.log(toastMsg)
        console.log(dataUpdateParticipation)
        if (isSectionAdmin) {
          // window.location.href = `/admin/user/participation/${userId}` //Currently no Admin Edit Page
        } else {
          window.location.href = `/participation/${userId}`
        }
        console.log(`nav to: /participation/${userId}`)
        
      } else {
        toastMsg = dataUpdateParticipation.updateParticipation.message
        toast.error(toastMsg)
        console.log(`${toastMsg}: `)
        console.log(dataUpdateParticipation.updateParticipation)
      }
      
    }
  }, [userId, participationId, errorUpdateParticipation, dataUpdateParticipation, isSectionAdmin])

  return (
		<>
      <Box mt={3} sx={{ maxWidth: 600 }}>
        <Stack spacing={3}>
          <TextFieldStyled
            value={formData.academic_year}
            disabled = {!isSectionAdmin}
            id="academic_year"
            select
            label="Academic Year"
            placeholder="Select one"
            onChange={(event) => { handleFormDataChange(setFormData, formData, "academic_year", event.target.value) }}
          >
            {academicYears.map((year: any, index: number) => {
              if (isSectionAdmin) {
                return (
                  <MenuItem 
                    key={index} 
                    value={year.value}
                  >
                    {year.label}
                  </MenuItem>
                )
              } else {
                if (currentAcademicYear === year.value) {
                  return (
                    <MenuItem 
                      key={index} 
                      value={year.value}
                    >
                      {year.label}
                    </MenuItem>
                  )
                }
              }
              return (<MenuItem 
                key={0} 
                value=""
              >
              </MenuItem>)
            })}
          </TextFieldStyled>
        </Stack>
        <Box mt={3}>
          <Stack spacing={3}>
            <TextFieldStyled
              value={formData.participation_category}
              id="participation_category"
              select
              label="Event or Service Participation?"
              
              placeholder="Select one"
              // onChange={handleCapacity}
              onChange={(event) => { handleFormDataChange(setFormData, formData, "participation_category", event.target.value) }}
            >
              <MenuItem value="event">Event</MenuItem>
              <MenuItem value="service">Service</MenuItem>
            </TextFieldStyled>
            {formData.participation_category === "service" ? <ServiceForm setFormData={setFormData} formData={formData}  action={action} /> : <EventForm setFormData={setFormData} formData={formData} action={action} />}
          </Stack>
        </Box>
        <Box mt={3}>
          <Typography paragraph>Please verify that all information is accurate before submitting your program participation. Incorrect information could lead to a denial of your request.</Typography>
          <Stack direction="row" spacing={2}>
            <Button  onClick={goBack} variant="outlined">Cancel</Button>
            <Button variant="contained"
              onClick={handleSubmit}
            >Submit Program Participation</Button>
          </Stack>
        </Box>
      </Box>
    </>
	);
};

const EventForm = ({setFormData, formData, action}: any) => {
  return (
    <>
      <TextFieldStyled
        value={formData.name}
        id="eventName"
        label="Name of Event"
        onChange={(event) => { handleFormDataChange(setFormData, formData, "name", event.target.value) }}
      />
      <TextField
        value={formData.description || ''}
        id="eventDesc"
        label="Brief Description of Event"
        multiline
        rows={4}
        onChange={(event) => { handleFormDataChange(setFormData, formData, "description", event.target.value) }}
      />
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePickerStyled 
          defaultValue={moment(formData.participation_date) || null}
          // id="date"
          label={`Date of ${titleCase(formData.participation_category) || 'Event'}`}
          onChange={(value) => { 
            handleFormDataChange(setFormData, formData, "participation_date", value ? moment(value.toDate()).format('YYYY-MM-DD') : null) 
          }}
        />
      </LocalizationProvider>
      
    </>
  )
}

const ServiceForm = ({setFormData, formData, action}: any) => {
  return (
    <>
      <TextFieldStyled
        value={formData.name || ""}
        id="serviceName"
        label="Name of Service"
        onChange={(event) => { handleFormDataChange(setFormData, formData, "name", event.target.value) }}
      />
      <TextField
        value={formData.description || ""}
        id="serviceDesc"
        label="Brief Description of Service"
        multiline
        rows={4}
        onChange={(event) => { handleFormDataChange(setFormData, formData, "description", event.target.value) }}
      />
      <TextFieldStyled
        value={formData.participation_hours}
        id="hours"
        label="Hours Served"
        type="number"
        onChange={(event) => { handleFormDataChange(setFormData, formData, "participation_hours", event.target.value) }}
      />

      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePickerStyled 
          defaultValue={moment(formData.participation_date) || null}
          // id="date"
          label="Date of Service"
          onChange={(date) => { 
            handleFormDataChange(setFormData, formData, "participation_date", date ? date.format('YYYY-MM-DD') : null)
          }}
        />
      </LocalizationProvider>
      <TextFieldStyled
        value={formData.sponsor_organization || ""}
        id="organization"
        label="Organization Sponsor of Service Activity"
        onChange={(event) => { handleFormDataChange(setFormData, formData, "sponsor_organization", event.target.value) }}
      />
      <TextFieldStyled
        value={formData.sponsor_supervisor || ""}
        id="supervisorName"
        label="Service Supervisor’s Name"
        onChange={(event) => { handleFormDataChange(setFormData, formData, "sponsor_supervisor", event.target.value) }}
      />
      <TextFieldStyled
        value={formData.sponsor_supervisor_contact_info || ""}
        id="supervisorContact"
        label="Service Supervisor’s Contact Info"
        helperText="Phone Number and/or Email Address"
        onChange={(event) => { handleFormDataChange(setFormData, formData, "sponsor_supervisor_contact_info", event.target.value) }}
      />
    </>
  )
}
