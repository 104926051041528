import { useEffect, useState } from 'react'
import {useDropzone} from 'react-dropzone';

const cssOuter:any = {
  padding: "16px",
  border: "1px solid rgb(232, 232, 232)",
  width: "100%",
  display: "inline-block",
  margin: "0px 0px 20px 0px",
}

const cssDropzone:any = {
  padding: "30px",
  "text-align": "center",
  border: "3px dashed #eeeeee",
  "background-color": "#fafafa",
  color: "#777",
  cursor: "pointer",
  "margin-bottom": "10px",
}

function FileDropzone(props:any) {
  const [allFiles, setAllFiles] = useState([])

  const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true
  });

  const { setSelectedFiles } = props

  const addNonDupeFiles = (newAcceptedFiles:any) => (existingAllFiles:any) => {
    const existingFilenames = [...existingAllFiles].map((file:any) => file.name)
    return existingAllFiles.concat(newAcceptedFiles.filter((file:any) => existingFilenames.indexOf(file.name) === -1))
  }

  useEffect(() => {
    setSelectedFiles(allFiles)
  }, [allFiles, setSelectedFiles])

  useEffect(() => {
    setAllFiles(addNonDupeFiles(acceptedFiles))
  }, [acceptedFiles])

  const files = allFiles.map((file:any) => (
    <li key={file.path}>
      <b>{file.path}</b> &nbsp;&nbsp;(<i>size:</i> {(Math.round(file.size/100000))/10} MB, <i>type:</i> {file.type})
    </li>
  ));

  return (
    <div style={cssOuter}>
      <div {...getRootProps({style: cssDropzone}) }>
        <input {...getInputProps()} />
        <p>Drag and drop files here</p>
        <button type="button" onClick={open}>
          Or click here to open File Dialog <br/><span>(hold down CTRL or CMD to select multiple)</span>
        </button>
      </div>
      {files.length > 0 &&
          <aside>
          <h4>Selected Files:</h4>
          <ul>{files}</ul>
        </aside>}
    </div>
  );
}

export default FileDropzone