export function initializeForm(setFormData: any, data: any) {
	setFormData((formData: any) => ({
		...formData,
		...data,
	}));
}

export function handleFormDataChange(
	setFormData: any,
	formData: any,
	key: string,
	value: any
) {
	// NOTE: console.log(formData) will look like it's 1 behind. console.logging(formData) onClick will show correct data.
	// console.debug("START handleChange()");
	// console.debug("handleFormDataChange:", value);
	let updateValue: any = {};

	updateValue[key] = value;

	setFormData(() => ({
		...formData,
		...updateValue,
	}));
	// console.debug(formData);
	// console.debug("END handleChange()");
}
