import { gql } from "@apollo/client";

export const GET_UCLA_CLASSES = gql`
	query getUclaClasses($id: ID) {
		getUclaClasses(id: $id) {
			id
			ucla_class_name
		}
	}
`;
