import { gql } from "@apollo/client";

export const CREATE_COURSE_DOCUMENT = gql`
	mutation createCourseDocument(
		$userId: ID! # use logged in ID. Create should only be from Student side
		$created_by_id: String! # use logged in ID. Creation should only be from Student side. Move to context on server side?
		$academic_year: String!
		$submission_type: String!
		$submitted_name: String!
		$term: String!
		$professor_name: String!
		$file_id_aws: String!
		$file_display_name: String
		$file_path: String!
		# $file_name: String!
		$file_type: String!
		$file_size: String!
	) {
		createCourseDocument(
			userId: $userId
			created_by_id: $created_by_id
			academic_year: $academic_year
			submission_type: $submission_type
			submitted_name: $submitted_name
			term: $term
			professor_name: $professor_name
			file_id_aws: $file_id_aws
			file_display_name: $file_display_name
			file_path: $file_path
			# file_name: $file_name
			file_type: $file_type
			file_size: $file_size
		) {
			successful
			message
			error_fields
			inserted_id
		}
	}
`;

//Admin Only
export const UPDATE_COURSE_DOCUMENT = gql`
	mutation updateCourseDocument(
		$id: ID!
		$uclaClassesId: ID
		$file_display_name: String
		$submission_status: String
		$submission_status_changed_by_id: String
	) {
		updateCourseDocument(
			id: $id
			uclaClassesId: $uclaClassesId
			file_display_name: $file_display_name
			submission_status: $submission_status
			submission_status_changed_by_id: $submission_status_changed_by_id
		) {
			successful
			message
			error_fields
		}
	}
`;

export const UPDATE_DOCUMENT_STATUS = gql`
	mutation updateCourseDocumentStatus(
		$id: ID!
		$participation_status: String
	) {
		updateCourseDocumentStatus(
			id: $id
			participation_status: $participation_status
		) {
			successful
			message
			error_fields
		}
	}
`;

export const DELETE_DOCUMENT = gql`
	mutation deleteCourseDocument($id: ID!) {
		deleteCourseDocument(id: $id) {
			successful
			message
			error_fields
		}
	}
`;
