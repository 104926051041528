import React, { useContext, useEffect, useState } from "react";
import { SettingsContext } from '../../components/authorization/GetAccessRights'
// import { PageState, withPageState } from '../../components/authorization/PageState'

import { useParams } from "react-router-dom"
import { useLazyQuery } from '@apollo/client'

import { getLoggedInUser } from "../../components/authorization/Utilities"

import { GET_ONE_USER_NON_ADMIN } from '../../Graphql/Users/QueryUser'

import { Typography } from "@mui/material"
import LinkButton from "../../components/button/Button"
import LayoutContainer from "../../components/container/Container"
import ParticipationProgress from "./partials/ParticipationProgress"
import ParticipationSubmissions from "./partials/ParticipationSubmissions"

import { getUserId } from "../../components/authorization/Utilities"
import LoadingSpinner from "../../components/progress/Spinner";
import AcademicYearClosed from "../../components/authorization/AcademicYearClosed";

const ProgramParticipation = () => {
  const settings: any = useContext(SettingsContext)
  const loggedInUserId = getLoggedInUser(settings).id || null
  const academicYearSettings = settings.academicYearSettings

  const params = useParams()
  // const id: string = params.id ? params.id : localStorageLoggedInUser()["id"] ? localStorageLoggedInUser()["id"] : ''
  const id = getUserId(settings.accessRights, params.id)

  // const { error: errorUser, data: dataUserAndLists } = useQuery(GET_ONE_USER_NON_ADMIN,  { variables: { id: id }}) // Change to LazyQuery. need to get current academic year first
  const [ getUserAndLists, { loading: loadingUser, error: errorUser, data: dataUserAndLists }] = useLazyQuery(GET_ONE_USER_NON_ADMIN, { fetchPolicy: "network-only" }) // Change to LazyQuery. need to get current academic year first
  const [ userData, setUserData ] = useState<any>({});
  const [ participationData, setParticipationData ] = useState<any>([]);
  const [ participationStats, setParticipationStats ] = useState<any>([]);

  useEffect(() => {
    if (settings.academicYearSettings && academicYearSettings.status !== 'closed') {
        getUserAndLists({
          variables: { id: id, academic_year: settings.academicYearSettings.academic_year}
        })
      // }
    }
  }, [id, settings, getUserAndLists])


  useEffect(() => {       
    if (!errorUser && dataUserAndLists) {
      console.debug('dataUserAndLists: ')
      console.debug(dataUserAndLists)
      setUserData(dataUserAndLists.getOneUser)
      setParticipationData(dataUserAndLists.getUsersParticipations)
      setParticipationStats(dataUserAndLists.getUserParticipationStats)
    }
    
    if (errorUser) {
      console.debug('errorUser')
      console.debug(errorUser)
    }
  }, [id, errorUser, dataUserAndLists])

  if (academicYearSettings.status === 'closed') {
    return <AcademicYearClosed section="Program Participation"/>
  }
	return (
		<>
      { loadingUser && 
        <LoadingSpinner/>
      }
      <LayoutContainer>
        <Typography variant="h3">Welcome back, { userData.first_name }!</Typography>
        <Typography><strong>Undergraduate, Class of {userData.graduation_year} - { userData.major_degree } </strong></Typography>
        <Typography><strong>{userData.user_committee}</strong></Typography>
      </LayoutContainer>
      <ParticipationProgress participationStats={participationStats} academicYearSettings={academicYearSettings}/>
        <LayoutContainer>
          <Typography sx={{ maxWidth: 395, mb: 3 }}><strong>Notice:</strong> The above progress panel reflects only verified and approved program participation submissions. Check back once submissions are processed.</Typography>
          <LinkButton to="https://alumni.ucla.edu/alumni-scholars-club/about/#renewal" variant="outlined" type="info" reload="true">
            View Scholarship Renewal Requirements
          </LinkButton>
        </LayoutContainer>
      <ParticipationSubmissions id={id} participationData={participationData} academicYearSettings={academicYearSettings}/>      
    </>
	);
};

// export default withPageState(ProgramParticipation, {})
export default ProgramParticipation
