import { Box, Stack, styled, Typography } from "@mui/material";
import LayoutContainer from "../../components/container/Container";
import { theme } from "../../themes/default";

const BoxStyled = styled(Box)({
  color: theme.palette.primary.contrastText
})

const AcademicYearClosed = (props: any) => {
  const { academicYearSettings, section } = props;

	return (
    <>
    <LayoutContainer />
    <LayoutContainer background="dark">
      <BoxStyled>
        <Stack spacing={3}>
          <>
            <Typography variant="h2">{section} is Currently Closed</Typography>
          </>
        </Stack>
      </BoxStyled>
    </LayoutContainer>
    <LayoutContainer />
    </>
	);
};

export default AcademicYearClosed;
