const loginEnv =
	process.env.REACT_APP_AWS_ENVIRONMENT === "local"
		? "dev"
		: process.env.REACT_APP_AWS_ENVIRONMENT;

// redirects user to UCLA site to sign on, with return url in query parameter (redirect_url)
export const doLogin = (page: any, returnPage: any) => {
	const url =
		"https://" +
		(loginEnv === "prod" ? "" : loginEnv + '.') +
		"account.alumnischolarsclub.alumni.ucla.edu/" +
		page +
		"?redirect_url=" +
		encodeURIComponent(returnPage);

	// console.debug("redirect url: " + url);
	// window.location.href = url;
	return url;
};

// you'll probably also want to create some kind of timer that auto-logs the user out 1 hour after logging in
// the token is only good for an hour, and we don't have any refresh token capability on this site (very complicated)
// for now I think we should just
export const doLogout = (logoutLandingPage: any) => {
	deleteCookie(".UCLAEAIDMID", "/", "ucla.edu");
	localStorage.removeItem("loggedInUser");
	localStorage.removeItem("sessionEnds");
	localStorage.removeItem("uclaeaidmtoken");
	localStorage.removeItem("redirectedToProfile");

	localStorage.setItem("loggedOut", "1"); //track what's caused user to logout in order to redirect to the correct page
	// return doLogin("signin", "/participation");
	window.location.href = "/";

	return logoutLandingPage;
	// window.location.href = logoutLandingPage;
};

export const deleteCookie = (name: any, path: any, domain: any) => {
	if (getCookie(name)) {
		document.cookie =
			name +
			"=" +
			(path ? ";path=" + path : "") +
			(domain ? ";domain=" + domain : "") +
			";expires=Thu, 01 Jan 1970 00:00:01 GMT";
	}
};

export const getCookie = (name: any) => {
	const value = "; " + document.cookie;
	const parts = value.split("; " + name + "=") || [];
	if (parts.length === 2) return (parts.pop() || "").split(";").shift();
};

export const initAccessRights = {
	// admin: false,
	admin_permanent: false,
	admin_settings: false,
	admin_users: false,
	admin_participation: false,
	admin_bank: false,
	bank_no_access: null,
	bank_allow_view: null,
	graduated_out_of_program: false,
};

export function getLocalStorageObj(key: string) {
	const obj = localStorage.getItem(key) ? localStorage.getItem(key) : "";

	return obj ? JSON.parse(obj) : null;
}

export function localStorageLoggedInUser() {
	return getLocalStorageObj("loggedInUser");
}

export function getLoggedInUser(settings: any) {
	// const loggedInUser = settings.loggedInUserId
	// 	? settings.loggedInUserId
	// 	: localStorageLoggedInUser()?.id ?? null;

	// // console.debug(
	// // 	"ASC - getLoggedInUser(): ",
	// // 	loggedInUser,
	// // 	localStorageLoggedInUser(),
	// // 	settings
	// // );
	// // force logout / remove cookies
	// if (!loggedInUser && settings.isLoading === false) {
	// 	console.debug(
	// 		"ASC - LOGOUT getLoggedInUser(): ",
	// 		loggedInUser,
	// 		localStorageLoggedInUser(),
	// 		settings
	// 	);
	// 	doLogout("/");
	// }

	// return loggedInUser;
	return localStorageLoggedInUser();
}

export function emptyDataIfNoAccess(data: any, allowAccess: boolean) {
	console.log("emptyDataIfNoAccess:", data);
	if (Array.isArray(data)) {
		if (data.length > 0 && !allowAccess) {
			//data is an array of objects
			data = [];
		}
	} else {
		if (!allowAccess) {
			//data is single object
			data = {};
		}
	}
	console.log("emptyDataIfNoAccess:", data);

	return data;
}

export function getUserId(accessRights: any, paramsID: string = "") {
	if (accessRights.admin) {
		// console.debug(
		// 	"Has Admin Access Rights - Use ID param if present, otherwise use localStorage"
		// );
		return paramsID ? paramsID : localStorageLoggedInUser().id;
	}
	// console.debug("Use localStorage");
	return localStorageLoggedInUser().id;
}
