import React from "react";
import { Box, Typography } from "@mui/material";
import LayoutContainer from "../../components/container/Container";
import LinkButton from "../../components/button/Button";

import { withPageState } from '../../components/authorization/PageState'

const RenewalRequirements = () => {
	return (
    <LayoutContainer>
      <Box sx={{ maxWidth: 660 }}>
        <Typography variant="h2" sx={{ mb: 3 }}>
          Scholarship Renewal Requirements
        </Typography>
        <Typography paragraph>
          Here is where the body copy explaining the scholarship renewal requirements will go.
        </Typography>
        <Typography paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed efficitur lorem, ut consectetur lacus. Suspendisse condimentum interdum lectus, non bibendum dui placerat quis. Proin posuere dui a orci fermentum, sed porttitor magna egestas. Fusce non eleifend lorem. Praesent interdum arcu sit amet nunc dapibus cursus. Integer venenatis tortor eget erat fermentum, sed viverra sem luctus. Maecenas ac dolor sapien. Morbi fringilla vitae dui in egestas. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nunc id ultricies elit. Curabitur aliquam, ligula eget pharetra sodales, augue ipsum fermentum tellus, at eleifend ipsum lorem id dolor. Morbi scelerisque tortor non justo cursus, at venenatis lacus vulputate. Proin pharetra, tellus sed lobortis efficitur, tortor odio vestibulum ante, in lacinia augue nibh porttitor metus. Praesent vulputate ultricies consequat. Fusce a metus vitae lacus accumsan dignissim.
        </Typography>
        <Typography paragraph>
          Cras lorem libero, mattis et sem sed, posuere blandit quam. Praesent consectetur tempus scelerisque. Aenean malesuada erat nec lectus consequat imperdiet. Fusce fermentum elementum justo. Fusce hendrerit ex orci, et posuere dolor lobortis eget. Etiam magna augue, egestas et justo a, efficitur viverra lectus. Cras augue metus, dapibus sed auctor rhoncus, luctus eu nulla. Proin vitae ante non nisi commodo laoreet ac quis est. 
        </Typography>
        <Box mt={5}>
          <LinkButton to="../participation" variant="outlined" type="back">
            Back to Program Participation
          </LinkButton>
        </Box>
      </Box>
    </LayoutContainer>
	);
};

// export default withPageState(RenewalRequirements, {})
export default RenewalRequirements
