import React, { useState, useEffect, useContext } from "react";
import { useMutation } from '@apollo/client'

// import moment from "moment";

import toast from 'react-hot-toast';

import { Box, Button, Stack, TextField } from "@mui/material"

import { handleFormDataChange } from '../../../../components/utilities/Events'
import { formatGQLDate } from '../../../../components/utilities/Formatters'
import { hasData } from '../../../../components/utilities/Validation'

import { SettingsContext } from '../../../../components/authorization/GetAccessRights';
import { getLoggedInUser } from '../../../../components/authorization/Utilities'


import { CREATE_SETTINGS_ACADEMIC_YEAR, UPDATE_SETTINGS_ACADEMIC_YEAR, DELETE_SETTINGS_ACADEMIC_YEAR } from "../../../../Graphql/Settings/MutateSettings";

export const AcademicYearForm = (props: any) => {
  const settings: any = useContext(SettingsContext)
  const loggedInUserId = getLoggedInUser(settings) ? getLoggedInUser(settings).id : null 

  var initFormData = props.initFormData
  var action = props.action ?? "add"
  var id = props.action === 'edit' && props.id ? props.id : '';

  const [ formData, setFormData ] = useState(initFormData)
  const [ createSettingsAcademicYear, { error: errorCreateSettings, data: dataCreateSettings } ] = useMutation(CREATE_SETTINGS_ACADEMIC_YEAR)
  const [ updateSettingsAcademicYear, { error: errorUpdateSettings, data: dataUpdateSettings } ] = useMutation(UPDATE_SETTINGS_ACADEMIC_YEAR)
  const [ deleteSettingsAcademicYear, { error: errorDeleteYear, data: dataDeleteYear } ] = useMutation(DELETE_SETTINGS_ACADEMIC_YEAR, {variables: {id: id}})

	const goBack = () => {
    window.history.back()
	}

  function handleSubmit() {
    let variables = formData;

    // variables.academic_year = moment(variables.academic_year_end).format("YYYY").toString()
    variables.created_by_id = loggedInUserId
    console.log(variables)

    if (action === 'add') {
      createSettingsAcademicYear ({
        variables: variables
      })
    } else if (action === 'edit') {
      variables.id = id
      updateSettingsAcademicYear ({
        variables: variables
      })
    }
  }
  
  function handleDelete() {
    deleteSettingsAcademicYear()
  }

  useEffect(() => {    
    let toastMsg = ""
    if (errorDeleteYear) {
      toastMsg = "Error Deleting Academic Year: " + errorDeleteYear
      toast.error(toastMsg)
      console.log(`${toastMsg}: `)
      console.log(errorDeleteYear)

    }  
    
    if (dataDeleteYear) {

      if (dataDeleteYear.deleteSettingsAcademicYear.successful) {
        toastMsg = "Successfully Deleted Academic Year "
        toast.success(toastMsg)
        console.log(toastMsg)
        console.log(dataDeleteYear)
        
        window.location.href = "/admin/settings"

      } else {
        toastMsg = dataDeleteYear.deleteSettingsAcademicYear.message
        toast.error(toastMsg)
        console.log(`${toastMsg}: `)
        console.log(dataDeleteYear.deleteSettingsAcademicYear)
      }
      
    }
  }, [errorDeleteYear, dataDeleteYear])
  

  useEffect(() => {
    if (hasData(initFormData)) {
      // ignore the initFormData useEffect dependency. will keep running this useEffect if added
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setFormData(initFormData) 
    }
  }, [initFormData])

  useEffect(() => {    
    let toastMsg = ""
    if (errorCreateSettings) {
      toastMsg = "Error Submiting Academic Year: " + errorCreateSettings
      toast.error(toastMsg)
      console.log(`${toastMsg}: `)
      console.log(errorCreateSettings)

    } 
    
    if (dataCreateSettings) {

      if (dataCreateSettings.createSettingsAcademicYear.successful) {
        toastMsg = "Successfully Submitted Academic Year "
        toast.success(toastMsg)
        console.log(toastMsg)
        console.log(dataCreateSettings)
        
        window.location.href = "/admin/settings"

      } else {
        toastMsg = dataCreateSettings.createSettingsAcademicYear.message
        toast.error(toastMsg)
        console.log(`${toastMsg}: `)
        console.log(dataCreateSettings.createSettingsAcademicYear)
      }
      
    }
  }, [errorCreateSettings, dataCreateSettings])
  
  useEffect(() => {    
    let toastMsg = ""
    if (errorUpdateSettings) {
      toastMsg = "Error Submiting Academic Year: " + errorUpdateSettings
      toast.error(toastMsg)
      console.log(`${toastMsg}: `)
      console.log(errorUpdateSettings)

    } else if (dataUpdateSettings) {
      
      if (dataUpdateSettings.updateSettingsAcademicYear.successful) {
        toastMsg = "Successfully Submitted Academic Year "
        toast.success(toastMsg)
        console.log(toastMsg)
        console.log(dataUpdateSettings)
        
        window.location.href = "/admin/settings"

      } else {
        toastMsg = dataUpdateSettings.updateSettingsAcademicYear.message
        toast.error(toastMsg)
        console.log(`${toastMsg}: `)
        console.log(dataUpdateSettings.updateSettingsAcademicYear)
      }
      
    }
  }, [errorUpdateSettings, dataUpdateSettings])

  return (
    <Box 
      component="form" 
      noValidate
      autoComplete="off"
    >
      <Stack spacing={3} sx={{ maxWidth: 300, mt: 3 }}>
        <TextField
          value={formData.academic_year_start || ''}
          id="academic-start-date"
          type="date"
          label="Academic Year Start Date"
          helperText="Specific Format Required: MM/DD/YYYY"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => { handleFormDataChange(setFormData, formData, "academic_year_start", formatGQLDate({gQLDate: event.target.value, format: "YYYY-MM-DD", formatDirection: "w"})) }}
        />
        <TextField
          value={formData.academic_year_end || ''}
          id="academic-close-date"
          type="date"
          label="Academic Year Close Date"
          helperText="Specific Format Required: MM/DD/YYYY"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => { handleFormDataChange(setFormData, formData, "academic_year_end", formatGQLDate({gQLDate: event.target.value, format: "YYYY-MM-DD", formatDirection: "w"})) }}
        />
        <TextField
          id="event-goal"
          type="number"
          label="Event Participation Goal"
          value={formData.event_participation_goal || ''}
          onChange={(event) => { handleFormDataChange(setFormData, formData, "event_participation_goal", parseInt(event.target.value)) }}
        />
        <TextField
          id="service-goal"
          type="number"
          label="Service Hours Goal"
          value={formData.service_hours_goal || ''}
          onChange={(event) => { handleFormDataChange(setFormData, formData, "service_hours_goal", parseInt(event.target.value)) }}
        />
        <Stack direction="row" spacing={2}>
          <Button variant="contained"
            onClick={handleSubmit}>{ action === 'edit' ? 'Edit' : 'Add'} Academic Year</Button>
          <Button  onClick={goBack} variant="outlined">Cancel</Button>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Button  onClick={handleDelete} variant="outlined">TEST - DELETE</Button>
        </Stack>
      </Stack>
    </Box>
  )
}
