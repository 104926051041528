import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQuery, useLazyQuery } from '@apollo/client'

import { POC_USER_GET_PROFILE } from '../../Graphql/Users/QueryUser'
import { doLogout, doLogin, deleteCookie, getCookie } from './util'

const returnPage = window.location.protocol+'//'+window.location.hostname+(window.location.port ? ':'+window.location.port: '') + '/participation';
const sessionTimeout = 24*60*60*1000; // access and id tokens have been set to 1 day in Cognito

const LoginLandingPageDemo = () => {
  const [getUserProfile, { loading, data }] = useLazyQuery(POC_USER_GET_PROFILE);
  const [loggedInUser, setLoggedInUser] = useState(localStorage.loggedInUser ? JSON.parse(localStorage.loggedInUser) : null);

	// scrapes JWT token from cookie for user returning from logging in on the CIDM Portal
	// (the token is also appended to the URL in dev mode, to make local development easier)
	// you'll probably want to show some kind of loading screen while the app retrieves the newly logged-in user's profile
	// this can be local to a special page that the user lands on after logging in
	// if we need login intervening (user clicks page, logs in, continues on that page) we can use local storage and a redirect page param
	useEffect(() => {
		if (!localStorage.loggedInUser) {
	    let cognitoIdToken:any = getCookie('.UCLAEAIDMID');

	    // check url param for local dev mode only
	    if (!cognitoIdToken && process.env.REACT_APP_AWS_ENVIRONMENT === "local" && window.location.href.indexOf('uclaeaidmtoken') > -1) {
	      const urlParams = new URLSearchParams(window.location.search);
	      cognitoIdToken = urlParams.get('uclaeaidmtoken');
	    }

	    if (cognitoIdToken) {
			 	localStorage.setItem('uclaeaidmtoken', cognitoIdToken)
	      getUserProfile(); // call to get the logged in user's user profile
	    }
	  }
	}, [getUserProfile])

	// is activated when the POC_USER_GET_PROFILE query populates the user data (post user login)
	useEffect(() => {
	 	if (data && data.getLogInProfile) {
		 	localStorage.setItem('loggedInUser', JSON.stringify(data.getLogInProfile))
	 		setLoggedInUser(data.getLogInProfile)

			// auto-logs the user out 1 hour after logging in (if they haven't already)
			// Cognito tokens are only good for an hour, and we can't implement refresh token functionality on this site
			// we can increase the token timeout, but we still need to flush stale tokens/logins out of localStorage somehow
	 		localStorage.setItem('sessionEnds', ((new Date()).getTime() + 1*sessionTimeout).toString());

		 	// strip off local dev url param if it exists
		 	if (process.env.REACT_APP_AWS_ENVIRONMENT === "local" && window.location.href.indexOf('uclaeaidmtoken') > -1) {
		    window.location.href = returnPage;
		  }
		}
	 	// user isn't found in system
	 	else if (data) {
		 	localStorage.removeItem('uclaeaidmtoken')
	 		// I'm sure there's a better way to do internal routing but I couldn't find any examples
	 		document.location.href = '/user_not_found_in_asc_database';
		}
	}, [data])

	// this auto logout probably needs to go somewhere global and may need to be tweaked, just an example for now
	useEffect(() => {
	  const sessionEnds:any = localStorage.getItem('sessionEnds');
	  if (localStorage.loggedInUser && sessionEnds) {
	  	const now = (new Date()).getTime();
	  	if (1*sessionEnds < now) {
      	doLogout(returnPage);
    	}
	  	else {
      	setTimeout(() => doLogout(returnPage), sessionEnds - now);
    	}
    }
	}, [])

  return (
		<>
			<Typography variant="h1">ASC Login/Logout Demonstration Page</Typography>
			<br/>
			{!loggedInUser &&
				<>
					&nbsp;<button onClick={() => doLogin('signin', returnPage)}>Login Test</button>
					&nbsp;<button onClick={() => doLogin('registration', returnPage)}>Sign Up Test</button> (just an example in case we want a separate signup button, I'm guessing we don't)
				</>}
			{loggedInUser &&
				<>
					&nbsp;<button onClick={() => doLogout("/")}>Logout Demonstration</button>
				</>}
			<br/>&nbsp;<br/>
			<b>&nbsp;Logged in user:</b> {loggedInUser ? loggedInUser.first_name + " " + loggedInUser.last_name + " - " + loggedInUser.email : "NONE"}
			<br/>&nbsp;<br/>
			<div></div>
		</>
	);
};

export default LoginLandingPageDemo;

