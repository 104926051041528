import { gql } from "@apollo/client";

export const GET_USERS_FILTERED_PARTICIPATIONS = gql`
	query getUsersByParticipation(
		$userId: ID
		$participation_id: ID
		$participation_status: String
		$academic_year: String
	) {
		getUsersByParticipation(
			userId: $userId
			participation_id: $participation_id
			participation_status: $participation_status
		) {
			id
			full_name
			first_name
			last_name
			full_name
			scholarship_type
			scholarship_id
			scholarship_name
			user_committee
			graduation_year
			major_degree
			minor_degree
			birthday
			pronoun
			pronoun_other
			email
			phone
			address_1
			address_2
			city
			state
			postal_code
			country
			# active
			# active_on
			# inactive_on
			last_logged_in
			created_on
			updated_on
			participations {
				id
				academic_year
				participation_category
				participation_status
				name
				description
				participation_date
				participation_hours
				sponsor_supervisor
				sponsor_organization
				sponsor_supervisor_contact_info
				participation_status_changed_on
				created_by_id
				created_on
				updated_on
			}
		}
		getUserParticipationStats(
			userId: $userId
			academic_year: $academic_year
		) {
			userId
			academic_year
			participation_total_events_approved
			participation_total_events_denied
			participation_total_events_pending
			participation_total_hours_approved
			participation_total_hours_denied
			participation_total_hours_pending
		}

		getAcademicYears {
			academic_years {
				type
				label
				value
			}
		}
	}
`;

export const GET_USERS_PARTICIPATIONS = gql`
	query getUsersParticipations(
		$userId: ID
		$participation_id: ID
		$participation_status: String
		$academic_year: String
	) {
		getUsersParticipations(
			userId: $userId
			participation_id: $participation_id
			participation_status: $participation_status
      # academic_year: $academic_year # Uncomment to apply academic year to query
		) {
			id
			academic_year
			participation_category
			participation_status
			name
			description
			participation_date
			participation_hours
			sponsor_supervisor
			sponsor_organization
			sponsor_supervisor_contact_info
			participation_status_changed_on
			created_by_id
			created_on
			updated_on
			userId
			user_first_name
			user_last_name
		}
		getUserParticipationStats(
			userId: $userId
			academic_year: $academic_year
		) {
			userId
			academic_year
			participation_total_events_approved
			participation_total_events_denied
			participation_total_events_pending
			participation_total_hours_approved
			participation_total_hours_denied
			participation_total_hours_pending
		}
	}
`;

export const GET_ALL_USER_PARTICIPATION_STATS = gql`
	query getAllUserParticipationStats($userId: ID, $academic_year: String) {
		getAllUserParticipationStats(
			userId: $userId
			academic_year: $academic_year
		) {
			academic_year
			academic_year_events_goal
			academic_year_events_goal_achieved
			academic_year_hours_goal_achieved
			academic_year_hours_goal
			participation_total_events_approved
			participation_total_events_denied
			participation_total_events_pending
			participation_total_hours_approved
			participation_total_hours_denied
			participation_total_hours_pending
			userId
			user_first_name
			user_last_name
		}
	}
`;
