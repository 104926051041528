import { styled } from '@mui/material/styles';
import { Box, Stack, Typography } from "@mui/material";
import LayoutContainer from "../container/Container";
import { theme } from "../../themes/default";
import LinkButton from '../button/Button';

const BoxStyled = styled(Box)({
  color: theme.palette.primary.contrastText,
});

const AdditionalLinks = (props: any) => {
  
	return (
    <LayoutContainer background="dark">
      <BoxStyled mb={3}>
        <Typography variant='h3'>
          Additional ASC Features
        </Typography>
      </BoxStyled>
      <Stack spacing={3}>
        <LinkButton to="/bank" variant="outlined">Notes/Test Bank</LinkButton>
        <LinkButton to="/profile" variant="outlined">Update Personal Info</LinkButton>
      </Stack>
    </LayoutContainer>
	);
};

export default AdditionalLinks;
