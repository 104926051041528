import { gql } from "@apollo/client";

export const CREATE_SETTINGS_ACADEMIC_YEAR = gql`
	mutation createSettingsAcademicYear(
		$academic_year_end: String!
		$academic_year_start: String!
		$event_participation_goal: Int!
		$service_hours_goal: Int!
		$created_by_id: String
	) {
		createSettingsAcademicYear(
			academic_year_end: $academic_year_end
			academic_year_start: $academic_year_start
			event_participation_goal: $event_participation_goal
			service_hours_goal: $service_hours_goal
			created_by_id: $created_by_id
		) {
			error_fields
			inserted_id
			message
			successful
		}
	}
`;

export const UPDATE_SETTINGS_ACADEMIC_YEAR = gql`
	mutation updateSettingsAcademicYear(
		$id: ID!
		$academic_year_end: String!
		$academic_year_start: String!
		$event_participation_goal: Int!
		$service_hours_goal: Int!
	) {
		updateSettingsAcademicYear(
			id: $id
			academic_year_end: $academic_year_end
			academic_year_start: $academic_year_start
			event_participation_goal: $event_participation_goal
			service_hours_goal: $service_hours_goal
		) {
			error_fields
			inserted_id
			message
			successful
		}
	}
`;

export const UPDATE_SETTINGS_ACADEMIC_YEAR_STATUS = gql`
	mutation updateSettingsAcademicYearStatus($id: ID!, $active: Boolean) {
		updateSettingsAcademicYearStatus(id: $id, active: $active) {
			error_fields
			inserted_id
			message
			successful
		}
	}
`;

export const DELETE_SETTINGS_ACADEMIC_YEAR = gql`
	mutation deleteSettingsAcademicYear($id: ID!) {
		deleteSettingsAcademicYear(id: $id) {
			successful
			message
			error_fields
		}
	}
`;
