import React, { useContext, useState, useEffect } from 'react'

import { useQuery, useMutation } from '@apollo/client';

import toast from 'react-hot-toast';

import { Button, Box, Divider, Grid, Paper, Stack, styled, Typography } from "@mui/material";
import LinkButton from "../../../components/button/Button"
import ListOfFaculty from './partials/ListOfFaculty';
import ListOfMLLVotes from './partials/ListOfMLLVotes';

// import BankManagementTable from "./partials/BankManagementTable";

import { GET_MLL_STATUS } from '../../../Graphql/MyLastLecture/QueryMLL';
import { GET_UCLA_FACULTY } from '../../../Graphql/UclaFaculty/QueryUclaFaculty';

import { UPDATE_SETTINGS_MLL_STATUS } from '../../../Graphql/MyLastLecture/MutateMLL';

export const MLLManagment = () => {
  const { loading: loadingFaculty, error: errorFaculty, data: dataFaculty } = useQuery(GET_UCLA_FACULTY, {fetchPolicy: "network-only"})
  const { loading: loadingMLLStatus, error: errorMLLStatus, data: dataMLLStatus } = useQuery(GET_MLL_STATUS, {fetchPolicy: "network-only"})
  const [ updateSettingsMLL, { error: errorUpdateSettings, data: dataUpdateSettings } ] = useMutation(UPDATE_SETTINGS_MLL_STATUS)

  const [ mllStatus, setMLLStatus] = useState(false)
  const [ newMLLStatus, setNewMLLStatus] = useState(false)

  function changeMLLStatus() {

    setNewMLLStatus(!mllStatus)
    console.debug('changeMLLStatus:', !mllStatus)
    updateSettingsMLL({
      variables: {active: !mllStatus}
    })
    
  }

  useEffect(() => {
    if (dataMLLStatus) {
      console.debug('dataMLLStatus', dataMLLStatus)
      let status = dataMLLStatus.getMLLStatus.active
      setMLLStatus(status)
      
      // setMLLStatus()
    }
  }, [errorMLLStatus, dataMLLStatus])

  useEffect(() => {
    if (errorUpdateSettings) {
      toast.error("Error Changing the MLL Status")
    }
    if (dataUpdateSettings && dataUpdateSettings.updateSettingsMLLStatus.successful === true) {
      console.log(dataUpdateSettings)
      setMLLStatus(newMLLStatus)
      toast.success("MLL Voting is: " + (newMLLStatus === true ? "ON" : "OFF"))

    } else if (dataUpdateSettings && !dataUpdateSettings.updateSettingsMLLStatus.successful) {
      toast.error("Error Changing the MLL Status: " + dataUpdateSettings.updateSettingsMLLStatus.message)
    }
  }, [errorUpdateSettings, dataUpdateSettings, newMLLStatus])

	return (
		<>
      <Stack spacing={3}>
        <Typography variant="h2">MLL</Typography>
        <Divider />
      </Stack>
      <Grid container spacing={3} mt={1} sx={{ maxWidth: 800 }}>
        
      </Grid>
      <Box mt={3}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Button variant="contained" color={mllStatus === true ? 'error' : 'success' || 'primary'}
              onClick={changeMLLStatus}>{mllStatus === true ? 'Stop Voting' : 'Start Voting' || ''}
          </Button>
          <LinkButton to="/admin/mll/faculty/new/bulk" variant="contained">
            Bulk Add Faculty
          </LinkButton>
        </Stack>
      </Box>
      <ListOfFaculty/>
      <ListOfMLLVotes/>
    </>
	);
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.primary.contrastText,
  padding: theme.spacing(3),
  'h3': {
    color: theme.palette.primary.main,
  },
  'p': {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  }
}));

function SummaryCard ({ title, count }: any) {
  return (
    <Grid item xs={12} md={6}>
        <Item elevation={3}>
          <Typography paragraph>{title}</Typography>
          <Typography variant="h3">{count}</Typography>
        </Item>
    </Grid>
  )
}