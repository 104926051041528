import { gql } from "@apollo/client";

export const ALL_LISTS_CATEGORIES = gql`
	query {
		getReviewStatuses {
			review_statuses {
				type
				label
			}
		}
		getParticipationCategories {
			participation_categories {
				type
				label
			}
		}
		getDocumentTypes {
			document_types {
				type
				label
			}
		}
		getScholarshipTypes {
			scholarship_types {
				type
				label
			}
		}
		getPronouns {
			pronouns {
				type
				label
			}
		}
		getUserCommittees {
			user_committees {
				type
				label
			}
		}
		getCountries {
			countries {
				code
				label
			}
		}
		getMajors {
			type
			label
		}
		getAcademicYears {
			academic_years {
				type
				label
			}
		}
	}
`;

export const DOCUMENT_TYPES = gql`
	query getDocumentTypes {
		getDocumentTypes {
			document_types {
				type
				label
			}
		}
	}
`;
