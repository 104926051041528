import { gql } from "@apollo/client";

export const GET_ROUTE_ACCESS = gql`
	query getAccessRights($crm_id: ID) {
		getAccessRights(crm_id: $crm_id) {
			id
			profile_filled_out
			route_permissions {
				# admin
				admin_permanent
				admin_bank
				admin_participation
				admin_settings
				admin_users
				admin_mll
				bank_no_access
				bank_allow_view
				graduated_out_of_program
			}
		}
		getSettingsCurrentAcademicYear {
			id
			academic_year
			academic_year_end
			academic_year_start
			event_participation_goal
			service_hours_goal
			status
		}
	}
`;

export default GET_ROUTE_ACCESS;
