import { gql } from "@apollo/client";

export const CREATE_USER = gql`
	mutation createUser(
		$crm_id: String!
		$ucla_id: String!
		$email: String!
		$first_name: String
		$last_name: String
		$major_degree: String
		$address_1: String
		$city: String
		$state: String
		$postal_code: String
		$country: String
		$phone: String
		$scholarship_type: String
		$scholarship_id: String
		$scholarship_name: String # $preferred_email: String
	) {
		createUser(
			crm_id: $crm_id
			ucla_id: $ucla_id
			email: $email
			first_name: $first_name
			last_name: $last_name
			major_degree: $major_degree
			address_1: $address_1
			city: $city
			state: $state
			postal_code: $postal_code
			country: $country
			phone: $phone
			scholarship_type: $scholarship_type
			scholarship_id: $scholarship_id
			scholarship_name: $scholarship_name # preferred_email: $preferred_email
		) {
			successful
			message
			error_fields
			inserted_id
		}
	}
`;

export const UPDATE_USER_BY_ADMIN = gql`
	mutation updateUserAdmin(
		$id: String!
		$ucla_id: String!
		$crm_id: String!
		$first_name: String!
		$last_name: String!
		$scholarship_type: String
		$user_committee: String
		$graduation_year: String
		$major_degree: String
		$minor_degree: String
		$birthday: String
		$pronoun: String
		$pronoun_other: String
		$phone: String
		$address_1: String
		$address_2: String
		$city: String
		$state: String
		$postal_code: String
		$country: String
		$permissions: String
		$email: String
		$preferred_email: String
	) {
		updateUserAdmin(
			id: $id
			ucla_id: $ucla_id
			crm_id: $crm_id
			first_name: $first_name
			last_name: $last_name
			scholarship_type: $scholarship_type
			user_committee: $user_committee
			graduation_year: $graduation_year
			major_degree: $major_degree
			minor_degree: $minor_degree
			birthday: $birthday
			pronoun: $pronoun
			pronoun_other: $pronoun_other
			phone: $phone
			address_1: $address_1
			address_2: $address_2
			city: $city
			state: $state
			postal_code: $postal_code
			country: $country
			permissions: $permissions
			email: $email
			preferred_email: $preferred_email
		) {
			successful
			message
			error_fields
		}
	}
`;

export const UPDATE_USER_BY_USER = gql`
	mutation updateUser(
		$id: String!
		$major_degree: String
		$minor_degree: String
		$birthday: String
		$pronoun: String
		$pronoun_other: String
		$phone: String
		$address_1: String
		$address_2: String
		$city: String
		$state: String
		$postal_code: String
		$country: String
		$preferred_email: String
		$confirm_accurate_data: Boolean
	) {
		updateUser(
			id: $id
			major_degree: $major_degree
			minor_degree: $minor_degree
			birthday: $birthday
			pronoun: $pronoun
			pronoun_other: $pronoun_other
			phone: $phone
			address_1: $address_1
			address_2: $address_2
			city: $city
			state: $state
			postal_code: $postal_code
			country: $country
			preferred_email: $preferred_email
			confirm_accurate_data: $confirm_accurate_data
		) {
			successful
			message
			error_fields
		}
	}
`;

export const DELETE_USER = gql`
	mutation deleteUser($id: ID!) {
		deleteUser(id: $id) {
			successful
			message
			error_fields
		}
	}
`;

export const BULK_IMPORT_USERS = gql`
	mutation importUsers($userList: [BulkImportUsers]!) {
		importUsers(userList: $userList) {
			successful
			message
			error_fields
			inserted_id
			email_responses {
				success
				msg
				email
				first_name
				last_name
				scholarship_type
				uid
			}
		}
	}
`;
