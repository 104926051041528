import { useState, useEffect } from "react"

import { DataGrid, GridColDef, GridToolbar, GridCellParams } from '@mui/x-data-grid';
import { Link } from "@mui/material";
import DataGridStyle from "../../../../components/container/DataGridStyle";

import { formatGQLDate } from '../../../../components/utilities/Formatters';
import { hasData } from '../../../../components/utilities/Validation'

import { GetAllUserParticipationStats } from '../helpers/LoadData'
import LoadingSpinner from "../../../../components/progress/Spinner";

import { v4 as uuidv4 } from 'uuid';

export function AllUserParticipationStats(props: any) {
  // const { participation_status } = props
  const participation_status = props.participation_status
  const setRefetchStatsQuery = props.setRefetchStatsQuery

  const [ tableData, setTableData ] = useState([]);
  const [ pageSize, setPageSize ] = useState<number>(25);
  const [ dgLoading, setDGLoading ] = useState();

  const participationStatsData: any = GetAllUserParticipationStats({setDGLoading: setDGLoading, participation_status: participation_status, setRefetchStatsQuery })

  //set datagrid data
  useEffect(() => {
    // const participations = participationStatsData
    if (hasData(participationStatsData)) {
      console.debug(`AllUserParticipationStats Component: `, participationStatsData)
      setTableData(participationStatsData);
    }
  }, [participationStatsData ])

  const columns: GridColDef[] = [
    { field: 'academic_year', headerName: 'Academic Year', width: 125, type: 'number', align:'center' }, //, renderCell: (params: any) => <> {params.row.academic_year ? `${params.row.academic_year}` : "-"}</> 
    { field: 'user_last_name', headerName: 'Last, First Name', width: 150, 
    renderCell: (params: any) =>
      <>
        <Link href={`/admin/user/participation/${params.row.userId}`} underline="none">{`${params.row.user_last_name}, ${params.row.user_first_name}`}</Link>
      </> 
    },

    // EVENTS
    { field: 'academic_year_events_goal', headerName: 'Events Goal', width: 100, type: 'number',  headerAlign: 'center', align:'center' },
    { field: 'participation_total_events_approved', headerName: 'Events Approved', width: 128, type: 'number',  headerAlign: 'center', align:'center' },
    { field: 'academic_year_events_goal_achieved', headerName: 'Met Event?', width: 125, headerAlign: 'center', align:'center' },
    { field: 'events_goal_approved_diff', headerName: 'Event Diff', width: 125, type: 'number',  headerAlign: 'center', align:'center', valueGetter: (params: any) =>
      params.row.participation_total_events_approved-params.row.academic_year_events_goal
    },
    // SERIVICE HOURS
    { field: 'academic_year_hours_goal', headerName: 'Hours Goal', width: 100, type: 'number',  headerAlign: 'center', align:'center' },
    { field: 'participation_total_hours_approved', headerName: 'Hours Approved', width: 128, type: 'number',  headerAlign: 'center', align:'center' },
    { field: 'academic_year_hours_goal_achieved', headerName: 'Met Hours?', width: 125, headerAlign: 'center', align:'center' },
    { field: 'hours_goal_approved_diff', headerName: 'Hrs Diff', width: 125, type: 'number',  headerAlign: 'center', align:'center', valueGetter: (params: any) =>
        params.row.participation_total_hours_approved-params.row.academic_year_hours_goal},
  ];

  return (
    <>
      { dgLoading && 
        <LoadingSpinner />
      }
      <DataGridStyle>
        <DataGrid 
          getRowId={(row: any) => uuidv4()}
          autoHeight={true}
          rows={tableData ?? []}
          columns={columns} 
          pageSize={pageSize}
          onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
          rowsPerPageOptions={[2, 5, 10, 25, 100]}                
          density="compact"
          components={{
              Toolbar: GridToolbar,
          }}
          componentsProps={{
            toolbar: {
              csvOptions:{
                fileName: `ASC-Student Participation Stats-${formatGQLDate({gQLDate: new Date().getTime(), format: "YYYYMMDD"})}`,
              }
            },
          }}
          initialState={{
            // sorting: {
            //     sortModel: [{ field: '', sort: 'asc' }],
            // },
            columns: {
              columnVisibilityModel: { }
            }
          }}
          getCellClassName={(params: GridCellParams<any>) => {
            // console.debug(params)
            if (params.field === "academic_year_hours_goal_achieved" || params.field === "academic_year_events_goal_achieved") {
              if (params.value.toLowerCase() === "no") {
                return 'data-cell-red ';
              } else if (params.value.toLowerCase() === "yes") {
                return 'data-cell-green';
              }
            }
            if (params.field === "events_goal_approved_diff" || params.field === "hours_goal_approved_diff") {
              if (params.value < 0 ) {
                return 'data-cell-red ';
              } else if (params.value > 0) {
                return 'data-cell-green';
              }
            }
            return ''
          }}
          // onRowClick={handleOnCellClick}
        />
      </DataGridStyle>
    </>
  )
}

export default AllUserParticipationStats