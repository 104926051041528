import React, { useContext, useState, useEffect } from 'react'
import { SettingsContext } from '../../../components/authorization/GetAccessRights';
// import { withPageState } from '../../../components/authorization/PageState'

import { useParams } from "react-router-dom"
import { useQuery, useMutation } from '@apollo/client';

import { getLoggedInUser } from "../../../components/authorization/Utilities"

import toast from 'react-hot-toast';

import { Box, Button, Divider, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,  } from "@mui/material";
import LinkButton from "../../../components/button/Button";

import { sortObjectsByKey } from "../../../components/utilities/Formatters"

import { handleFormDataChange } from '../../../components/utilities/Events'
import { formatFileSize } from '../../../components/utilities/Formatters'
import { hasData } from '../../../components/utilities/Validation'

import { getDocument, approveDocument, rejectDocument } from "../../../components/files/utilities/UploadDownload"

import { GET_ADMIN_COURSE_DOCUMENTS } from '../../../Graphql/Documents/QueryDocuments';
import { UPDATE_COURSE_DOCUMENT, DELETE_DOCUMENT } from '../../../Graphql/Documents/MutateDocuments';

import { GET_UCLA_CLASSES } from '../../../Graphql/UclaClasses/QueryUclaClasses';

const initFormData = {
  uclaClassesId: "",
  file_display_name: "",
}

const initSubmissionData = {
  id: "",
  submission_type: "",
  submitted_name: "",
  file_id_aws: "",
  file_display_name: "",
  file_path: "",
  file_type: "",
  file_size: "",
  professor_name: "",
  ucla_classes_class_name: "",
  term: "",
  academic_year: "",
  submission_status: "",
  user_first_name: "",
  user_last_name: "",
  created_by_id: ""
}


const DocumentReview = () => {
  // const adminPage = "admin_bank"
  const settings: any = useContext(SettingsContext)
  const loggedInUserId = getLoggedInUser(settings).id || null
  // const academicYearSettings = settings.academicYearSettings

  const params = useParams()
  const id: string = params.id ? params.id : ''

  const [open, setOpen] = useState(false);

  const { loading: loadingDocs, error: errorDocs, data: dataDocs } = useQuery(GET_ADMIN_COURSE_DOCUMENTS, {variables: { submission_id: id }})
  const { loading: loadingClasses, error: errorClasses, data: dataClasses } = useQuery(GET_UCLA_CLASSES)
  const [ updateDoc, { error: errorUpdateDoc, data: dataUpdateDoc } ] = useMutation(UPDATE_COURSE_DOCUMENT)
  const [ deleteSubmission, { error: errorDeleteSubmission, data: dataDeleteSubmission } ] = useMutation(DELETE_DOCUMENT, {variables: {id: id}})

  const [ submissionData, setSubmissionData ] = useState(initSubmissionData)
  const [ classesData, setClassesData ] = useState([])
  // const [ approveResponse, setApproveResponse] = useState()

  const [ formData, setFormData ] = useState(initFormData)

  const handleOpen = () => { 
    setOpen(true); 
  };
  
  function handleClose() {
    setOpen(false);
  }

  function handleAssignToClass() {
    console.debug('formData')
    console.debug(formData)
    
    if (!formData.uclaClassesId) {
      toast.error("Please select a Class to Assign this Document to");
    // } else if (formData.uclaClassesId || formData.file_display_name.trim()) {
    } else {
      let variables: any = {
        id: submissionData.id,
        submission_status: 'approved',
        submission_status_changed_by_id: loggedInUserId
      }
      // if (formData.uclaClassesId) {
        variables.uclaClassesId = formData.uclaClassesId
      // }
      if (formData.file_display_name.trim()) {
        variables.file_display_name = formData.file_display_name
      }

      console.debug('update doc: ', submissionData.submission_status, variables)


      // File that was approved prior, but is being updated. eg) Document assigned to a different class, or display name change 
      if (submissionData.submission_status === 'approved') {
        console.debug('Just update DB')
        updateDoc(
          {variables: variables}
        )
      }

      // Pending requires call to aws to move the file from staging to approved bucket
      if (submissionData.submission_status === 'pending') {
        console.debug('Move file in AWS, then update DB')
        approveDocument({schoolYear: submissionData.academic_year, fileName: submissionData.file_path, fileId: submissionData.file_id_aws})
        
        .then((data) => {
          console.log(data); // access the data resolved by the Promise here
          if (data.success && data.success === true) {
            console.debug('Successfully Approved - filed moved in AWS to approved bucket')
            // setApproveResponse(data)
            updateDoc(
              {variables: variables}
            )
          } else if (data.error) {
            toast.error(`Error moving file: ${data.error.message}`)
          }
        })
        .catch((error) => {
          console.error(error); // handle any errors that may have occurred
        });
      }
    }
  }

  function handleDeclineDelete() {
    console.debug('delete submission: ', submissionData.submission_status)
    rejectDocument({schoolYear: submissionData.academic_year, status:submissionData.submission_status, fileName: submissionData.file_path, fileId: submissionData.file_id_aws})
      .then((data) => {
        console.log(data); // access the data resolved by the Promise here
        if (data.success && data.success === true) {
          console.debug('Successfully Approved - filed moved in AWS to approved bucket')
          // setApproveResponse(data)
          deleteSubmission()
        } else if (data.error) {
          toast.error(`Error moving file: ${data.error.message}`)
        }
      })
      .catch((error) => {
        console.error(error); // handle any errors that may have occurred
      });
    handleClose()
  }

  //Delete
  useEffect(() => {
    if (errorDeleteSubmission) {
      console.debug('error', errorDeleteSubmission)
    }

    if (dataDeleteSubmission && hasData(dataDeleteSubmission) && dataDeleteSubmission.deleteCourseDocument.successful) {
      console.debug('successful', dataDeleteSubmission)
      window.location.href="/admin/bank"

    } else {
      console.debug('not successful', dataDeleteSubmission)
    }
  }, [errorDeleteSubmission, dataDeleteSubmission])

  // SET Data
  useEffect(() => {
    if (loadingDocs) {
      // console.debug(loadingDocs)
    }
    if (errorDocs) {
      console.debug(errorDocs)
    }
    if (dataDocs) {
      console.debug('document data: ', dataDocs)
      setSubmissionData(dataDocs.getCourseDocuments[0])
    }

  }, [loadingDocs, errorDocs, dataDocs])

  // SET Classes
  useEffect(() => {
    if (loadingClasses) {
      // console.debug(loadingClasses)
    }
    if (errorClasses) {
      console.debug(errorClasses)
    }
    if (dataClasses) {
      console.debug(dataClasses)
      const sortedClasses: any = sortObjectsByKey(dataClasses.getUclaClasses, "ucla_class_name", "asc")

      console.debug(sortedClasses)
      setClassesData(sortedClasses)
    }

  }, [loadingClasses, errorClasses, dataClasses])

  // UPDATE DOCUMENT
  useEffect(() => {

    if (errorUpdateDoc) {
      console.debug(errorUpdateDoc)
      toast.error('Error: ' + errorUpdateDoc)
    }
    if (dataUpdateDoc && !dataUpdateDoc.updateCourseDocument.successful) {
      console.debug(dataUpdateDoc.updateCourseDocument.message)
      toast.error('Error: ' + dataUpdateDoc.updateCourseDocument.message)
    }
    if (dataUpdateDoc && dataUpdateDoc.updateCourseDocument.successful) {
      console.debug(dataUpdateDoc)
      window.location.href="/admin/bank"
    }

  }, [errorUpdateDoc, dataUpdateDoc])

  //Approve Document
  // useEffect(() => {

  //   if (hasData(approveResponse)) {
  //     console.log("approveResponse", approveResponse)
  //     // if (approveResponse.uploadErrors.length > 0 ) {
  //       // toast.error('There was an issue uploading your document. This submission did not go through.')
  //     // }
  //     // if (approveResponse.uploadResponses.length > 0) {
  //       console.debug('Upload Successful!!!')
  //       console.debug('approveResponse: ', approveResponse)
        
  //     // }
  //   }

    
  // }, [approveResponse])
  // PageState({pageSection: "admin", adminAccess: adminPage})

	return (
		<>
      <Stack spacing={3}>
        <Box>
          <LinkButton to="../bank" variant="outlined" type="back">Back</LinkButton>
        </Box>
        <Typography variant="h2">Notes/Test Bank Management</Typography>
        <Typography variant="h3">Document Review</Typography>
        <Divider />
        <DataPair title="Document Type:" data={submissionData.submission_type} />
        <DataPair title="File Display Name:" data={submissionData.file_display_name} />
        <DataPair title="File Type:" data={submissionData.file_type} />
        <DataPair title="File Size:" data={formatFileSize(parseInt(submissionData.file_size))} />
        <DataPair title="Uploaded By:" data={`${submissionData.user_first_name} ${submissionData.user_last_name}`} />
        <DataPair title="Submitted Class and Professor:" data={`${submissionData.submitted_name}, ${submissionData.professor_name}`} />
        <DataPair title="Assigned Class:" data={`${submissionData.ucla_classes_class_name || ""}`} />
        <DataPair title="Term and Year:" data={`${submissionData.term}, ${submissionData.academic_year}`} />
        <DataPair title="Document Status:" data={submissionData.submission_status} />
        <Box>
          <Button variant="contained" onClick={() => {
            getDocument({schoolYear: submissionData.academic_year, fileName: submissionData.file_path, status:submissionData.submission_status, fileId:submissionData.file_id_aws})
            console.log('click file preview')
            }}>Preview File</Button>
        </Box>
        <Divider />
        <AssignClass id={id} setFormData={setFormData} formData={formData} classesData={classesData} />
        <DisplayName setFormData={setFormData} formData={formData} />
        <Stack direction="row" spacing={3} sx={{ pt: 1 }}>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" variant="h4" sx={{ width: 400, maxWidth: "70%" }}>
              Delete Document Submission?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This action cannot be undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={(event) => handleDeclineDelete() } autoFocus color="error">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          <Button variant="outlined" color="error" onClick={handleOpen}>Decline/Delete Document</Button>
          <Button variant="contained" onClick={handleAssignToClass}>Assign to Class</Button>
        </Stack>
      </Stack>
      <Box mt={5}>
        
      </Box>
      
    </>
	);
};

function DataPair({title, data}:any) {
  return (
    <Box>
      <Typography><strong>{title}</strong></Typography>
      <Typography>{data}</Typography>
    </Box>
  )
}

function AssignClass ({id, setFormData, formData, classesData}: any) {
// Steven, I'm testing the MUI Select with Formcontrol. LMK if this should be switched back to TextField
  return (
    <Stack direction="row" spacing={3} sx={{ pt: 1 }}>
      <FormControl sx={{ width: '100%', maxWidth: 400 }}>
        <InputLabel id="assign-class22">Assign Document to Class</InputLabel>
        <Select
          labelId="assign-class22"
          id="assign-class2"
          value={formData.uclaClassesId || ""}
          label="Assign Document to Class"
          onChange={(event) => { handleFormDataChange(setFormData, formData, "uclaClassesId", event.target.value) }}
        >
          <MenuItem></MenuItem>
          {classesData.map((option: any) => (
            <MenuItem key={option.id} value={option.id}>
              {option.ucla_class_name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
        If you do not see your class on this list, please add the Class to the list using the ‘Add New Class to List’ button.
        </FormHelperText>
      </FormControl>
      <LinkButton to={`../bank/new-class/${id}`} variant="outlined">Add New Class to List</LinkButton>
    </Stack>
  )
}

function DisplayName ({setFormData, formData}: any) {

  return (
    <Stack direction="row" spacing={3} sx={{ pt: 1 }}>
      <TextField
        id="display-name"
        label="Change File Display Name"
        value={formData.file_display_name || ""}
        onChange={(event) => { handleFormDataChange(setFormData, formData, "file_display_name", event.target.value) }}
        sx={{ width: '100%', maxWidth: 400 }}
        helperText="Please follow this name format: Physics 5A Final Exam and do NOT include the professors name or term information."
      />
    </Stack>
  )
}

// export default withPageState(DocumentReview, { pageSection: "admin", adminAccess: "admin_bank" })
export default DocumentReview