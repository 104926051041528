import { Box, Container, Link, Typography } from "@mui/material";

export const NotAnASCUser = ({message=''}) => {

  return (
    <>
      <Box 
        sx={{ 
          display: 'flex', 
          height: '75vh'
        }} 
        alignItems="center" 
        justifyContent="center"
      >
        <Container maxWidth="xs">
          <Typography variant="h2" align="center" mb={4}>Sorry, it appears you are not in the ASC database.</Typography>
          {message && 
            <Typography variant="h2" align="center" mb={4}>{message}</Typography>
          }
          <Typography variant="subtitle1" align="center">
            If you need assistance, please <Link href="mailto:asc@alumni.ucla.edu" underline="none" target="_blank">email us</Link>. 
          </Typography>
        </Container>
      </Box>
    </>
  );
}

export default NotAnASCUser;