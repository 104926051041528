import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQuery, useLazyQuery } from '@apollo/client'
import FileDropzone from './FileDropzone'
import { getDocument, uploadDocuments, approveDocument, rejectDocument } from "./util"

const successPrefix = "All files uploaded successfully:"
const emptyElem = <></>
const schoolYear = "2023"

// Note: whether or not the browser tries to download the file, or load it inline
//       can only be triggered by setting Content-Disposition on the file metadata in S3
// There may be an easier way but that's all I've found
// This means that after upload, a separate process step will need to be called to set the Content-Disposition on the file in S3
// If it's ok for the file to open inline then we don't need the separate process step

// links to metadata in S3:
// https://console.aws.amazon.com/s3/object/uclaea-asc-documents-sandbox?region=us-west-2&prefix=2023/file_download_example.pdf (Object Actions, Edit Metadata)
// https://console.aws.amazon.com/s3/object/uclaea-asc-documents-sandbox?region=us-west-2&prefix=2023/file_load_inline_example.pdf (Object Actions, Edit Metadata)
const exampleFileToDownload = {schoolYear, fileName: "file_download_example.pdf", fileId: '123456789', isDownload: true, isApproved: true}
const exampleFileToLoadInline = {schoolYear, fileName: "file_load_inline_example.pdf", fileId: '1111111111', isApproved: true}
const exampleStagingFileToLoadInline = {schoolYear, fileName: "staging_file_load_inline_example.pdf", fileId: '2222222222'}

// upload a file of this name to test approve or reject
const exampleFileToApproveOrReject = {schoolYear, fileName: "test.pdf", fileId: '123456789'}

function FileUploadDownloadDemo(props:any) {
  const [selectedFiles, setSelectedFiles] = useState([])
  const [dropzoneKey, setDropzoneKey] = useState(0)
  const [submitError, setSubmitError] = useState(emptyElem)
  const [submitSuccess, setSubmitSuccess] = useState(emptyElem)
  const [downloadError, setDownloadError] = useState("")
  const [approveRejectError, setApproveRejectError] = useState("")

  useEffect(() => {
    setApproveRejectError("")
    setDownloadError("")
    if (selectedFiles.length === 0) {
      setSubmitError(emptyElem)
    }
    else if (selectedFiles.length > 0) {
      setSubmitError(validateFilenames(selectedFiles))
      setSubmitSuccess(emptyElem)
    }
  }, [selectedFiles])

  const onClear = async (e:any) => {
    setSelectedFiles([])
    setDownloadError("")
    setApproveRejectError("")
    setDropzoneKey(dropzoneKey + 1) // hack because dropzone doesn't expose a way to reset itself
  }

  const onSubmit = async (e:any) => {
    e.preventDefault()
    // showLoading(true)

    const {uploadResponses, uploadErrors} = await uploadDocuments(schoolYear, selectedFiles)

    if (uploadErrors.length > 0) {
      setSubmitError(doUploadError(uploadErrors))
      // showLoading(false)
    }
    else {
      setSubmitSuccess(doSubmitSuccess(selectedFiles))
      setDropzoneKey(dropzoneKey + 1) // hack because dropzone doesn't expose a way to reset itself
      // showLoading(false)
    }
  }

  const downloadDocument = async (e:any, doc:any) => {
    e.preventDefault()
    // showLoading(true)

    const response = await getDocument(doc)
    console.log(response)
    if (response?.error) {
      setDownloadError("File download error: " + response.error.key + (response.error.msg ? " - " + response.error.msg : ""))
    }
    else {
      setDownloadError("")
    }
    // showLoading(false)
  }

  const doApprove = async (e:any, doc:any) => {
    e.preventDefault()
    // showLoading(true)
    setApproveRejectError("")

    const response = await approveDocument(doc)
    console.log(response)
    if (response?.error) {
      setApproveRejectError("Approve document error: " +  (response.error.key || response.error.code) + (response.error.message ? " - " + response.error.message : ""))
    }
    else {
      setApproveRejectError("")
      alert("Success approving document!")
    }
    // showLoading(false)
  }

  const doReject = async (e:any, doc:any) => {
    e.preventDefault()
    // showLoading(true)
    setApproveRejectError("")

    const response = await rejectDocument(doc)
    console.log(response)
    if (response?.error) {
      setApproveRejectError("Reject document error: " + (response.error.key || response.error.code) + (response.error.message ? " - " + response.error.message : ""))
    }
    else {
      setApproveRejectError("")
      alert("Success rejecting document!")
    }
    // showLoading(false)
  }

  const doSubmitSuccess = (files:any) => (
    <>
      <b>{successPrefix}</b>
      <ul>
        {files.map((file:any, index:number) => (<li key={index}>{file.name}</li>))}
      </ul>
    </>
  )

  const doUploadError = (uploadErrors:any) => (
    <>
      <p>There was an error uploading some of your files</p>
      <ul>
        {uploadErrors.map((error:any, index:number) => (
          <li key={index}>{error.fileName}: {error.res.statusText || error.res.key} {error.res.status} </li>
        ))}
      </ul>
    </>
  )

  const validateFilenames = (files:any) => {
    const errors:any = [];
    files.forEach((file:any) => {
      // just an example of filename validation
      if (file.name.indexOf('bad_filename') > -1) {
        errors.push({file, msg: "Bad filename!"})
      }
    })
    return (errors.length === 0) ? emptyElem : (
      <>
        <p>The following filenames were not in the proper format. Please rename the files and try again:</p>
        <ul>
          {errors.map((error:any, index:number) => (
            <li key={index}><b>{error.msg}: {error.file.name}</b></li>
          ))}
        </ul>
      </>
    )
  }

  return (
		<>
			<br/>&nbsp;<br/>
			<Typography variant="h2">ASC Upload/Download File Demonstration Page</Typography>
			<br/>
			<br/>&nbsp;<br/>

			<Typography variant="h3">&nbsp;Download Example</Typography>
      <br/>
      <div>&nbsp;&nbsp;<button onClick={e => downloadDocument(e, exampleFileToDownload)}>DOWNLOAD DOCUMENT EXAMPLE</button></div>
      <br/>
      <div>&nbsp;&nbsp;<button onClick={e => downloadDocument(e, exampleFileToLoadInline)}>OPEN DOCUMENT INLINE EXAMPLE</button></div>
      <br/>
      <div>&nbsp;&nbsp;<button onClick={e => downloadDocument(e, exampleStagingFileToLoadInline)}>PREVIEW DOCUMENT IN STAGING FOLDER</button></div>
      <br/>
      {downloadError &&
        <div style={{color:'red'}}>{downloadError}</div>
      }

      <br/>&nbsp;<br/>

			<Typography variant="h3">&nbsp;Upload Example</Typography>
	    <form onSubmit={onSubmit}>
	      <FileDropzone
	        key={'dropzone-' + dropzoneKey}
	        setSelectedFiles={setSelectedFiles}
	      />

        {submitSuccess &&
          <div>{submitSuccess}</div>
        }

        {submitError &&
	        <div style={{color:'red'}}>{submitError}</div>
	      }

        <center>
          <button type="submit" style={Object.assign({height:"50px"}, {"font-size":"24px"})} disabled={selectedFiles.length === 0 || submitError !== emptyElem}> <b>Upload</b> </button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button onClick={onClear} style={Object.assign({height:"50px"}, {"font-size":"24px"})} disabled={selectedFiles.length === 0 && submitError === emptyElem && submitSuccess === emptyElem}> <b>Clear</b> </button>
        </center>
      </form>
      <br/>&nbsp;<br/>

      <Typography variant="h3">&nbsp;Approve/Reject Documents Example (to test, upload a file named test.pdf)</Typography>
      <br/>
      <div>&nbsp;&nbsp;<button onClick={e => doApprove(e, exampleFileToApproveOrReject)}>APPROVE DOCUMENT EXAMPLE</button> (upload test.pdf, then wait a few seconds for S3 to catch up)</div>
      <br/>
      <div>&nbsp;&nbsp;<button onClick={e => doReject(e, exampleFileToApproveOrReject)}>REJECT DOCUMENT EXAMPLE</button> (won't throw error if file isn't found)</div>
      <br/>
      {approveRejectError &&
        <div style={{color:'red'}}>{approveRejectError}</div>
      }
      <br/>&nbsp;<br/>

			<div></div>
		</>
	);
}

export default FileUploadDownloadDemo;

