import { gql } from "@apollo/client";

export const GET_SETTINGS = gql`
	query getSettings($id: ID) {
		getSettings(id: $id) {
			id
			academic_year
			academic_year_end
			academic_year_start
			event_participation_goal
			service_hours_goal
			updated_on
			created_by_id
			created_on
		}
	}
`;

export const GET_ACADEMIC_YEAR_SETTINGS = gql`
	query getSettingsCurrentAcademicYear {
		getSettingsCurrentAcademicYear {
			id
			academic_year
			academic_year_end
			academic_year_start
			event_participation_goal
			service_hours_goal
			status
		}
	}
`;

export default GET_SETTINGS;
