import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useParams } from "react-router-dom"

import { withPageState } from '../../../components/authorization/PageState'

import { Box, Stack, Typography } from "@mui/material"
import LinkButton from "../../../components/button/Button";

import { AcademicYearForm } from "./partials/SettingsForm"

import { formatGQLDate } from '../../../components/utilities/Formatters'

import { GET_SETTINGS } from '../../../Graphql/Settings/QuerySettings';


const EditAcademicYear = () => {
  const params = useParams()
  const id: string = params.id ? params.id : ''

  // console.log('id: ' + id)
  
  const [ initFormData, setInitFormData ] = useState({})

  const { loading, error, data } = useQuery(GET_SETTINGS, {variables: {id: id}})

  useEffect(() => {
    if (data) {
      // console.log(data)
      var d = data.getSettings[0]

      const initData: any = {
        academic_year_start: formatGQLDate({gQLDate: d.academic_year_start, format: "YYYY-MM-DD"}),
        academic_year_end: formatGQLDate({gQLDate: d.academic_year_end, format: "YYYY-MM-DD"}),
        event_participation_goal: d.event_participation_goal,
        service_hours_goal: d.service_hours_goal
      }
      setInitFormData(initData)
    }
  }, [loading, error, data])

  return (
    <Stack spacing={3}>
      <Box>
        <Typography variant="h2">Edit Academic Year</Typography>
        <Typography>
          Use the form below to define an academic year for the Alumni Scholars Club.
<br />
          Set the start and end dates below to define the timeframe students are allowed to submit program participation. Set the goals below that align with this year’s scholarship renewal requirements.
        </Typography>
      </Box>
      <Box>
        <LinkButton to="/admin/settings" type="back" variant="outlined" reload>
          Return to Settings
        </LinkButton>
      </Box>
      <AcademicYearForm initFormData={initFormData} action="edit" id={id}/>
    </Stack>
  )
}

// export default withPageState(EditAcademicYear, { pageSection: "admin", adminAccess: "admin_settings" })
export default EditAcademicYear