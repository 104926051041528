import { gql } from "@apollo/client";

export const CREATE_PARTICIPATION = gql`
	mutation createParticipation(
		$userId: ID!
		$created_by_id: String
		$participation_category: String!
		$academic_year: String
		$name: String
		$description: String
		$participation_date: String
		$participation_hours: Float
		$sponsor_organization: String
		$sponsor_supervisor: String
		$sponsor_supervisor_contact_info: String # $participation_status: String # $participation_status_changed_on: String # $participation_status_changed_by_id: String
	) {
		createParticipation(
			userId: $userId
			created_by_id: $created_by_id
			participation_category: $participation_category
			academic_year: $academic_year
			name: $name
			description: $description
			participation_date: $participation_date
			participation_hours: $participation_hours
			sponsor_organization: $sponsor_organization
			sponsor_supervisor: $sponsor_supervisor
			sponsor_supervisor_contact_info: $sponsor_supervisor_contact_info # participation_status: $participation_status # participation_status_changed_on: $participation_status_changed_on # participation_status_changed_by_id: $participation_status_changed_by_id
		) {
			successful
			message
			error_fields
			inserted_id
		}
	}
`;

export const UPDATE_PARTICIPATION = gql`
	mutation updateParticipation(
		$id: ID!
		$participation_category: String!
		$academic_year: String
		$name: String
		$description: String
		$participation_date: String
		$participation_hours: Float
		$sponsor_organization: String
		$sponsor_supervisor: String
		$sponsor_supervisor_contact_info: String # $participation_status: String # $participation_status_changed_on: String # $participation_status_changed_by_id: String
	) {
		updateParticipation(
			id: $id
			participation_category: $participation_category
			academic_year: $academic_year
			name: $name
			description: $description
			participation_date: $participation_date
			participation_hours: $participation_hours
			sponsor_organization: $sponsor_organization
			sponsor_supervisor: $sponsor_supervisor
			sponsor_supervisor_contact_info: $sponsor_supervisor_contact_info # participation_status: $participation_status # participation_status_changed_on: $participation_status_changed_on # participation_status_changed_by_id: $participation_status_changed_by_id
		) {
			successful
			message
			error_fields
		}
	}
`;

export const UPDATE_PARTICIPATION_STATUS = gql`
	mutation updateParticipationStatus(
		$id: ID!
		$participation_status: String
	) {
		updateParticipationStatus(
			id: $id
			participation_status: $participation_status
		) {
			successful
			message
			error_fields
		}
	}
`;

export const BULK_UPDATE_PARTICIPATION_STATUS = gql`
	mutation bulkUpdateParticipationStatus(
		$participationList: String!
		$participation_status: String!
	) {
		bulkUpdateParticipationStatus(
			participationList: $participationList
			participation_status: $participation_status
		) {
			successful
			message
			error_fields
		}
	}
`;

export const DELETE_PARTICIPATION = gql`
	mutation deleteParticipation($id: ID!) {
		deleteParticipation(id: $id) {
			successful
			message
			error_fields
		}
	}
`;
