import React, { useContext, useEffect, useState }from 'react'
import { SettingsContext } from '../../components/authorization/GetAccessRights';
import { checkAdminAccess } from '../../components/authorization/PageState';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { NavLink } from "react-router-dom";
import { Stack } from "@mui/material";
import { styled } from '@mui/material/styles';
import { theme } from '../../themes/default';

import { hasData } from '../utilities/Validation'
import { initAccessRights } from '../authorization/Utilities'

const NavLinkStyled = styled(NavLink)({
  textDecoration: 'none',
  padding: '4px 0',
  color: theme.palette.primary.dark,
  fontWeight: 'bold',
  '&.active': {
    color: theme.palette.text.primary,
    cursor: 'pointer'
  }
});

const AdminNav = () => {
  const settingsContext: any = useContext(SettingsContext)
  const accessRights = settingsContext.accessRights ?? initAccessRights

  // console.debug('AdminNav', settingsContext, checkAdminAccess(settingsContext))
  if (checkAdminAccess(settingsContext) === false) {
    return (
      <></>
    )
  } 
  return (
    <Stack 
      spacing={2} 
      component="nav"
      sx={{ 
        px: 3,
        py: 6,
        width: 200,
        height: '100%',
        backgroundColor: theme.palette.info.light,
      }}
      aria-label="admin navigation"
    >
      <Box>
        <Typography variant='h3'>ASC Admin Control Panel</Typography>
      </Box>
      <Divider />
      {accessRights.admin &&
        <NavLinkStyled to="/admin">
          Admin Home 
        </NavLinkStyled>
      }
      {accessRights.admin_settings &&
        <NavLinkStyled to="/admin/settings">
          Settings 
        </NavLinkStyled>
      }
      {/* Leave user management open for now. can't modify access rights without it */}
      {accessRights.admin_users &&
        <NavLinkStyled to="/admin/users"> 
          User Management 
        </NavLinkStyled>
      }
      {(accessRights.admin_settings || accessRights.admin_users) &&
        <Divider />
      }
      { accessRights.admin_participation &&
        <NavLinkStyled to="/admin/participation">
          Bulk Participation Management
        </NavLinkStyled>
      }
      {/* <AllowAccess route="admin_bank"> */}
      { accessRights.admin_bank &&
        <NavLinkStyled to="/admin/bank">
          Notes/Test Bank Management
        </NavLinkStyled>
      }
      { accessRights.admin_mll &&
        <NavLinkStyled to="/admin/mll">
          MLL System
        </NavLinkStyled>
      }
    </Stack>
  )
}

export default AdminNav;