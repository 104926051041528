import React, { useState, useEffect, useContext } from 'react'
import { SettingsContext } from '../../../components/authorization/GetAccessRights';
// import { withPageState } from '../../../components/authorization/PageState'

import { useMutation } from "@apollo/client";

// import { getLoggedInUser } from "../../../components/authorization/Utilities"

import toast from "react-hot-toast";

import { Box, Divider, Stack, Typography, Button } from "@mui/material";

import { titleCase } from '../../../components/utilities/Formatters'

import { BulkParticipationSubmissionList } from "./partials/BulkParticipationSubmissionList";
import { AllUserParticipationStats } from "./partials/AllUserParticipationStats";
import { GET_USERS_PARTICIPATIONS, GET_ALL_USER_PARTICIPATION_STATS } from "../../../Graphql/Participations/QueryParticipations";
import { BULK_UPDATE_PARTICIPATION_STATUS } from "../../../Graphql/Participations/MutateParticipations";

const ParticipationManagement = () => {
  // const adminPage = "admin_participation"
  const settings: any = useContext(SettingsContext)
  // const loggedInUserId = getLoggedInUser(settings).id || null
  const academicYearSettings = settings.academicYearSettings

  const [ statusCount, setStatusCount ] = useState(0)
  const [ checkedParticipations, setCheckedParticipations ] = useState([])
  const [ participationStatus, setParticipationStatus] = useState("pending")
  const [ refetchStatsQuery, setRefetchStatsQuery] = useState(null);
  
  const [bulkUpdateParticipationStatus, { error: errorBulkUpdate, data: dataBulkUpdate } ] = useMutation( BULK_UPDATE_PARTICIPATION_STATUS,{
    refetchQueries: [{ query: GET_USERS_PARTICIPATIONS, variables: {participation_status: participationStatus} }, { query: GET_ALL_USER_PARTICIPATION_STATS }],
    awaitRefetchQueries: true,
  } );

  function handleClickQueryStatus(status: string) {
    console.debug("handleClickQueryStatus: ", status)
    setParticipationStatus(status)
    
  }

  function handleClickBulkStatusChange(status: string) {
    // console.debug('handleClickBulkStatusChange()', status, checkedParticipations)
    
    if (checkedParticipations.length === 0) {
      toast.error("Please select 1 or more records.")
      return false
    }
    const variables = {
      participationList: checkedParticipations.join(', '),
      participation_status: status
    }
    console.debug('handleClickBulkStatusChange() variables: ', variables)
    
    // setParticipationStatus(status) //Uncomment to apply participation status to query
    bulkUpdateParticipationStatus({ variables: variables })
  }
 
	useEffect(() => {
		let toastMsg = "";

		if (errorBulkUpdate) {
			toastMsg =
				"Error Attempting to Bulk Update Participation Statuses: " + errorBulkUpdate;
			toast.error(toastMsg);
			console.debug(toastMsg);

		} else if (dataBulkUpdate) {
			if (dataBulkUpdate.bulkUpdateParticipationStatus.successful) {
				toastMsg =
					"Successfully updated the Participation Approval Status";
				toast.success(toastMsg);
				console.debug(`Successfully Updated Approval Status: `, dataBulkUpdate);

			} else {
				toastMsg = dataBulkUpdate.bulkUpdateParticipationStatus.message;
				toast.error(toastMsg);
				console.debug(
					`error attempting to update the participation approval status`, dataBulkUpdate.bulkUpdateParticipationStatus
				);

			}
		}
	}, [errorBulkUpdate, dataBulkUpdate, refetchStatsQuery ]);

  
  return (
		<>
      <Stack spacing={3}>
        <Box>
          <Typography variant="h2">Bulk Program Participation Management</Typography>
          <Typography>View, modify and verify service hour submissions in bulk.</Typography>
        </Box>
        <Divider />
        <Typography variant="h3">Bulk Submission Management [ {statusCount} {participationStatus === 'all' ? 'Total' : titleCase(participationStatus)} ]</Typography>
      </Stack>
      <br/>
      <Stack spacing={2} direction="row">
        <Button variant="outlined" color="error"
              onClick={(event) => handleClickBulkStatusChange("denied")}
            >Bulk Deny</Button>
        
        <Button variant="contained"
            onClick={(event) => handleClickBulkStatusChange("approved")}>Bulk Approve</Button>
        <Button variant="outlined"
            onClick={(event) => handleClickBulkStatusChange("pending")}>Bulk Pending</Button>
      </Stack>
      <br/>
      <Stack spacing={2} direction="row">
        <Button variant="text"
              onClick={(event) => handleClickQueryStatus("pending")}
            >Pending</Button>
        <Button variant="text"
              onClick={(event) => handleClickQueryStatus("approved")}>Approved</Button>
        <Button variant="text"
              onClick={(event) => handleClickQueryStatus("denied")}>Denied</Button>
        <Button variant="text"
              onClick={(event) => handleClickQueryStatus("all")}>View ALL</Button>
      </Stack>
      <Box mt={3}>
        <BulkParticipationSubmissionList participation_status={participationStatus} setStatusCount={setStatusCount} setCheckedParticipations={setCheckedParticipations} academic_year_settings={academicYearSettings} />
      </Box>
      <br/>
      <Box mt={3}> 
        <Typography variant="h3">Student Participation Stats</Typography><br/>
        <AllUserParticipationStats participation_status={participationStatus} setRefetchStatsQuery={setRefetchStatsQuery}/>
      </Box>
    </>
	);
};

// export default withPageState(ParticipationManagement, { pageSection: "admin", adminAccess: "admin_participation" })
export default ParticipationManagement