import React, { useContext, useState, useEffect } from 'react'
import { SettingsContext } from '../../../components/authorization/GetAccessRights';
// import { withPageState } from '../../../components/authorization/PageState'

import { useParams } from "react-router-dom"
// import { getLoggedInUser } from "../../../components/authorization/Utilities"

import { hasData } from '../../../components/utilities/Validation'

import { Autocomplete, Box, Button, Divider, Link, Paper, Stack, TextField, Typography } from "@mui/material";

// import LoadingSpinner from '../../../components/progress/Spinner';
import LinkButton from "../../../components/button/Button";

import { formatAcademicYearRange } from '../../../components/utilities/Formatters';

import { GetUsersByParticipation } from '../participation/helpers/LoadData'
import UserParticipationLog from "./partials/UserParticipationLog";

const ParticipationProfile = (props: any) => {
  // const adminPage = "admin_participation"
  const settings: any = useContext(SettingsContext)
  // const loggedInUserId = getLoggedInUser(settings).id || null
  const academicYearSettings = settings.academicYearSettings
  
  // const allowAccess = settings.accessRights["admin_participation"]

  const event_goal: number = academicYearSettings.event_participation_goal ?? 0
  const service_goal: number = academicYearSettings.service_hours_goal ?? 0
  const academic_year = academicYearSettings.academic_year
  const academic_year_range = formatAcademicYearRange(academic_year)

  const params = useParams()
  const userId: string = params.id ? params.id : ''

  const [ user, setUser ] = useState<any>({})
  const [ participationStats, setParticipationStats ] = useState<any>({})

  const [ dgLoading, setDGLoading ] = useState();
  
  const response = GetUsersByParticipation({userId: userId, academic_year: academic_year, setDGLoading: setDGLoading})
  const userData: any = response.userData
  const refetchQuery: any = response.refetch

  useEffect(() => {
    if (hasData(userData)) {
      console.debug('ParticipationProfile() userData')
      console.log(userData)
    }
    if (userData.getUsersByParticipation && userData.getUsersByParticipation.length > 0) {
      setUser(userData.getUsersByParticipation[0]);
      setParticipationStats(userData.getUserParticipationStats)
    }
  }, [userData, setUser])

  return (
		<>
      <Stack spacing={5}>
        <LinkButton type="back" variant="outlined">Back</LinkButton>
        <Typography variant="h2">Individual’s Program Participation Profile:</Typography>
        <Paper elevation={3}>
          <Box p={3}>
            <Stack spacing={2}>
              <Typography>
                <strong>Name:</strong> {user.last_name}, {user.first_name} &nbsp;
                <small><Link href={`/admin/user/${user.id}`} underline="none">edit profile</Link></small>
              </Typography>
              <Typography><strong>Class:</strong> {user.graduation_year}</Typography>
              <Typography><strong>Email:</strong> {user.email}</Typography>
              <Typography><strong>ID:</strong> {user.id}</Typography>
              <Typography><strong>Event Participation Completed ({academic_year_range}):</strong> {participationStats.participation_total_events_approved ?? 0 } / {event_goal}</Typography>
              <Typography><strong>Service Hours Completed ({academic_year_range}):</strong> {participationStats.participation_total_hours_approved ?? 0 } / {service_goal}</Typography>
            </Stack>
          </Box>
        </Paper>
        <Divider />
        <Typography variant="h3">Individual Program Participation Log</Typography>
        <UserParticipationLog id={userId} data={user} dgLoading={dgLoading} refetchQuery={refetchQuery} />
        <Divider />
        <Box>
          <LinkButton to={`/admin/user/participation/new/${userId}`} variant="contained">
            Manually Add Participation For User
          </LinkButton>
        </Box>       
      </Stack>
    </>
	);
};

function ProgramReport () {
  return (
    <Stack spacing={2} sx={{ width: 450 }}>
      <Autocomplete
        disablePortal
        id="academic year"
        options={academicYears}
        renderInput={(params) => <TextField {...params} label="Generate Individual Report by Academic Year " />}
      />
      <Button variant="outlined">Generate Individual Program Participation Report</Button>
    </Stack>
  )
}

// Generate list of academic years
const academicYears = [
  { label: '2022-2023', year: '2022-2023' },
  { label: '2021-2022', year: '2021-2022' },
  { label: '2020-2021', year: '2020-2021' },
];

// export default withPageState(ParticipationProfile, { pageSection: "admin", adminAccess: "admin_participation" })
export default ParticipationProfile