import React from "react";
import { useEffect } from 'react'
import {useDropzone} from 'react-dropzone';

import { formatFileSize } from '../utilities/Formatters'
import { parseFileNameType } from '../utilities/Parsers'

const cssOuter:any = {
  padding: "16px",
  border: "1px solid rgb(232, 232, 232)",
  width: "100%",
  display: "inline-block",
  margin: "0px 0px 20px 0px",
}

const cssDropzone:any = {
  padding: "30px",
  textAlign: "center",
  border: "3px dashed #eeeeee",
  backgroundColor: "#fafafa",
  color: "#777",
  cursor: "pointer",
  marginBottom: "10px",
}

function FileDropzone(props:any) {
  const { setSelectedFile, selectedFile } = props

  // const [allFiles, setAllFiles] = useState([]) //if multiple files allowed

  const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false, //Can refactor component to accept a prop for this
    accept: {'application/msword': ['.docx'],'application/pdf': []} //Can refactor component to accept a prop for this
  });

  
  // Don't need to dedupe since only accepting one file. keep here if that changes
  // const addNonDupeFiles = (newAcceptedFiles:any) => (existingAllFiles:any) => {
  //   const existingFilenames = [...existingAllFiles].map((file:any) => file.name)
  //   return existingAllFiles.concat(newAcceptedFiles.filter((file:any) => existingFilenames.indexOf(file.name) === -1))
  // }

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      console.debug(acceptedFiles)
      setSelectedFile(acceptedFiles)
    }
    // setAllFiles(addNonDupeFiles(acceptedFiles)) //if multiple files allowed
  }, [acceptedFiles, setSelectedFile])

  const files = selectedFile.map((file: any, index: number) => {
    const fileParts = parseFileNameType(file.path)
    const fileName = fileParts.fileName
    const fileType = fileParts.fileType
    const fileSize = formatFileSize(file.size)
    return (
      <div key={fileName}>
        <div>
          <b><i>File Path:</i></b> {file.path}
        </div>
        <div>
          <b><i>File Name:</i></b> {fileName}
        </div>
        <div>
          <b><i>Type:</i></b> {fileType}
        </div>
        <div>
          <b><i>Size:</i></b> {fileSize}
        </div>
      </div>
    );
  });

  return (
    <div style={cssOuter}>
      <div {...getRootProps({style: cssDropzone}) }>
        <input {...getInputProps()} />
        <button type="button" onClick={open}>Select File On Your Device</button>
        <p>or Drag and drop files here</p>
        <p>Accepted Formats: .doc, .docx, .pdf</p>
        <em>Note: Only one file may be uploaded at a time</em>
      </div>
      {files.length > 0 &&
        <aside>
          <h4>Selected File:</h4>
          {files}
        </aside>
      }
    </div>
  );
}

export default FileDropzone