import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import {Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const LinearProgressStyled = styled(LinearProgress)({
  height: 24,
  borderRadius: 3
});

export function LinearProgressWithLabel(props: any) {
  
  const goalCompleted = props.goal ?? 0
  const currentValue = props.value ?? 0
  const percentValue = Math.round( (currentValue / goalCompleted) * 100 )
  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12} md={9}>
        <LinearProgressStyled variant="determinate" value={percentValue} color={props.color} />
      </Grid>
      <Grid item xs={12} md={3}>
        <Typography variant="h2" color="primary.contrastText">
          {currentValue}/{goalCompleted}
        </Typography>
      </Grid>
    </Grid>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
  color: PropTypes.string,
  goal: PropTypes.number.isRequired
};
