import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom"
import { useLazyQuery, useQuery } from '@apollo/client';

import { Box, Grid, MenuItem, Pagination, Paper, Stack, styled, TextField, Typography } from "@mui/material";

import { Link } from "react-router-dom";
import LayoutContainer from "../../../components/container/Container";

import { sortObjectsByKey } from "../../../components/utilities/Formatters"
import { handleFormDataChange } from '../../../components/utilities/Events'
import { nextOffset } from '../../../components/utilities/Pagination'

import { GET_COURSE_DOCUMENT_SUBMISSIONS } from '../../../Graphql/Documents/QueryDocuments';

var initClassCounts = [{
    notesCount: 0,
    syllabusCount: 0,
    testCount: 0,
    uclaClassesId: "",
    ucla_class_name: ""
}]

var initFormData = {
  sort: 'asc',
  searchBy: ''
}

const Submissions = ({access, pending}: any) => {
  
  const limit = 50 //Number of records to show by default
  const [ totalClassCategories, setTotalClassCategories ] = useState(0)
  const [ totalClassDocuments, setTotalClassDocuments ] = useState(0)
  const [ totalPages, setTotalPages ] = useState(0)
  const [ offset, setOffset ] = useState(0)
  const [ currentPage, setCurrentPage ] = useState(1)
  const [ formData, setFormData ] = useState(initFormData)

  const [ classCounts, setClassCounts ] = useState(initClassCounts)
  // const { loading: loadingClassCounts, error: errorClassCounts, data: dataClassCounts } = useQuery(GET_COURSE_DOCUMENT_SUBMISSIONS, {variables: { submission_status: 'approved'}})
  const [ getClassCounts, { loading: loadingClassCounts, error: errorClassCounts, data: dataClassCounts, fetchMore: fetchMoreSubmissions }] = useLazyQuery(GET_COURSE_DOCUMENT_SUBMISSIONS, {
    variables: { 
      submission_status: 'approved', 
      limit: limit,
      offset: offset,
      sort_direction: formData.sort,
      search_for: formData.searchBy
    },
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (access) {
      getClassCounts()
    }
  }, [access, getClassCounts])

  useEffect(() => {
    if (loadingClassCounts) {
      // console.debug('loading data')
    }
    if (errorClassCounts) {
      console.debug(errorClassCounts)
    }
    if (dataClassCounts) {
      console.debug('dataClassCounts:', dataClassCounts)
      // console.debug(formData)
      // Remove this sort - refetch
      if (dataClassCounts?.getCourseSubmissions.courseDocumentCounts.length > 0) {
        // setClassCounts(sortObjectsByKey(dataClassCounts.getCourseSubmissions.courseDocumentCounts, 'ucla_class_name', formData.sort))
        setCurrentPage(1)
        setClassCounts(dataClassCounts.getCourseSubmissions.courseDocumentCounts)
      }
      setTotalClassCategories(dataClassCounts.getCourseSubmissions.totalClassCategories)
      setTotalClassDocuments(dataClassCounts.getCourseSubmissions.totalClassDocuments)
      setTotalPages(dataClassCounts.getCourseSubmissions.totalPages)
    }

  }, [loadingClassCounts, errorClassCounts, dataClassCounts, formData])

  useEffect(() => {

  }, [])

  function handlePagination(page: number) {
    const newOffset = nextOffset(limit, page, offset)
    console.debug('page clicked', page)
    console.debug('new offset', newOffset)
    
    setCurrentPage(page)

    fetchMoreSubmissions({
      variables: {
        offset: newOffset,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        console.debug('prev', prev)
        console.debug('fetchMoreResult', fetchMoreResult)
        setClassCounts(fetchMoreResult.getCourseSubmissions.courseDocumentCounts)
      },
    });
  }

  const submissionCards = classCounts.map((course: any, index: number) => {
    let totalCount = course.notesCount + course.syllabusCount + course.testCount
      return <SubmissionCard key={`sc${index}`} courseId={course.uclaClassesId} title={course.ucla_class_name} count={totalCount} />
  });

  if ((!access && !pending) || (!access && pending)) {
    return <></>
  }

  return (
    <>
      {/* does the student have approved sumbissions? */}
      {/* {access === 'approved' &&  */}
        <LayoutContainer background="light">
          <SearchFilter setFormData={setFormData} formData={formData}/>
          <Typography>Total Class Categories: {totalClassCategories}</Typography>
          <Typography>Total Documents: {totalClassDocuments}</Typography>
          {totalClassCategories > 0 &&
            <>
              <Grid container spacing={3} mt={1}>
                {submissionCards}
              </Grid>
                <Box my={5}>
                  <Pagination count={totalPages} page={currentPage} onChange={(event, page) => {
                    handlePagination(page)
                  }} />
                </Box>
              </>
            }
        </LayoutContainer>
      {/* } */}
    </>
  )
}

export default Submissions

const filters = [
  { value: 'asc', label: 'A to Z', },
  { value: 'desc', label: 'Z to A', },
];

function SearchFilter ({setFormData, formData}: any) {
  return (
    <Grid container spacing={3}>
      <Grid item md={8}>
        <TextField
          id="search-cards"
          fullWidth
          label="Search"
          placeholder="Enter a class category..."
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => { handleFormDataChange(setFormData, formData, "searchBy", event.target.value) }}
        />
      </Grid>
      <Grid item md={4}>
        <TextField
          id="sort-cards"
          fullWidth
          select
          label="Sort"
          defaultValue="asc"
          onChange={(event) => { handleFormDataChange(setFormData, formData, "sort", event.target.value) }}
        >
          {filters.map((option, index) => (
            <MenuItem key={`sort${index}`} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  )
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.primary.contrastText,
  padding: theme.spacing(3),
  borderSize: 2,
  borderStyle: 'solid',
  borderColor: theme.palette.primary.contrastText,
  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
  'h4': {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1.5)
  }
}));

function SubmissionCard ({ courseId, title, count }: any) {
  return (
    <Grid item xs={12} md={4}>
      <Link to={`/bank/class/${courseId}`} style={{ textDecoration: 'none' }}>
        <Item elevation={3}>
          <Typography variant="h4">{title}</Typography>
          <Typography>{count} Items Available</Typography>
        </Item>
      </Link>
    </Grid>
  )
}