
/// UPLOAD ///
const getPreSignedUrlForUpload = async (doc:any) => {
  return await doApi({route: `getuploadurl/${doc.schoolYear}?fn=${doc.fileName}&fileId=${doc.fileId}&contentType=${doc.contentType}`});
};

const getUploadUrl = async (doc:any) => {
  const response = await getPreSignedUrlForUpload(doc)
  console.log({response, doc})

  if (response.error)
    return response
  else if (!response || !response.presignedUrl)
    return {error: {key:'PRESIGNED_URL_FAILED', response}}
  else
    return response
};

const uploadToS3 = async (file:any, contentType:any, presignedUrl:string) => {
  const fetchData = {
    method: 'PUT',
    headers: {
      'Content-Type': contentType,
    },
    body: file
  }

  try {
    const response = await fetch(presignedUrl, fetchData);
    return response;
  }
  catch (e) {
    console.log(e)
  }
}

const dummyFileId = '123456789';
export const uploadDocuments = async (schoolYear:string, selectedFiles:any) => {
  const uploadResponses = [];
  const uploadErrors = [];
  for (const file of (selectedFiles as any[])) {

    const presignedUrlResponse = await getUploadUrl({schoolYear, fileName:file.name, fileId:dummyFileId, contentType:file.type})

    if (presignedUrlResponse.error) {
      uploadErrors.push({ fileName: file.name, res: presignedUrlResponse.error })
    }
    else {
      const response = await uploadToS3(file, file.type, presignedUrlResponse.presignedUrl)
      if (response && response.ok && response.status === 200)
        uploadResponses.push(response)
      else
        uploadErrors.push({ fileName: file.name, res: (response || {statusCode:'', statusText:'No response'}) })
    }
  }

  return {uploadResponses, uploadErrors};
}


/// DOWNLOAD ///
export const getDocument = async (doc:any) => {
  const staging = doc.isApproved ? "" : "&staging=true"
  const response = await doApi({route: `getdownloadurl/${doc.schoolYear}?fn=${doc.fileName}&fileId=${doc.fileId}${staging}`});
  console.log({response, doc})

  if (response.error)
    return response
  else if (!response || !response.presignedUrl)
    return {error: {key:'PRESIGNED_URL_FAILED', response}}

  const a = document.createElement('a');
  a.href = response.presignedUrl;
  if (!doc.isDownload) a.target = "_blank";
  a.download = doc.fileName;
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove();
};


/// APPROVE/REJECT ///
export const approveDocument = async (doc:any) => {
  const response = await doApi({route: `approvedocument/${doc.schoolYear}?fn=${doc.fileName}&fileId=${doc.fileId}`});
  console.log({response, doc})
  return response
};

export const rejectDocument = async (doc:any) => {
  const response = await doApi({route: `rejectdocument/${doc.schoolYear}?fn=${doc.fileName}&fileId=${doc.fileId}`});
  console.log({response, doc})
  return response
};



/// API ///
const doApi = async (options:any) => {
  console.log('doApi!!!', options);

  let fetchUrl = (process.env.REACT_APP_GATEWAY_HOST || "dev") + options.route;

  const fetchData = {
    method: options.method || "GET",
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem("uclaeaidmtoken") || '',
    },
    body: options.data ? JSON.stringify(options.data) : null
  }

  try {
    const response = await fetch(fetchUrl, fetchData);
    const data = await response.json();
    return data;
  }
  catch (e:any) {
    if (options.noError) return;

    console.log("ERROR - Failed to ' + fetchData.method + ' ' + route");
    console.log(e);
    return {
      error: {
        key:e.key || 'CALL_FAILED',
        msg:e.message || e,
      }
    };
  }
};

