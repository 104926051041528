import { Alert, Box, Container, Link, Typography } from "@mui/material";
import { useRouteError } from "react-router-dom";
import ExternalNav from "../../components/navigation/ExternalNav";

export default function ErrorPage({hideNav=false}) {
  const error: any = useRouteError();
  console.error(error);

  return (
    <>
      { hideNav !== true &&
        <ExternalNav />
      }
      <Box 
        sx={{ 
          display: 'flex', 
          height: '100vh'
        }} 
        alignItems="center" 
        justifyContent="center"
      >
        <Container maxWidth="xs">
          <Typography variant="h2" align="center">Oops! An unexpected error has occurred.</Typography>
          <Box my={4}>
            <Alert severity="warning">
              <Typography paragraph m={0} sx={{ lineHeight: 1.5 }}>
                <strong>{error.statusText || error.message}</strong>
              </Typography>
            </Alert>
          </Box>
          
          <Typography variant="subtitle1" align="center">
            If you need assistance, please <Link href="mailto:asc@alumni.ucla.edu" underline="none" target="_blank">email us</Link>. 
          </Typography>
        </Container>
      </Box>
    </>
  );
}