import { Autocomplete, TextField } from "@mui/material"
import { handleFormDataChange } from '../../components/utilities/Events'

export const DropDownTypes = {
  GraduationYear: 'graduation_year',
  Major: 'major',
  Minor: 'minor',
  Scholarship: 'scholarship',
  Pronoun: 'pronoun',
  Country: 'country',
  Committee: 'committee',
}

export const Dropdown = ({ type, options, setFormData, formData }: any) => {
  // console.log('Dropdown component options: ' +  typeof options)
  // console.log(options)
  var id = ''
  var label = ''

  if (type === 'scholarship') {
    id = 'scholarship_type'
    label = 'Scholarship Type'

  } else if (type === 'pronoun') {
    id = 'pronoun'
    label = 'Preferred Pronouns'

  } else if (type === 'country') {
    id = 'country'
    label = 'Country'

  } else if (type === 'major') {
    id = 'major_degree'
    label = 'Primary Major'

  } else if (type === 'minor') {
    id = 'minor_degree'
    label = 'Primary Minor'

  } else if (type === 'committee') {
    id = 'user_committee'
    label = 'User Committee'

  } else if (type === 'graduation_year') {
    id = 'graduation_year'
    label = 'Graduation Year'
    
  }


  if (Object.keys(options).length === 0) {
    return <></>
  }
	return <Autocomplete
    disablePortal
    value={ formData[id] ? {label: formData[id] } : null }
    id={id}
    options={options}
    isOptionEqualToValue={(option, value) => option.label === value.label}
    sx={{ width: 300 }}
    renderInput={(params) => <TextField {...params} label={label} />}
    onChange={(event, value: any) => { handleFormDataChange(setFormData, formData, id, value.label) }}
  />
};



export default Dropdown