import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";

import {
	GET_ONE_USER,
	GET_ONE_USER_NON_ADMIN,
} from "../../../../Graphql/Users/QueryUser";
import {
	GET_USERS_PARTICIPATIONS,
	GET_USERS_FILTERED_PARTICIPATIONS,
	GET_ALL_USER_PARTICIPATION_STATS,
} from "../../../../Graphql/Participations/QueryParticipations";
import {
	UPDATE_PARTICIPATION_STATUS,
	DELETE_PARTICIPATION,
} from "../../../../Graphql/Participations/MutateParticipations";

export function UpdateParticipationStatus(props: any) {
	const userId: string = props.userId ?? "";
	const redirectUrl: string = props.redirectUrl ?? "";
	const goBackHistory: number = props.goBackHistory ?? 0; //represents the numeric index to go back in browser history
	const refetchDataFrom: string = props.refetchData ?? "";
	const setDGLoading: any = props.setDGLoading ?? "";

	const refetchQuery: any = props.refetchQuery ?? null; //this is using the refetch method returned by useQuery or useLazyQuery. Different Method from the Mutation refetchQueries to refresh another query

	const [refetchQueries, setRefetchQueries] = useState([]);

	// let queryFrom = GET_ONE_USER;

	useEffect(() => {
		let tempRefetchQueries: any = [];
		let refetchVariables: any = {};

		let queryFrom = GET_USERS_FILTERED_PARTICIPATIONS;

		if (refetchDataFrom === "participations") {
			queryFrom = GET_USERS_PARTICIPATIONS;
		}

		if (refetchDataFrom) {
			if (userId) {
				refetchVariables["id"] = userId;
			}
			tempRefetchQueries.push({
				query: queryFrom,
				variables: refetchVariables,
			});
		}

		if (refetchDataFrom === "participations") {
			console.debug("refetchDataFrom participations");
			tempRefetchQueries.push({
				query: GET_ALL_USER_PARTICIPATION_STATS,
				// variables: refetchVariables,
			});
		}

		setRefetchQueries(tempRefetchQueries);
	}, [refetchDataFrom, userId]);

	const [
		updateParticipationStatus,
		{
			loading: loadingUpdateApproval,
			error: errorUpdateApproval,
			data: dataUpdateApproval,
		},
	] = useMutation(UPDATE_PARTICIPATION_STATUS, {
		refetchQueries: refetchQueries,
	});

	useEffect(() => {
		let toastMsg = "";
		if (setDGLoading) {
			setDGLoading(loadingUpdateApproval);
		}

		if (errorUpdateApproval) {
			toastMsg =
				"Error Attempting to Update the Participation Approval Status";
			toast.error(toastMsg);
			console.debug(toastMsg, errorUpdateApproval);
		}

		if (dataUpdateApproval) {
			if (dataUpdateApproval.updateParticipationStatus.successful) {
				if (refetchQuery) {
					console.debug("Refetch Query");
					refetchQuery();
				}
				toastMsg =
					"Successfully updated the Participation Approval Status";
				toast.success(toastMsg);
				console.debug(`Successfully Updated Approval Status`);
				console.debug(dataUpdateApproval);

				if (goBackHistory < 0) {
					window.history.go(goBackHistory);
				} else if (redirectUrl) {
					window.location.href = redirectUrl;
				}
			} else {
				toastMsg = dataUpdateApproval.updateParticipationStatus.message;
				toast.error(toastMsg);
				console.debug(
					`error attempting to update the participation approval status`
				);
				console.debug(dataUpdateApproval.updateParticipationStatus);
			}
		}
	}, [
		loadingUpdateApproval,
		errorUpdateApproval,
		dataUpdateApproval,
		goBackHistory,
		redirectUrl,
		setDGLoading,
		refetchQuery,
	]);

	//Handle approval click event
	function handleClick(
		participationId: string,
		approvalStatus: string,
		refetch: any = null
	) {
		console.debug("handleClick: ", props, participationId, approvalStatus);

		updateParticipationStatus({
			variables: {
				id: participationId,
				participation_status: approvalStatus,
			},
		});
	}

	return handleClick;
}

export function DeleteParticipation(props: any) {
	const userId: string = props.userId ? props.userId : "";
	const refetchData: string = props.refetchData ? props.refetchData : "";
	const redirectUrl: string = props.redirectUrl ? props.redirectUrl : "";
	const handleDialog = props.handleDialog ? props.handleDialog : () => {};
	const academicYear: string = props.academicYear ?? "";

	let queryFrom =
		refetchData === "user" ? GET_ONE_USER_NON_ADMIN : GET_ONE_USER;
	let refetchQuery = {};
	let refetchQueries = [];
	let refetchVariables: any = {};

	if (refetchData === "user") {
		if (userId) {
			refetchVariables["id"] = userId;
			refetchVariables["academic_year"] = academicYear;
		}
		refetchQueries.push({
			query: queryFrom,
			variables: refetchVariables,
		});

		refetchQuery = { refetchQueries: refetchQueries };
	}

	const [
		deleteParticipation,
		{ error: errorDeleteParticipation, data: dataDeleteParticipation },
	] = useMutation(DELETE_PARTICIPATION, refetchQuery);

	// console.log("handleDeleteParticipationClicked");

	let toastMsg = "";

	function handleClick(participation_id: String, dialogClose = () => {}) {
		if (participation_id) {
			deleteParticipation({
				variables: {
					id: participation_id,
				},
			});
		} else {
			toastMsg = "Please supply the correct parameter";
			console.debug(toastMsg);
			toast.error(toastMsg);
		}
	}

	useEffect(() => {
		let toastMsg: string = "";

		if (errorDeleteParticipation) {
			console.debug("errorDeleteParticipation");
			console.debug(errorDeleteParticipation);
			toastMsg =
				"Error Attempting to Delete Participation: " +
				errorDeleteParticipation;
			toast.error(toastMsg);
		} else if (dataDeleteParticipation) {
			if (dataDeleteParticipation.deleteParticipation.successful) {
				toastMsg = "Successfully Deleted Participation!";
				toast.success(toastMsg);

				handleDialog();

				if (redirectUrl) {
					window.location.href = redirectUrl;
				}
			} else {
				toastMsg = dataDeleteParticipation.deleteParticipation.message;
				console.debug(toastMsg);
				toast.error(toastMsg);
			}
		}
		if (toastMsg) {
			console.log(`Toast Message: ${toastMsg}`);
		}
	}, [errorDeleteParticipation, dataDeleteParticipation]);

	return handleClick;
}
