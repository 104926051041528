import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, styled } from '@mui/material';

const Overlay = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backdropFilter: "blur(2.5px)",
  backgroundColor: 'rgba(0,0,0,0.4)',
  padding: 32,
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  zIndex: 100
}));

const LoadingSpinner = () => {
  console.debug("Loading Spinner...")
  return (
    <Overlay sx={{  }}>
      <CircularProgress
      size={100}
      thickness={4}
      />
    </Overlay>
  )
}

export default LoadingSpinner;