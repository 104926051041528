import React, { ReactNode, useContext } from 'react'
import { useLocation } from "react-router-dom";
import { SettingsContext } from '../../components/authorization/GetAccessRights'
import { NoAccessPage } from '../../pages/error/NoAccess'

import { localStorageLoggedInUser } from "../../components/authorization/Utilities"
import { hasData } from '../../components/utilities/Validation'

export const PageState = (props: any) => {
  const settings: any = useContext(SettingsContext)
  const lsLoggedInUser = localStorageLoggedInUser();
  const pageSection = props.pageSection ?? ""
  const adminAccess = props.adminAccess ?? ""
  const children = props.children ?? ""
  const location = useLocation()
  let profileFilled = hasData(settings.accessRights) ? settings.profileFilled : false

  // console.debug("PageState location: ", location)
  if (profileFilled === false) {
    profileFilled = lsLoggedInUser ? lsLoggedInUser.profile_filled_out : false
  }

  
  if (settings.isLoading || profileFilled === false || settings.profileFilled === false) {
    if(['/profile'].indexOf(location.pathname) > -1) {
      // console.debug("1 - PageState Render Children")
      return children
    }
    // console.debug("1 - PageState return empty")
    return <></>
  } 

  if (settings.isLoading === false) {
    console.debug('PageState: ', pageSection, adminAccess, lsLoggedInUser, profileFilled, location, settings)
  }
  // -------------------------------------
  // Handle Graduated Out Scenarios
  // -------------------------------------
  const graduatedOutMsg = "Sorry, you've graduated out of the program. If this is a mistake please contact the program administrator."
  
  // User Graduated Out and Has Non-Expiring User Rights => Allow Admin Access but no access to other non-admin pages
  if (settings.accessRights.graduated_out_of_program === true && settings.accessRights.admin_permanent === false) {
    return <NoAccessPage message={graduatedOutMsg} />
  }

  // User Graduated Out and Has Non-Expiring User Rights => Allow Admin Access but no access to other non-admin pages  
  if ((settings.accessRights.graduated_out_of_program === true && settings.accessRights.admin_permanent === true && pageSection !== "admin")) {
    return <NoAccessPage message={graduatedOutMsg} />
  }

  // -------------------------------------
  // Handle Admin Access
  // -------------------------------------
  if (pageSection === 'admin') {
    let allowAccess = false
    if (adminAccess === "" || adminAccess === 'admin') {
      allowAccess = checkAdminAccess(settings)
    } else {
      allowAccess = settings.accessRights[adminAccess]
    }  
    if (allowAccess === false) { //Do Not use !allowAccess. There is a moment where allowAccess === null, which will cause a flash of NoAccess 
      return <NoAccessPage />
    }
  }



  if (children) {
    // console.debug("PageState Render Children")
    return children
  }
  // console.debug("2 - PageState return empty")
  return <></>
}

// -------------------------------------
  // Helper Functions
  // -------------------------------------
export const checkAdminAccess = (settings: any) => {
  // console.debug('checkAdminAccess()')
  if (settings.isLoading || !settings.profileFilled || settings.profileFilled === false) {
    // console.debug('CheckAdminAccess', false)
    return false
  } 
  
  if (!settings.isLoading) {
    const obj = settings.accessRights
    if ((obj.graduated_out_of_program === true || obj.graduated_out_of_program === null) && obj.admin_permanent === false) {
      return false
    }

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (key.includes("admin") && obj[key] === true) {
          // console.debug('checkAdminAccess', true)
          return true
        }
      }
    }
  }
  // console.debug('checkAdminAccess', false)
  return false
  
}

export const withPageState = (Component: any, options: any) => (props: any) =>
  (
    <PageState {...options}>
      <Component {...props} />
    </PageState>
  );

export default PageState;