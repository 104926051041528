import { gql } from "@apollo/client";

export const GET_UCLA_FACULTY = gql`
	query getFacultyList($faculty_name: String) {
		getFacultyList(faculty_name: $faculty_name) {
			id
			ucla_faculty_id
			full_name
			first_name
			last_name
		}
	}
`;
