import { gql } from "@apollo/client";

export const GET_ADMIN_STATS = gql`
	query getAdminStats($academic_year: String) {
		getAdminStats(academic_year: $academic_year) {
			active_users
			pending_events
			pending_services
			pending_service_hours
		}
	}
`;

export default GET_ADMIN_STATS;
