import React, { useContext, useState, useEffect } from "react";
import { SettingsContext } from '../../components/authorization/GetAccessRights';

import { useParams } from "react-router-dom"

import { Typography } from "@mui/material";
import LayoutContainer from "../../components/container/Container";

import { GetUsersByParticipation } from '../admin/participation/helpers/LoadData'

import { ParticipationForm } from "../../components/forms/ParticipationForm";

import { hasData } from '../../components/utilities/Validation'
import AcademicYearClosed from "../../components/authorization/AcademicYearClosed";

const EditParticipation = () => {
  const settings: any = useContext(SettingsContext)
  const params = useParams()
  const id: string = params.id ? params.id : ''
  const academicYearSettings = settings.academicYearSettings

  console.debug('query param id:', id)
  

  const [ data, setData ] = useState<any>({})
  const [ participationData, setParticipation ] = useState<any>({})
  const [ userId, setUserId ] = useState("")
  const [ dgLoading, setDGLoading ] = useState();

  const response: any = GetUsersByParticipation({ participation_id: id})
  const userData: any = response.userData
  
  useEffect(() => {
    if (userData) {
      console.debug('EditParticipation.tsx userData')
      console.debug(userData)
      setData(userData);
      if (userData.getUsersByParticipation && userData.getUsersByParticipation.length > 0){
        setUserId(userData.getUsersByParticipation[0].id)
        setParticipation(userData.getUsersByParticipation[0].participations[0])
      } 
    }
  }, [userData, setData, setParticipation])

  if (academicYearSettings.status === 'closed') {
    return <AcademicYearClosed section="Program Participation Submission"/>
  }
	return (
		<>
      <LayoutContainer>
        <Typography variant="h2">Edit Program Participation</Typography>
        { hasData(participationData) &&
          <ParticipationForm userId={userId} participationId={id} data={data} participationData={participationData} action="edit"/>
        }
      </LayoutContainer>
    </>
	);
};

// export default withPageState(EditParticipation, {})
export default EditParticipation
