import { Box, Divider, Stack, Typography } from "@mui/material";
import LinkButton from "../../../components/button/Button";

// import { withPageState } from '../../../components/authorization/PageState'

const ParticipationProfiles = () => {

  return (
		<>
      <Stack spacing={3}>
        <Typography variant="h2">Individual Program Participation Profiles</Typography>
        <Typography>Search for program participation details by individual user.</Typography>
        <LinkButton to="/admin/participation" variant="outlined">Switch to Bulk Submissions View</LinkButton>
        <Divider />
        <Typography variant="h3">Individual Participation Profiles</Typography>
      </Stack>
      <Box mt={3}>
        <Typography>[profiles table here]</Typography>
      </Box>
    </>
	);
};

// export default withPageState(ParticipationProfiles, { pageSection: "admin", adminAccess: "admin_participation" })
export default ParticipationProfiles
