import { doApi } from "../../utilities/Api";

/// UPLOAD ///
const getPreSignedUrlForUpload = async (doc: any) => {
	return await doApi({
		route: `getuploadurl/${doc.schoolYear}?fn=${doc.fileName}&fileId=${doc.fileId}&contentType=${doc.contentType}`,
	});
};

const getUploadUrl = async (doc: any) => {
	const response = await getPreSignedUrlForUpload(doc);
	console.log({ response, doc });

	if (response.error) return response;
	else if (!response || !response.presignedUrl)
		return { error: { key: "PRESIGNED_URL_FAILED", response } };
	else return response;
};

const uploadToS3 = async (
	file: any,
	contentType: any,
	presignedUrl: string
) => {
	const fetchData = {
		method: "PUT",
		headers: {
			"Content-Type": contentType,
		},
		body: file,
	};

	try {
		const response = await fetch(presignedUrl, fetchData);
		return response;
	} catch (e) {
		console.log(e);
	}
};

export const uploadDocuments = async (
	schoolYear: string,
	selectedFiles: any,
	fileId: string = "123456789"
) => {
	const uploadResponses = [];
	const uploadErrors = [];
	for (const file of selectedFiles as any[]) {
		const presignedUrlResponse = await getUploadUrl({
			schoolYear: schoolYear,
			fileName: file.name,
			fileId: fileId,
			contentType: file.type,
		});

		if (presignedUrlResponse.error) {
			uploadErrors.push({
				fileName: file.name,
				res: presignedUrlResponse.error,
			});
		} else {
			const response = await uploadToS3(
				file,
				file.type,
				presignedUrlResponse.presignedUrl
			);
			if (response && response.ok && response.status === 200)
				uploadResponses.push(response);
			else
				uploadErrors.push({
					fileName: file.name,
					res: response || {
						statusCode: "",
						statusText: "No response",
					},
				});
		}
	}

	return { uploadResponses, uploadErrors };
};

/// DOWNLOAD ///
export const getDocument = async (doc: any) => {
	const staging = doc.status === "approved" ? "" : "&staging=true";
	const response = await doApi({
		route: `getdownloadurl/${doc.schoolYear}?fn=${doc.fileName}&fileId=${doc.fileId}${staging}`,
	});
	console.log({ response, doc });

	if (response.error) return response;
	else if (!response || !response.presignedUrl)
		return { error: { key: "PRESIGNED_URL_FAILED", response } };

	const a = document.createElement("a");
	a.href = response.presignedUrl;
	if (!doc.isDownload) a.target = "_blank";
	a.download = doc.fileName;
	document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
	a.click();
	a.remove();
};

/// APPROVE/REJECT ///
export const approveDocument = async (doc: any) => {
	const response = await doApi({
		route: `approvedocument/${doc.schoolYear}?fn=${doc.fileName}&fileId=${doc.fileId}`,
	});
	console.log({ response, doc });
	return response;
};

export const rejectDocument = async (doc: any) => {
	const staging = doc.status === "approved" ? "" : "&staging=true";
	const response = await doApi({
		route: `rejectdocument/${doc.schoolYear}?fn=${doc.fileName}&fileId=${doc.fileId}${staging}`,
	});
	console.log({ response, doc });
	return response;
};
