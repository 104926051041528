import { useState, useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";

import {
	GET_USERS_FILTERED_PARTICIPATIONS,
	GET_USERS_PARTICIPATIONS,
	GET_ALL_USER_PARTICIPATION_STATS,
} from "../../../../Graphql/Participations/QueryParticipations";

export function GetUsersByParticipation(props: any) {
	// Returns User's Data Joined to Users Participations with filters for UserId, participation_id, participation_status
	const {
		userId,
		participation_id,
		participation_status,
		academic_year,
		setDGLoading,
	} = props;

	let variables: any = {};
	console.debug(
		"GetUsersByParticipation:",
		userId,
		participation_id,
		participation_status,
		academic_year
	);
	if (userId) {
		variables["userId"] = userId;
	}
	if (participation_id) {
		variables["participation_id"] = participation_id;
	}
	if (participation_status) {
		variables["participation_status"] = participation_status;
	}

	if (academic_year) {
		variables["academic_year"] = academic_year;
	}

	console.debug("variables: ", variables);
	const { loading, error, data, refetch } = useQuery(
		GET_USERS_FILTERED_PARTICIPATIONS,
		{
			variables: variables,
			fetchPolicy: "network-only",
		}
	);

	const [userData, setUserData] = useState<any>({});

	useEffect(() => {
		if (setDGLoading) {
			setDGLoading(loading);
		}
		if (!error && data) {
			if (data.getUsersByParticipation) {
				console.debug("GetUsersByParticipation data");
				console.debug(data);
				// console.debug(data.getUsersByParticipation);
				// setUserData(data.getUsersByParticipation);
				setUserData(data);
			}
		}
	}, [loading, error, data, setUserData, setDGLoading]);

	return { userData, refetch };
}

export function GetUsersParticipations(props: any) {
	// For 1 or More Users
	// Returns Users Participations Table Data Joined to Users
	const {
		userId,
		participation_id,
		participation_status,
		academic_year,
		setDGLoading,
	} = props;

	let variables: any = {};

	if (userId) {
		variables["userId"] = userId;
	}
	if (participation_id) {
		variables["participation_id"] = participation_id;
	}
	if (participation_status !== "all") {
		variables["participation_status"] = participation_status;
	}

	if (academic_year) {
		variables["academic_year"] = academic_year;
	}

	const [getParticipations, { loading, error, data, refetch }] = useLazyQuery(
		GET_USERS_PARTICIPATIONS,
		{
			variables: variables,
			fetchPolicy: "network-only", //This is required to properly refresh the datagrid when bulk and individually changing participation approval statuses
		}
	);

	const [usersParticipationData, setUsersParticipationData] = useState<any>(
		{}
	);

	useEffect(() => {
		// console.debug("getUsersParticipations status: ", participation_status);
		getParticipations();
	}, [participation_status, getParticipations]);

	useEffect(() => {
		// console.debug("GET_USERS_PARTICIPATIONS: ", data);
		if (setDGLoading) {
			setDGLoading(loading);
		}

		if (!error && data) {
			// console.debug("GET_USERS_PARTICIPATIONS: ", data);

			if (data.getUsersParticipations) {
				// console.debug("getUsersParticipations data: ", data);
				setUsersParticipationData(data.getUsersParticipations);
			}
		}
	}, [userId, loading, error, data, setDGLoading]);

	return { usersParticipationData, refetch };
}

export function GetAllUserParticipationStats(props: any) {
	// Returns Users Participations Table Data Joined to Users
	const {
		userId,
		academic_year,
		setDGLoading,
		participation_status,
		setRefetchStatsQuery,
	} = props;

	let variables: any = {};

	if (userId) {
		variables["userId"] = userId;
	}

	if (academic_year) {
		variables["academic_year"] = academic_year;
	}

	const [getParticipationStats, { loading, error, data, refetch }] =
		useLazyQuery(GET_ALL_USER_PARTICIPATION_STATS, {
			variables: variables,
			fetchPolicy: "network-only", //This is required to properly refresh the datagrid when bulk and individually changing participation approval statuses
		});

	const [usersParticipationStatsData, setUserParticipationStatsData] =
		useState<any>({});

	useEffect(() => {
		console.debug("getParticipationStats: ", participation_status);
		getParticipationStats();
	}, [participation_status, getParticipationStats]);

	useEffect(() => {
		console.debug("GET_ALL_USER_PARTICIPATION_STATS");
		console.debug(data);

		setDGLoading(loading);

		if (!error && data) {
			console.debug("GET_ALL_USER_PARTICIPATION_STATS: ", data);
			setRefetchStatsQuery(() => refetch);

			if (data.getAllUserParticipationStats) {
				setUserParticipationStatsData(
					data.getAllUserParticipationStats
				);
				console.debug("getAllUserParticipationStats data");
				console.debug(data);
			}
		}
	}, [
		userId,
		loading,
		error,
		data,
		setDGLoading,
		setRefetchStatsQuery,
		refetch,
	]);

	return usersParticipationStatsData;
}
