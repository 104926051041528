import React, { useContext, useState, useEffect } from 'react'
import { SettingsContext } from '../../../components/authorization/GetAccessRights';
// import { withPageState } from '../../../components/authorization/PageState'

import { useQuery } from '@apollo/client'

import { getLoggedInUser } from "../../../components/authorization/Utilities"

import { emptyDataIfNoAccess } from '../../../components/authorization/Utilities'
import LoadingSpinner from "../../../components/progress/Spinner";

import { Box, Divider, Link, Stack, Typography } from "@mui/material"
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import LinkButton from "../../../components/button/Button";
import { AcademicYear } from "./partials/AcademicYear";

import { formatGQLDate } from '../../../components/utilities/Formatters'

import { GET_SETTINGS } from '../../../Graphql/Settings/QuerySettings';

const AdminSettings = ({requiredRights}: any) => {
  const adminPage = "admin_settings"
  const settings: any = useContext(SettingsContext)
  // const loggedInUserId = getLoggedInUser(settings)
  // const academicYearSettings = settings.academicYearSettings

  // console.debug("AdminSettings:", academicYearSettings.academic_year)

  const allowAccess = settings.accessRights[adminPage]

  const { loading, error, data } = useQuery(GET_SETTINGS, { fetchPolicy: "network-only" })

  const [ tableData, setTableData ] = useState([]); 
  const [ currentYearData, setCurrentYearData ] = useState<any>()
  const [ currentYearId, setCurrentYearId ] = useState<any>()
  const [ currentYearStatus, setCurrentYearStatus ] = useState("")

  useEffect(() => {
    if (data && data.getSettings) {
      console.debug('Update DataGrid - List of Academic Years');
      console.log(data)
      setCurrentYearData(settings.academicYearSettings)
      setCurrentYearId(settings.academicYearSettings.id)
      setCurrentYearStatus(settings.academicYearSettings.status)
      setTableData(emptyDataIfNoAccess(data.getSettings, allowAccess));
    }
  
  }, [loading, error, data, settings, allowAccess]);

  return (
    <>
      { loading && 
        <LoadingSpinner/>
      }
      <Stack spacing={3}>
        <Box>
          <Typography variant="h2">Website Configuration Settings</Typography>
          <Typography>
            Please exercise extreme caution when editing these settings as some changes cannot be easily undone!
          </Typography>
        </Box>
        <Box py={3}>
          <AcademicYear currentYearData={currentYearData} />
        </Box>
        <Divider />
        <Typography variant="h3">Academic Years</Typography>
        <Box>
          { allowAccess &&
            <LinkButton to="new" variant="contained">Add Academic Year</LinkButton>
          }
        </Box>
        <Box mt={3}>
          <AcademicYears tableData={tableData} currentYearId={currentYearId} yearStatus={currentYearStatus}/>
        </Box>
      </Stack>
    </>
  )
}



const AcademicYears = ({tableData, currentYearId, yearStatus}: any) => {

  const columns: GridColDef[] = [

    { field: 'status', headerName: 'Active', width: 75, 
      renderCell: (params) => 
        <>
            { currentYearId === params.row.id &&  yearStatus === 'open' ? <>&#10004;</> : <></> } 
        </>,
    },
    { field: 'academic_year', headerName: 'Year', width: 150, 
      renderCell: (params) => 
        <>
            { formatGQLDate({gQLDate: params.row.academic_year_start, format: "YYYY"}) } - { formatGQLDate({gQLDate: params.row.academic_year_end, format: "YYYY"}) }
        </>,
    },
    { field: 'academic_year_start', headerName: 'Open On', width: 120, valueGetter: (params: any) => params.row.academic_year_start ? formatGQLDate({gQLDate: params.row.academic_year_start, format: "MM/DD/YYYY"}) : "-" },
    { field: 'academic_year_end', headerName: 'Close On', width: 120, valueGetter: (params: any) => params.row.academic_year_end ? formatGQLDate({gQLDate: params.row.academic_year_end, format: "MM/DD/YYYY"}) : "-" },
    { field: 'event_participation_goal', headerName: 'Event Goal', width: 120 },
    { field: 'service_hours_goal', headerName: 'Service Goal', width: 120 },
    { 
      field: 'actions', 
      headerName: 'Actions', 
      width: 300 ,
      renderCell: (params) =>
        <>
          <Link href={`settings/edit/${params.row.id}`} underline="none">Edit Year</Link>
        </>,
    },
  
  ];
  
  const [ pageSize, setPageSize ] = useState<number>(25);
  return (
    <DataGrid
      autoHeight={true}  
      rows={tableData}
      columns={columns}
      pageSize={pageSize}
      onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
      rowsPerPageOptions={[2, 5, 10, 25, 100]}
      density="compact"
      components={{
          Toolbar: GridToolbar,
      }}
      componentsProps={{
        toolbar: {
          csvOptions:{
            fileName: `ASC-Settings-${formatGQLDate({gQLDate: new Date().getTime(), format: "YYYYMMDD"})}`,
          }
        },
      }}
    />
  )
}

// export default withPageState(AdminSettings, { pageSection: "admin", adminAccess: "admin_settings" })
export default AdminSettings