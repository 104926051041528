import { Alert, Box, styled, Typography } from "@mui/material"
import { hasData } from '../../../../components/utilities/Validation'

export const AcademicYear = (props: any) => {
  // console.debug("AcademicYear - props.currentYearData", props.currentYearData)
  const settingsYear = props.currentYearData
  const haveYearData = hasData(settingsYear)
  const status = haveYearData ? settingsYear.status : ""
  var currentAcademicYear = ""  

  if (settingsYear !== undefined) {
    // currentAcademicYear = haveYearData && settingsYear.status === 'open' ? `${(parseInt(settingsYear.academic_year)-1)} - ${parseInt(settingsYear.academic_year)}` : "Closed"
    currentAcademicYear = haveYearData ? `${(parseInt(settingsYear.academic_year)-1)} - ${parseInt(settingsYear.academic_year)}` : ""

  }
  return (
    <>
      <Typography variant='h4'>Current Academic Year: </Typography>
      <Typography variant='h2'style={{ color: haveYearData && settingsYear.status === 'closed' ? 'red' : 'inherit' }}>{currentAcademicYear}</Typography>
      <Box mt={1}>
        <AcademicYearStatus academicYear={haveYearData ? settingsYear.academic_year : ""} status={status} />
      </Box>
    </>
  )
}

const AlertStyled = styled(Alert)({
  display: 'inline-block', 
  '& .MuiAlert-message': { textAlign: 'center', width: '100%'}
});

const AcademicYearStatus = ({status, academicYear}:any) => {
  if (status === 'open') {
    return (
      <>
        <AlertStyled icon={false} severity="success">
          <Typography>Currently In Progress</Typography> 
        </AlertStyled>
      </>
    )
  }
  if (status === 'closed') {
    return (
      <>
        <AlertStyled icon={false} severity="error">
          <Typography>Not Currently In Progress</Typography>
        </AlertStyled>
      </>
    )
  }
  return <></>
}