import { gql } from "@apollo/client";

export const GET_COURSE_DOCUMENT_SUBMISSIONS = gql`
	query getCourseDocuments(
		$submission_status: String
		$limit: Int
		$offset: Int
		$sort_direction: String
		$search_for: String
	) {
		getCourseSubmissions(
			submission_status: $submission_status
			limit: $limit
			offset: $offset
			sort_direction: $sort_direction
			search_for: $search_for
		) {
			courseDocumentCounts {
				notesCount
				syllabusCount
				testCount
				uclaClassesId
				ucla_class_name
			}
			totalPages
			totalClassDocuments
			totalClassCategories
		}
	}
`;

export const GET_USER_DOCUMENT_COUNTS = gql`
	query getCourseDocuments($userId: ID) {
		getCourseDocumentsStats(userId: $userId) {
			approvedCount
			pendingCount
		}
	}
`;

export const GET_ADMIN_COURSE_DOCUMENTS = gql`
	query getCourseDocuments(
		$userId: ID
		$submission_id: ID
		$submission_status: String
		$academic_year: String
	) {
		getCourseDocuments(
			userId: $userId
			submission_id: $submission_id
			submission_status: $submission_status
			academic_year: $academic_year
		) {
			id
			userId
			academic_year
			created_by_id
			created_on
			file_id_aws
			file_display_name
			file_path
			# file_name
			file_type
			file_size
			submission_type
			submitted_name
			submission_status
			professor_name
			submission_status_changed_by_id
			submission_status_changed_on
			term
			updated_on
			ucla_classes_class_name
			user_first_name
			user_last_name
		}
		getCourseDocumentsStats {
			approvedCount
			classesWithApprovedDocsCount
			pendingCount
			uniqueUsersCount
		}
	}
`;

export const GET_ONE_COURSE_DOCUMENTS = gql`
	query getOneCourseDocuments($uclaClassesId: ID) {
		getOneCourseDocuments(uclaClassesId: $uclaClassesId) {
			ucla_classes_class_name
			documents {
				notes {
					academic_year
					professor_name
					term
					ucla_classes_class_name
					user_first_name
					user_last_name
					submission_status
					file_path
					# file_name
					file_type
					file_size
					file_id_aws
					submitted_name
					file_display_name
					created_by_id
				}
				syllabi {
					academic_year
					professor_name
					term
					ucla_classes_class_name
					user_first_name
					user_last_name
					submission_status
					file_path
					# file_name
					file_type
					file_size
					file_id_aws
					submitted_name
					file_display_name
					created_by_id
				}
				tests {
					academic_year
					professor_name
					term
					ucla_classes_class_name
					user_first_name
					user_last_name
					submission_status
					file_path
					# file_name
					file_type
					file_size
					file_id_aws
					submitted_name
					file_display_name
					created_by_id
				}
			}
		}
	}
`;
