import React from 'react'

import ReactDOM from "react-dom/client";
import { localStorageLoggedInUser } from './components/authorization/Utilities'
import { doPing } from './components/utilities/Api'

import { 
  // BrowserRouter, Routes, Route 
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from "react-router-dom";
import {
	ApolloClient,
	InMemoryCache,
	ApolloProvider,
	HttpLink,
	ApolloLink,
	concat,
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";

import "./index.css";
import reportWebVitals from "./reportWebVitals";
import buildMetadata from "./build-metadata.json";

import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./themes/default";

import Layout, { AdminLayout } from "./pages/Layout";
import Home from "./pages/home/Home";
import NewUser from "./pages/admin/user/NewUser";
import NewUserBulk from "./pages/admin/user/NewUserBulk";
import ProgramParticipation from "./pages/participation/ProgramParticipation";
import NewParticipation from "./pages/participation/NewParticipation";
import EditParticipation from "./pages/participation/EditParticipation";
import NotesTestBank from "./pages/bank/NotesTestBank";
import ClassDetail from "./pages/bank/ClassDetail";
import NewDocument from "./pages/bank/NewDocument";
import PersonalProfile from "./pages/profile/PersonalProfile";

// Public pages
import { MLLVoting } from './pages/mll/MLLVoting';

import FileUploadDownloadDemo from "./pages/file_upload_download_demo/FileUploadDownloadDemo";

// Admin pages
import Admin from "./pages/admin/Admin";
import Users from "./pages/admin/user/Users";
import UserDetail from "./pages/admin/user/UserDetail";
import BulkManagement from "./pages/admin/participation/BulkManagement";
import ParticipationProfiles from "./pages/admin/participation/AllProfiles";
import ParticipationProfile from "./pages/admin/participation/IndividualProfile";
import { default as AdminSubmissionDetails } from "./pages/admin/participation/SubmissionDetails";
import { default as AdminNewParticipation } from "./pages/admin/participation/NewParticipation";
import AdminSettings from "./pages/admin/settings/Settings";
import NewAcademicYear from "./pages/admin/settings/NewAcademicYear";
import EditAcademicYear from "./pages/admin/settings/EditAcademicYear";
import BankManagement from "./pages/admin/bank/BankManagement";
import DocumentReview from "./pages/admin/bank/DocumentReview";
import NewClass from "./pages/admin/bank/NewClass";
import { MLLManagment } from './pages/admin/mll/MLLManagement';

import LoginLandingPageDemo from "./pages/login_demo/LoginLandingPageDemo";
import UserNotFoundInAscDatabase from './pages/login_demo/UserNotFoundInAscDatabase'

import { Toast } from "./components/alerts/Toaster";

import RenewalRequirements from "./pages/participation/RenewalRequirements";
import ErrorPage from "./pages/error/error";

import { GetAccessRights } from './components/authorization/GetAccessRights'
import { GoToLogout } from "./components/authorization/LoginLogout";

import { PageState } from './components/authorization/PageState'
import NewFacultyBulk from './pages/admin/mll/NewFacultyBulk';

// you can set this in .env.local - which is ignore by git
if (process.env.REACT_APP_HIDE_CONSOLE_WARN) console.warn = () => {};

// use this to make sure builds match and verify updates are actually pushed, we will make it more formal eventually
// build # is only generated on npm build (eventually will only be incremented on a push to stage branch or something)
console.log("build environment: " + buildMetadata.build_environment);
console.log("build date: " + buildMetadata.date);
console.log("build ID: " + buildMetadata.build_id);
console.log("github commit ID: " + buildMetadata.commit_id);
console.log("react graphql: " + process.env.REACT_APP_GRAPHQL_URL);
console.log("aws environment: " + process.env.REACT_APP_AWS_ENVIRONMENT);
console.log("localStorageLoggedInUser: ", localStorageLoggedInUser());

// called when any user loads home page, to wake up the lamdba
if (process.env.REACT_APP_AWS_ENVIRONMENT !== "local") doPing();

// https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
const retryLink = new RetryLink(); // retries 5 times by default on network and server errors
const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL });
const link = concat(retryLink, httpLink);

const authMiddleware = new ApolloLink((operation, forward) => {
	// MATT - Below is the block to add the auth to the headers
	operation.setContext(({ headers = {} }) => ({
		headers: {
			...headers,
			authorization: localStorage.getItem("uclaeaidmtoken") || 'Token To Hello World',
		},
	}));

	return forward(operation);
});

export default function App() {

  const client = new ApolloClient({
      uri: process.env.REACT_APP_GRAPHQL_URL,
      cache: new InMemoryCache(),
      link: concat(authMiddleware, link),
  });

  const router = (isLoading: boolean, accessRights: any) => { 
    // if (!isLoading && hasData(accessRights)) {
    //   console.log('TEST: createRouter...', accessRights)
    // }

    return createBrowserRouter(
      createRoutesFromElements(
        <>
          <Route path="/" element={<Layout />} errorElement={<ErrorPage />}> 
            <Route index element={<Home />}/>
            <Route path="/logout" element={<GoToLogout />} />
            {/* TEMP DEMOS */}
            <Route path="file_upload_download_demo" element={<FileUploadDownloadDemo />} />
            <Route path="login_demo" element={<LoginLandingPageDemo />} />
            <Route path="user_not_found_in_asc_database" element={<UserNotFoundInAscDatabase />} />

            {/* participation */}
            <Route path="participation" element={<PageState><ProgramParticipation /></PageState>} /> 
            <Route path="participation/:id" element={<PageState><ProgramParticipation /></PageState>} />
            <Route path="participation/new" element={<PageState><NewParticipation /></PageState>} />
            <Route path="participation/new/:id"  element={<PageState><NewParticipation /></PageState>} />
            <Route path="participation/edit" element={<PageState><EditParticipation /></PageState>} />
            <Route path="participation/edit/:id" element={<PageState><EditParticipation /></PageState>} />
            <Route path="participation/renewal-requirements" element={<PageState><RenewalRequirements /></PageState>} />
            {/* notes/text bank */}
            <Route path="bank" element={<PageState><NotesTestBank /></PageState>} />
            <Route path="bank/:id" element={<PageState><NotesTestBank /></PageState>} />
            <Route path="bank/class/:id" element={<PageState><ClassDetail /></PageState>} />
            <Route path="bank/new" element={<PageState><NewDocument /></PageState>} />
            <Route path="bank/:id/new" element={<PageState><NewDocument /></PageState>} />
            <Route
                path="profile"
                element={<PageState><PersonalProfile /></PageState>}
            />
            {/* <Route
                path="profile/:id"
                element={<PageState><PersonalProfile /></PageState>}
            /> */}
          </Route>
          <Route path="admin" element={<AdminLayout />}>
            <Route index element={<PageState pageSection="admin"><Admin /></PageState>} />
            {/* settings */}
            <Route path="settings" element={<PageState pageSection="admin" adminAccess="admin_settings"><AdminSettings /></PageState>} />
            <Route path="settings/new" element={<PageState pageSection="admin" adminAccess="admin_settings"><NewAcademicYear /></PageState>} />
            <Route path="settings/edit/:id" element={<PageState pageSection="admin" adminAccess="admin_settings"><EditAcademicYear /></PageState>} />
            
            {/* all users */}
            <Route path="users" element={<PageState pageSection="admin" adminAccess="admin_users"><Users /></PageState>} />
            <Route path="user/new" element={<PageState pageSection="admin" adminAccess="admin_users"><NewUser /></PageState>} />
            <Route path="users/new/bulk" element={<PageState pageSection="admin" adminAccess="admin_users"><NewUserBulk /></PageState>} />
            {/* single user */}
            <Route path="user/:id" element={<PageState pageSection="admin" adminAccess="admin_users"><UserDetail /></PageState>} />
            
            {/* all participation */}
            <Route path="participation" element={<PageState pageSection="admin" adminAccess="admin_participation"><BulkManagement /></PageState>} />
            <Route path="participation/profiles" element={<PageState pageSection="admin" adminAccess="admin_participation"><ParticipationProfiles /></PageState>} />
            <Route path="user/participation/:id" element={<PageState pageSection="admin" adminAccess="admin_participation"><ParticipationProfile /></PageState>} />
            <Route path="user/participation/details/:id" element={<PageState pageSection="admin" adminAccess="admin_participation"><AdminSubmissionDetails /></PageState>} />
            <Route path="user/participation/new/:id" element={<PageState pageSection="admin" adminAccess="admin_participation"><AdminNewParticipation /></PageState>} />

            {/* notes/test bank */}
            <Route path="bank" element={<PageState pageSection="admin" adminAccess="admin_bank"><BankManagement /></PageState>} />
            <Route path="bank/review" element={<PageState pageSection="admin" adminAccess="admin_bank"><DocumentReview /></PageState>} />
            <Route path="bank/review/:id" element={<PageState pageSection="admin" adminAccess="admin_bank"><DocumentReview /></PageState>} />
            <Route path="bank/new-class/:notesId" element={<PageState pageSection="admin" adminAccess="admin_bank"><NewClass /></PageState>} />
          
            {/* my last lecture (MLL) */}
            <Route path="mll" errorElement={<ErrorPage hideNav={true} />} element={<PageState pageSection="admin" adminAccess="admin_mll"><MLLManagment /></PageState>} />
            <Route path="mll/faculty/new/bulk" errorElement={<ErrorPage hideNav={true} />} element={<PageState pageSection="admin" adminAccess="admin_mll"><NewFacultyBulk /></PageState>} />
            
          </Route>

          {/* my last lecture voting */}
          <Route path="mll" element={<MLLVoting />} />

        </>
      )
    );
  }

  return (
      <React.StrictMode>
          <ApolloProvider client={client}>
            <GetAccessRights>
              {({isLoading, accessRights}: any) => (
                <ThemeProvider theme={theme}>
                  <RouterProvider router={router(isLoading, accessRights)} />
                  <Toast />
                </ThemeProvider>
              )}
            </GetAccessRights>
          </ApolloProvider>
      </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);
