import React, { useContext, useEffect, useState } from "react";
import { SettingsContext } from '../../components/authorization/GetAccessRights';
import { withPageState } from '../../components/authorization/PageState'

import { getUserId,  getLoggedInUser } from "../../components/authorization/Utilities"

import { useParams } from "react-router-dom"
import { useQuery } from '@apollo/client'

import { Typography } from "@mui/material";
import LayoutContainer from "../../components/container/Container";

import { GET_ONE_USER } from '../../Graphql/Users/QueryUser'

import { ParticipationForm } from "../../components/forms/ParticipationForm";
// import NoAccessPage from "../error/NoAccess";
import AcademicYearClosed from "../../components/authorization/AcademicYearClosed";

// const initErrors: any = {
//   academic_year: false, 
//   submission_type: false, 
//   submitted_name: false, 
//   term: false, 
//   professor_name: false
// }

const NewParticipation = () => {
  const settings: any = useContext(SettingsContext)
  // const loggedInUserId = getLoggedInUser(settings).id || null
  const academicYearSettings = settings.academicYearSettings

  const params = useParams()
  // const id: string = params.id ? params.id : ''
  const id: string = getUserId(settings.accessRights, params.id)
  
  const { error: errorUser, data: dataUser } = useQuery(GET_ONE_USER,  { variables: { id: id }})

  const [ user, setUser ] = useState<any>({})

  useEffect(() => {           
    if (!errorUser && dataUser) {
      console.log(dataUser)
      setUser(dataUser.getOneUser);
    }
  }, [id, errorUser, dataUser, setUser ])
  
  if (academicYearSettings.status !== 'open') {
    return <AcademicYearClosed section="Program Participation Submission" />
  }
	return (
		<>
      <LayoutContainer>
        <Typography variant="h2">Submit Program Participation</Typography>
        <ParticipationForm userId={id} data={dataUser} action="new"/>
      </LayoutContainer>
    </>
	);
};

// export default withPageState(NewParticipation, {})
export default NewParticipation
