import React, { useContext, useEffect, useState } from "react";
import { SettingsContext } from '../../components/authorization/GetAccessRights';
import { withPageState } from '../../components/authorization/PageState'

import { useLazyQuery } from '@apollo/client';

import { getLoggedInUser } from "../../components/authorization/Utilities"

import { Box, Stack, styled, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { theme } from '../../themes/default';
import { AcademicYear } from './settings/partials/AcademicYear';

// import { GET_ACADEMIC_YEAR_SETTINGS } from '../../Graphql/Settings/QuerySettings';
import { GET_ADMIN_STATS } from '../../Graphql/AdminStats/QueryAdminStats';

const LinkBoxStyled = styled(Link)({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  display: 'inline-block',
  borderRadius: theme.shape.borderRadius,
  textDecoration: 'none',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  }
});

const hilighted = {
  color: theme.palette.secondary.main
}
const initStatsData = {
  active_users: 0,
  pending_events: 0,
  pending_services: 0,
  pending_service_hours: 0
}
function ResponsiveDrawer() {
  const settings: any = useContext(SettingsContext)
  // const loggedInUserId = getLoggedInUser(settings).id || null 
  // const academicYearSettings = settings.academicYearSettings

  // console.log('allowAccess: ' + allowAccess)
  // const { loading: loadingAcademicYear, error: err orAcademicYear, data: dataAcademicYear } = useQuery(GET_ACADEMIC_YEAR_SETTINGS)
  const [ getAdminStats, { error: errorAdminStats, data: dataAdminStats }] = useLazyQuery(GET_ADMIN_STATS);
    // , {variables: { submission_id: id }})
  
  const [ statsData, setStatsData ] = useState(initStatsData)
  const [ currentYearData, setCurrentYearData ] = useState<any>()
  const [ currentYearId, setCurrentYearId ] = useState<any>()

  useEffect(() => {

    if (settings) {
      console.debug('Settings: ', settings)
      if (settings.academicYearSettings) {
        setCurrentYearData(settings.academicYearSettings)
        setCurrentYearId(settings.academicYearSettings.id)
        getAdminStats({
          variables: { academic_year: settings.academicYearSettings.academic_year}
        })
      }
    }
  }, [getAdminStats, settings])

  useEffect(() => {
    if (errorAdminStats) {
      console.debug(errorAdminStats)
    }
    if (dataAdminStats) {
      console.debug('Admin Stats: ', dataAdminStats)
      setStatsData(dataAdminStats.getAdminStats[0])
    }
  }, [errorAdminStats, dataAdminStats])

  return (
    <Stack spacing={5}>
      <Typography variant='h2'>Administrative Control Panel</Typography>
      <Box>
        <AcademicYear currentYearData={currentYearData} />
      </Box>
      <Box>
        <LinkBoxStyled to="/admin/users">
          <Stack direction="row" spacing={3} alignItems="center">
            <Typography variant='h4'>Active Users:</Typography>
            <Typography variant='h3' sx={hilighted}>{statsData.active_users}</Typography>
          </Stack>
        </LinkBoxStyled>
      </Box>
      <Box>
        <LinkBoxStyled to="/admin/participation">
          <Stack spacing={3}>
            <Typography variant='h4'>Pending Program Participation Submissions:</Typography>
            <Typography variant='h3' sx={hilighted}>{statsData.pending_events + statsData.pending_services || 0} Events</Typography>
            <Typography variant='h3' sx={hilighted}>@ {statsData.pending_service_hours !== null 
                                                      ? Math.round(statsData.pending_service_hours * 100) / 100 
                                                      : 0} Service Hours</Typography>
          </Stack>
        </LinkBoxStyled>
      </Box>
      <Box sx={{ maxWidth: 640 }}>
        <Typography paragraph><strong>Control Panel Notices:</strong></Typography>
        <Typography paragraph>Welcome to the Administrative Control Panel for the Alumni Scholars Club at UCLA. Please exercise caution when making adjustments to settings and/or posting content as not all changes can be immediately fixed. Additionally, take caution when editing users' account information as these changes are reflected immediately.</Typography>
      </Box>
    </Stack>
  );
}

// export default withPageState(ResponsiveDrawer, { pageSection: "admin", adminAccess: "" })
export default ResponsiveDrawer