import React, { useContext, useState, useEffect } from 'react'
import { SettingsContext } from '../../../components/authorization/GetAccessRights';
// import { withPageState } from '../../../components/authorization/PageState'

import { useMutation } from '@apollo/client'

import { getLoggedInUser } from "../../../components/authorization/Utilities"

import toast from 'react-hot-toast';

import { handleFormDataChange } from '../../../components/utilities/Events'

import { Box, Button, Divider,Stack, TextField, Typography } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import LinkButton from "../../../components/button/Button";

import { CREATE_UCLA_CLASS } from '../../../Graphql/UclaClasses/MutateUclaClasses'

var initFormData = {
  ucla_class_name: "",
}

const NewClass = () => {
  // const adminPage = "admin_bank"
  const settings: any = useContext(SettingsContext)
  const loggedInUserId = getLoggedInUser(settings).id || null
  // const academicYearSettings = settings.academicYearSettings
  // const allowAccess = settings.accessRights[adminPage]

  const params = useParams()
  const notesId: string = params.notesId ? params.notesId : ''

  const [ createUclaClass, { error: errorCreateUclaClass, data: dataCreateUclaClass } ] = useMutation(CREATE_UCLA_CLASS)
  const [ formData, setFormData ] = useState(initFormData)

  const navigate = useNavigate();
	const goBack = () => {
		navigate(-1);
	}

  function handleSubmit() {
    createUclaClass({
      variables: {
        ucla_class_name: formData.ucla_class_name,
        created_by_id: loggedInUserId
      }
    })
  }

  useEffect(() => {    
    let toastMsg = ""
    if (errorCreateUclaClass) {
      toastMsg = "Error Submiting UCLA Class: " + errorCreateUclaClass
      toast.error(toastMsg)
      console.log(`${toastMsg}: `)
      console.log(errorCreateUclaClass)
    } else if (dataCreateUclaClass) {
      if (dataCreateUclaClass.createUclaClass.successful) {
        toastMsg = "Successfully Added the UCLA Class"
        toast.success(toastMsg)
        console.log(toastMsg)
        console.log(dataCreateUclaClass)
        
        window.location.href = `/admin/bank/review/${notesId}`
        
        
      } else {
        toastMsg = dataCreateUclaClass.createUclaClass.message
        toast.error(toastMsg)
        console.log(`${toastMsg}: `)
        console.log(dataCreateUclaClass.createUclaClass)
      }
      
    }
  }, [errorCreateUclaClass, dataCreateUclaClass, notesId])

	return (
		<>
      <Stack spacing={3}>
        <Box>
          <LinkButton to="../bank/review" variant="outlined" type="back">Back</LinkButton>
        </Box>
        <Typography variant="h2">Notes/Test Bank Management</Typography>
        <Typography variant="h3">Add New Class</Typography>
        <Divider />
        <Stack direction="row" spacing={3} sx={{ pt: 1 }}>
          <TextField
            id="class-name"
            label="Full Class Name"
            value={formData.ucla_class_name}
            onChange={(event) => { handleFormDataChange(setFormData, formData, "ucla_class_name", event.target.value) }}
            sx={{ width: '100%', maxWidth: 400 }}
            helperText="Please enter the class name in full such as Chemistry 14A NOT Chem 14A. For small classes, enter the name like Theater (All) or Chemistry (Other)."
          />
        </Stack>
        <Stack direction="row" spacing={3} sx={{ pt: 1 }}>
          <Button  onClick={goBack} variant="outlined">Cancel</Button>
          <Button variant="contained" onClick={handleSubmit}>Add New Class</Button>
        </Stack>
      </Stack>
    </>
	);
};

// export default withPageState(NewClass, { pageSection: "admin", adminAccess: "admin_bank" })
export default NewClass