import React, { useContext, useEffect, useState } from "react";
import { SettingsContext } from '../../components/authorization/GetAccessRights';
// import { withPageState } from '../../components/authorization/PageState'

import { useParams } from "react-router-dom"
import { useQuery } from '@apollo/client';

// import { getLoggedInUser } from "../../components/authorization/Utilities"
import { hasData } from '../../components/utilities/Validation'

import { Box, Stack, styled, Typography } from "@mui/material"

import LoadingSpinner from "../../components/progress/Spinner";
import LinkButton from "../../components/button/Button"
import LayoutContainer from "../../components/container/Container"
import { theme } from "../../themes/default"
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import Submissions from "./partials/Submissions"

import { GET_USER_DOCUMENT_COUNTS } from '../../Graphql/Documents/QueryDocuments';
import { getUserId } from "../../components/authorization/Utilities";
import AcademicYearClosed from "../../components/authorization/AcademicYearClosed";

function NotesTestBank() {
  const settings: any = useContext(SettingsContext)
  // const loggedInUserId = getLoggedInUser(settings).id || null
  const academicYearSettings = settings.academicYearSettings

  const params = useParams()
  // const id: string = params.id ? params.id : localStorageLoggedInUser().id
  const id = getUserId(settings.accessRights, params.id)

  const { loading, error, data } = useQuery(GET_USER_DOCUMENT_COUNTS, {variables: { userId: id }, fetchPolicy: "network-only"})
  
  const [ userAllowedToViewBank, setUserAllowedToViewBank]  = useState(false)
  const [ userHasPendingDocs, setUserHasPendingDocs ] = useState(false)
  
  
  useEffect(() => {
    
    if (error) {
      console.debug(error)
    }
    if (data) {
      console.debug("data: ", data)
      console.debug("User is Allowed to View Notes Bank based on Rules")
      setUserAllowedToViewBank(data.getCourseDocumentsStats[0].approvedCount > 0)
      setUserHasPendingDocs(data.getCourseDocumentsStats[0].pendingCount > 0)
    } 
    if (hasData(settings)) {
      if (settings.accessRights.bank_allow_view === true) {
        console.debug("User is Allowed to View Notes Bank")
        setUserAllowedToViewBank(true)
      }
      if (settings.accessRights.bank_no_access === true) {
        console.debug("User Not Allowed to View Notes/Bank")
        setUserAllowedToViewBank(false)
      }
    }

  }, [error, data, settings])

  // if (academicYearSettings.status === null) {
  //   return <AcademicYearClosed section="Notes/Test Bank"/>
  // }
  
  return (
		<>
      { loading && 
        <LoadingSpinner/>
      }
      <>
        <LayoutContainer>
          <Stack spacing={3}>
            <Typography variant="h2">Notes/Test Bank</Typography>
            <WelcomeMessage access={userAllowedToViewBank} />
            <AccessPending access={userAllowedToViewBank}  pending={userHasPendingDocs}/>
            <LinkButton to="new" type="add">Submit New Document</LinkButton>
          </Stack>
        </LayoutContainer>
        <BankInfo access={userAllowedToViewBank} pending={userHasPendingDocs}/>
        <Submissions access={userAllowedToViewBank} pending={userHasPendingDocs}/>
      </>
    </>
	)
}

function BankInfo({access, pending}: any) {

  return (
    <>
    
    {/* students who do not have any sumbissions */}
    {(!access && !pending) &&
      <>
        <LayoutContainer background="light">
          <Typography variant="h4">Service Hours Awarded for Each Submission:</Typography>
          <Typography paragraph sx={{ mt: 3 }}>
            Students are allowed to submit: <br />
            • Notes - 1 Hour <br />
            • Exams - 1.5 hours <br />
            • Syllabi - 4 syllabi = 1 hour <br />
            You may recieve a <strong>maximum of 10 service hours</strong> from contributions to the Notes/Test Bank per year.
          </Typography>
        </LayoutContainer>
        <LayoutContainer>
          <Typography variant="h4">Academic Information</Typography>
          <Typography paragraph sx={{ mt: 3, maxWidth: 896 }}>
          The ASC Notes/Test Bank is a great opportunity for Alumni Scholars to share their notes and exams from previous classes with each other. The idea was inspired from the UCLA notes/test bank in the Student Activities Center (SAC), which is definitely a wonderful resource to use as well! The ASC Notes/Test Bank, however, is unique. For one, it is open only to Alumni Scholars. Furthermore, unlike the UCLA notes/test bank, once you are given access to the ASC notes/test bank, you are able to view its database from anywhere, whether on campus or at home, and there is no limit on the amount of time you can spend on the ASC Notes/Test Bank. The following are an overview of important guidelines for the ASC Notes/Test Bank. Thank you for your contributions, and we hope the ASC Notes/Test Bank is of great help to you!
          </Typography>
        </LayoutContainer>
      </>
    }
    </>
  )
}

const AccessBoxStyled = styled(Box)({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.info.light,
  maxWidth: 550,
  borderRadius: theme.shape.borderRadius,
});

function AccessPending({access, pending}: any) {
  return (
    <>
      {/* does the student have pending access?  */}
      {(!access && pending) &&
        <AccessBoxStyled>
          <Stack direction="row" spacing={1} alignItems="center" sx={{ color: theme.palette.success.main, mb: 3 }}>
            <Typography variant="h4">Access Pending</Typography>
            <AccessTimeIcon />
          </Stack>
          <Typography paragraph>
            Your first document has been received and will be reviewed shortly. Once your submission has been approved, you will be granted access to the Notes/Test Bank.
          </Typography>
        </AccessBoxStyled>
      }
    </>
  )
}

function WelcomeMessage({access}: any) {
  return (
    // does the student have approved sumbissions?
    access ?
    <>
      <Typography paragraph sx={{ maxWidth: 896 }}>
        Welcome to the Alumni Scholars Club notes/test bank! Over the years we have acquired a vast array of syllabi, notes, and tests spanning across multiple classes and majors. Feel free to browse through the folders below as you see fit. If you cannot find a specific class, check the Non-Listed Classes folder. Please do not share items from the notes/test bank as this is an ASC scholar benefit. Sharing bank items may result in the loss of your access to the bank.
      </Typography>
    </>
    :
    <>
      <Typography variant="h4">Gaining Access to the Notes/Test Bank:</Typography>
      <Typography variant="body1" sx={{ maxWidth: 896 }}>
        To gain access to the Notes/Test Bank, ASC scholars must first submit either class syllabi, a set of notes, or an exam (midterm/final). After the submission is received, you will be granted access to the notes/test bank within 2-5 business days.
      </Typography>
    </>
  
  )
}

// export default withPageState(NotesTestBank, {})
export default NotesTestBank