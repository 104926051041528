import { gql } from "@apollo/client";

export const CREATE_UCLA_CLASS = gql`
	mutation createCourseDocument(
		$created_by_id: String! # use logged in ID. Create should only be from Student side
		$ucla_class_name: String!
	) {
		createUclaClass(
			ucla_class_name: $ucla_class_name
			created_by_id: $created_by_id
		) {
			error_fields
			inserted_id
			message
			successful
		}
	}
`;

export const UPDATE_UCLA_CLASS = gql`
	mutation updateCourseDocument($id: ID!, $ucla_class_name: String!) {
		updateUclaClass(id: $id, ucla_class_name: $ucla_class_name) {
			error_fields
			inserted_id
			message
			successful
		}
	}
`;

export const DELETE_UCLA_CLASS = gql`
	mutation deleteUclaClass($id: ID!) {
		deleteUclaClass(id: $id) {
			successful
			message
			error_fields
		}
	}
`;
